import { CalloutComponent, FormValues, getValue, InputFormGroup, SelectOption } from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { AdbsEditLicensePanel, Fields } from "../../components/AdbsEditLicensePanel/AdbsEditLicensePanel";
import {
  AdbsDatabaseBaseDatabaseEditionEnum,
  AdbsDatabaseBaseLicenseModelEnum,
  UpdateAdbsDatabaseDetailsDatabaseEditionEnum,
} from "../../gen/clients/mchub-azure-api-client-adb";
import { parseId } from "../../helpers/idHelper";
import { LICENSE_TYPE } from "../../helpers/resourceHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_UPDATE_POLL_DELAY,
  ADBS_UPDATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationProps } from "../OperationTypes";

export interface AdbsEditLicenseProps extends OperationProps {
  databaseId: string | undefined;
  databaseEdition: AdbsDatabaseBaseDatabaseEditionEnum | undefined;
  computeCount: number | undefined;
  isOcpuModel: boolean | undefined;
  licenseModel: AdbsDatabaseBaseLicenseModelEnum | undefined;
}

const AdbsEditLicense = (
  {
    location,
    databaseId,
    databaseEdition,
    computeCount,
    isOcpuModel,
    licenseModel,
    onExecute,
    onCancel,
  }: AdbsEditLicenseProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");
  const [licenseCalloutRef, setLicenseCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const { invokeCall: invokeAdbsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.updateAdbsDatabase },
  );

  const onLicenseSubmit = (formValues: FormValues): void => {
    const selectedLicense = getValue<SelectOption[]>(formValues, Fields.LicenseType, InputFormGroup);
    const licenseTypeId = selectedLicense?.[0].id as LICENSE_TYPE | undefined;
    const selectedEdition = getValue<SelectOption[]>(formValues, Fields.DatabaseEdition, InputFormGroup);
    const databaseEditionId = selectedEdition?.[0].id as UpdateAdbsDatabaseDetailsDatabaseEditionEnum | undefined;

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: licenseCalloutRef?.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.updateAdbsLicense(),
          message: Messages.notifications.inProgress.messages.updateAdbsLicense(resName),
        },
        success: {
          title: Messages.notifications.success.titles.updateAdbsLicense(),
          message: Messages.notifications.success.messages.updateAdbsLicense(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.updateAdbsLicense(),
          message: Messages.notifications.failure.messages.updateAdbsLicense(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK,
          delay: ADBS_UPDATE_POLL_DELAY,
          interval: ADBS_UPDATE_POLL_INTERVAL,
        },
      },
    };
    invokeAdbsUpdate({
      databaseName: resName,
      subscriptionId,
      resourceGroupName: resGroup,
      updateAdbsDatabaseDetails: {
        licenseModel: licenseTypeId,
        ...licenseTypeId === LICENSE_TYPE.BRING_YOUR_OWN_LICENSE
          ? { databaseEdition: databaseEditionId }
          : undefined,
      },
    }, invokeOptions);
  };

  return (
    <AdbsEditLicensePanel
      adbsEditLicenseValues={{
        licenseModel,
        databaseEdition,
        computeCount,
        isOcpuModel,
      }}
      onSubmit={onLicenseSubmit}
      componentRef={setLicenseCalloutRef}
      onClose={onCancel}
    />
  );
};

export const newAdbsEditLicense = (
  props: AdbsEditLicenseProps,
): JSX.Element => (<AdbsEditLicense {...props} />);
