/****************************************************************
 * WARNING THIS FILE IS AUTOGENERATED AND SHOULD NOT BE MODIFIED *
 ****************************************************************/

// tslint:disable
import { LoomI18n, Currency } from "loom-i18n";
import enLocaleData from "loom-i18n/dist/locale-data/en";

export const bundled: { [language: string]: any } = {};
bundled["en"] = {
  actions: {
    addCapacity: "Add capacity",
    addComment: "Add comment",
    addRegion: "Add location",
    addResources: "Add resources",
    addSshKey: "Add SSH key",
    addSubscriptionHelp: "How to add subscriptions for linking",
    addToFavorites: "Add to favorites",
    apexLaunch: "APEX",
    apply: "Apply",
    attach: "Attach",
    changePassword: "Change password",
    changeTdePassword: "Change TDE password",
    clear: "Clear",
    clone: "Clone",
    close: "Close",
    configureVMCluster: "Configure the VM Cluster",
    configureDbSystemShape: "Configure DB system shape",
    connectionStrings: "Connection strings",
    convert: "Convert",
    copyConnectionString: "Copy connection string",
    copySshPublicKey: "Copy SSH public key",
    create: "Create",
    createAdbs: "Create Autonomous Database",
    createApex: "Create APEX instance",
    createAResource: "Create a resource",
    createASupportRequest: "Create a support request",
    createExaDb: "Create Exadata Database",
    createExaInfra: "Create Exadata Infrastructure",
    createExaSystem: "Create Exadata System",
    createHeatWave: "Create HeatWave cluster",
    createMysql: "Create MySQL HeatWave",
    createNew: "Create new",
    createVMCluster: "Create VM Cluster",
    createVMDb: "Create Base Database",
    dashboard: "Dashboard",
    databaseActions: "Database Actions",
    delete: "Delete",
    deleteAll: "Delete All",
    detach: "Detach",
    disable: "Disable",
    disableCrashRecovery: "Disable crash recovery",
    discardChanges: "Discard changes",
    dismissAll: "Dismiss all",
    dismissInfo: "Dismiss informational",
    dismissSuccess: "Dismiss completed",
    downloadKeyCreateResource: "Download private key and create resource",
    downloadKeyUpdateResource:
      "Download private key and add public key to resource",
    downloadWallet: "Download wallet",
    edit: "Edit",
    enable: "Enable",
    enableCrashRecovery: "Enable crash recovery",
    enterDetails: "Enter details",
    getStarted: "Get started",
    goToResource: "Go to resource",
    linkSubscription: "Link subscription",
    metrics: "Metrics",
    portalMenu: "Portal menu",
    quickSearch: "Quick search",
    removeFromFavorites: "Remove from favorites",
    restore: "Restore",
    restoreToNewDbSystem: "Restore to new DB system",
    refresh: "Refresh",
    reopen: "Reopen",
    restart: "Restart",
    retry: "Retry",
    returnToAddSshKey: "Return to add an SSH key",
    returnToCreateResource: "Return to create resource",
    rotateWallet: "Rotate wallet",
    save: "Save",
    scale: "Scale",
    search: "Search",
    seeAll: "See all",
    select: "Select",
    serviceMenu: "Service menu",
    start: "Start",
    stop: "Stop",
    submit: "Submit",
    submitFeedback: "Submit feedback",
    updateDetails: "Update details",
    upgradeAccount: "Upgrade now",
    upload: "Upload",
    uploadFile: "Upload file",
    viewAccount: "View account"
  },
  activityTypes: {
    close: "Close",
    notes: "Notes",
    problemDescription: "Problem description",
    reopen: "Reopen",
    update: "Update"
  },
  addSshKey: {
    title: "Add an SSH key"
  },
  ariaLabel: {
    dbNodesList: "Database nodes list",
    dismissOptions: "Dismiss options",
    more: "More",
    morePortalTools: "More portal tools",
    mySqlCommand: "MySQL command",
    resourceList: "Resource list",
    scn: "Oracle database system change number (SCN)"
  },
  backupTypes: {
    full: "Full",
    incremental: "Incremental",
    virtualFull: "Virtual full"
  },
  creationTypes: {
    automatic: "Automatic",
    manual: "Manual"
  },
  clonePluggableDatabase: {
    title: "Clone pluggable database"
  },
  common: {
    addessesCount: "({count} addresses)",
    cancel: "Cancel",
    days: "days",
    disabled: "Disabled",
    enabled: "Enabled",
    generated: "Generated",
    home: "Home",
    hours: "Hours",
    learnMore: "Learn more",
    loading: "Loading...",
    memoryGB: "{number} GB",
    memoryMB: "{number} MB",
    memoryTB: "{number} TB",
    minutes: "Minutes",
    name: "Name",
    new: "New",
    no: "No",
    noResults: "No results",
    noSubscriptions: "No subscriptions found",
    notRequired: "Not required",
    required: "Required",
    type: "Type",
    unknown: "Unknown",
    unusable: "unusable",
    uploaded: "Uploaded",
    value: "Value",
    version: "Version",
    yes: "Yes"
  },
  configTitles: {
    default: "Default",
    noStorageAllocatedForLocalAndSparseSummary:
      "No storage allocated for local backups & Exadata sparse snapshots",
    ocpuCountPerVM: "{count} OCPU count per node",
    provideRequestDetails: "Provide details for the request",
    quotaRequests: "{count} request",
    requestedOCPUCount: "{count} Requested OCPU count",
    storageForLocal: "Local backups",
    storageForLocalAndSparse: "Local backups & Exadata sparse snapshots",
    storageForLocalAndSparseSummary:
      "Allocate storage for local backups & Exadata sparse snapshots",
    storageForSparse: "Exadata sparse snapshots",
    storageLocalSummary: "Allocate storage for local backups",
    storageSparseSummary: "Allocate storage for Exadata sparse snapshots"
  },
  createAdbs: {
    basicsTab: {
      sectionTitles: {
        instanceDetails: "Instance details",
        projectDetails: "Project details"
      },
      info: {
        learnMore:
          "Oracle Autonomous Database Serverless delivers a self-driving, self-securing, self-repairing database service that can instantly scale to meet the demands of a variety of applications: mission-critical transaction processing, mixed transactions, analytics, IoT, JSON documents, and others. When you create an Autonomous Database, you can deploy it on a serverless architecture that provides a simple and elastic choice. Oracle autonomously operates all aspects of the database life cycle from database placement to backup and updates. <a href={url}>Learn more</a>"
      },
      title: "Basics"
    },
    configurationTab: {
      sectionTitles: {
        databaseDetails: "Database details"
      },
      title: "Configuration"
    },
    networkingTab: {
      sectionTitles: {
        databaseAndToolsAccess: "Database and tools access"
      },
      title: "Networking"
    },
    securityTab: {
      sectionTitles: {
        databaseAdministratorCredentials: "Database administrator credentials"
      },
      title: "Security"
    },
    titles: {
      long: "Create Autonomous Database",
      short: "Create Autonomous Database"
    }
  },
  createApex: {
    titles: {
      long: "Create APEX instance",
      short: "Create APEX instance"
    }
  },
  createCommon: {
    dependencyWarnings: {
      basicsChangeWarning:
        "Changing Basic subscription, resource group, or region may reset selections you have made. Review all options prior to creating the {resourceName}.",
      basicsIncomplete: {
        message:
          "Subscription, resource group and region must be selected first.",
        title: "Basics is incomplete"
      },
      infrastructureNotSelected: {
        message: "Infrastructure must be selected first.",
        title: "Infrastructure not selected"
      },
      subscriptionNotSelected: {
        message: "Subscription must be selected first.",
        title: "Subscription not selected"
      },
      virtualNetworkNotSelected: {
        message: "Virtual network must be selected first.",
        title: "Virtual network not selected"
      },
      vmClusterNotSelected: {
        message: "VM cluster must be selected first.",
        title: "VM cluster not selected"
      },
      vmShapeNotSelected: {
        message: "VM shape must be selected first.",
        title: "VM shape not selected"
      },
      vnetNotSelected: {
        message: "Virtual network must be selected first.",
        title: "Virtual network not selected"
      }
    },
    loadingErrors: {
      general:
        "Failed to load data. For more details check notifications panel.",
      resourceGroups:
        "Failed to load resource groups. For more details check notifications panel."
    },
    loadingNone: "No {itemName} found",
    storageNoChangeAfterVMCreation:
      "The storage options cannot be changed after VM Cluster creation."
  },
  createExaDb: {
    basicsTab: {
      sectionTitles: {
        instanceDetails: "Instance details",
        projectDetails: "Project details"
      },
      title: "Basics"
    },
    configurationTab: {
      sectionTitles: {
        databaseDetail: "Database details",
        databaseHomeDetails: "Database home details",
        infrastructureDetail: "Exadata infrastructure details",
        vmCluster: "VM cluster details"
      },
      title: "Configuration"
    },
    managementTab: {
      info: {
        backupInfo:
          "All prerequisites for backing up to Oracle Cloud Infrastructure Object Storage must be met in the local and remote regions for automatic and remote backups to work."
      },
      sectionTitles: {
        backupHeader: "Backup"
      },
      title: "Management"
    },
    networkingTab: {
      sectionTitles: {
        networkPeering: "Network peering",
        vmClusterNetworking: "VM cluster networking"
      },
      title: "Networking"
    },
    securityTab: {
      sectionDescriptions: {
        vmClusterAuthentication:
          "You can access the services of your system's databases by using SSH tunneling. When you connect to the system, you will provide the associated private key."
      },
      sectionTitles: {
        databaseAdministratorCredentials: "Database administrator credentials",
        vmClusterAuthentication: "VM cluster authentication"
      },
      title: "Security"
    },
    titles: {
      long: "Create Exadata Database",
      longFromBackup: "Create Exadata Database from backup",
      longFullStack: "Create Exadata System",
      short: "Create Exadata Database",
      shortFromBackup: "Create Exadata Database from backup",
      shortFullStack: "Create Exadata System"
    }
  },
  createInfra: {
    basicsTab: {
      sectionTitles: {
        instanceDetails: "Instance details",
        projectDetails: "Project details"
      },
      title: "Basics"
    },
    configurationTab: {
      sectionTitles: {
        infrastructureDetail: "Exadata infrastructure details"
      },
      title: "Configuration"
    },
    titles: {
      long: "Create Exadata infra",
      short: "Create Exadata infra"
    }
  },
  createMcvcn: {
    basicsTab: {
      sectionTitles: {
        instanceDetails: "Instance details",
        projectDetails: "Project details"
      },
      title: "Basics"
    },
    configurationTab: {
      title: "Configuration"
    },
    titles: {
      long: "Create virtual cloud network",
      short: "Create virtual cloud network"
    }
  },
  createMysql: {
    basicsTab: {
      sectionTitles: {
        instanceDetails: "Instance details",
        projectDetails: "Project details"
      },
      title: "Basics"
    },
    configurationTab: {
      sectionTitles: {
        heatwaveDetails: "HeatWave details",
        infrastructureDetails: "Infrastructure details",
        restoreDataFromBackup: "Restore data from backup"
      },
      title: "Configuration"
    },
    managementTab: {
      info: {
        backupInfo:
          "All prerequisites for backing up to Oracle Cloud Infrastructure Object Storage must be met in the local and remote regions for automatic and remote backups to work. If you enable automatic backups, crash recovery will also be enabled.",
        crashRecoveryInfo:
          "If you disable crash recovery, automatic backups will also be disabled."
      },
      sectionTitles: {
        backupHeader: "Backup",
        crashRecoveryHeader: "Crash recovery",
        deletionPlanHeader: "Deletion plan"
      },
      title: "Management"
    },
    networkingTab: {
      sectionTitles: {
        databaseSystemNetworking: "Database system networking",
        networkPeering: "Network peering"
      },
      title: "Networking"
    },
    securityTab: {
      sectionTitles: {
        administratorCredentials: "Database system administrator credentials"
      },
      title: "Security"
    },
    titles: {
      long: "Create MySQL HeatWave",
      short: "Create MySQL HeatWave"
    }
  },
  createNewPanels: {
    addCapacity: {
      title: "Add capacity"
    },
    addRegion: {
      title: "Add location",
      descriptionMultipleCloudLinks:
        "All subscriptions linked with the selected OCI tenant will be extended to the new location.",
      descriptionSingleCloudLink:
        "All linked subscriptions will be extended to the new location."
    },
    backup: {
      title: "Create backup"
    },
    changePassword: {
      title: "Change password"
    },
    changeTdePassword: {
      title: "Change TDE password"
    },
    configCoreCount: {
      title: "Configure VM cluster"
    },
    configStorage: {
      title: "Configure Storage",
      description: "Configure the Storage"
    },
    dbHome: {
      title: "Create database home"
    },
    deleteExaInfra: {
      confirmCb:
        "I have read and understand that this Exadata infrastructure as well as any contained resources listed above will be deleted.",
      databaseNotLoaded:
        "Some databases could not be loaded, they will be deleted along with the selected infrastructure",
      description:
        "This action will permanently delete this Exadata infrastructure.",
      noVmCluster: "No VM clusters found.",
      vmClusterNotLoaded:
        "Failed to load VM clusters, they will be deleted along with the selected infrastructure and all contained databases will be cleaned up.",
      warningInfoBlock:
        "Warning! Deleting the selected infrastructure is irreversible. All contained resources will be also deleted."
    },
    editBackup: {
      title: "Edit backup"
    },
    editDatabaseName: {
      title: "Edit database name"
    },
    editDataStorageSize: {
      title: "Edit data storage size"
    },
    editDescription: {
      title: "Edit description"
    },
    editMySqlBackupPlan: {
      title: "Edit backup plan"
    },
    editMySqlDeletionPlan: {
      title: "Edit deletion plan"
    },
    editMySqlVersion: {
      title: "Edit MySQL version"
    },
    editNetworking: {
      title: "Edit Network Access"
    },
    editNetworkingMTLS: {
      title: "Edit mTLS authentication"
    },
    editRetentionDays: {
      title: "Edit Retention days"
    },
    editShape: {
      title: "Edit shape"
    },
    editStorage: {
      title: "Edit Storage"
    },
    licenseType: {
      title: "Edit license"
    },
    linkSubscription: {
      title: "Link subscription",
      descriptionMultipleCloudLinks:
        "Select the OCI tenant to link with subscription '{name}'.",
      descriptionSingleCloudLink:
        "Are you sure you want to link subscription '{name}'?"
    },
    mcvcn: {
      title: "Create Virtual Cloud Network"
    },
    mysqlManualBackup: {
      title: "Create manual backup"
    },
    nodeCountEstimator: {
      columns: "Columns",
      emptyList: {
        title: "No schema information available."
      },
      generateEstimate: "Generate estimate",
      infoBlock: {
        nodeCount: "Recommended node count: {number}",
        memorySizeGB: "Total memory size: {number} GB",
        memorySizeMB: "Total memory size: {number} MB",
        memorySizeTB: "Total memory size: {number} TB"
      },
      memory: "Memory",
      mySqlCommand: {
        title: "On completion",
        description:
          "All currently loaded tables remain loaded during and after the edit operation. The following command is only necessary when loading additional tables or schemas."
      },
      regenerateEstimate: "Regenerate estimate",
      rowCount: "Row count",
      tableList: {
        row: {
          columnsCell: "{columns} ({varlen}% VARLEN-encoded)"
        }
      },
      title: "HeatWave node count"
    },
    quotaDetails: {
      title: "Quota details"
    },
    resourceGroup: {
      description:
        "A resource group is a container that holds related resources for a solution."
    },
    restore: {
      restoreDbToPoint: "Restore the Autonomous database to a point in time",
      title: "Restore"
    },
    scaleCoreCount: {
      title: "Scale"
    },
    scaleExaInfra: {
      title: "Scale"
    },
    scaleVmDbSystem: {
      title: "Scale"
    },
    supportSearch: {
      description:
        "Enter a support request ID to search through all support request history."
    },
    virtualNetwork: {
      title: "Attach virtual network"
    },
    vmShape: {
      title: "Select VM shape"
    },
    walletDownload: {
      description:
        "Database connections to your Autonomous Database use a secure connection. The wallet file will be required to configure your database clients and tools to access Autonomous Database. Please create a password for this wallet. Some database clients will require that you provide both the wallet and password to connect to your database (other clients will auto-login using the wallet without a password).",
      title: "Download Wallet"
    }
  },
  createPluggableDatabase: {
    title: "Create pluggable database"
  },
  createSupport: {
    detailsTab: {
      sectionDescriptions: {
        problemDetails:
          "Additional information is required to promptly process your request for a quota increase.",
        tellUs:
          "Providing detailed, accurate information helps us resolve your issue faster."
      },
      sectionTitles: {
        problemDetails: "Problem details",
        tellUs: "Tell us a little more information"
      },
      title: "Additional details"
    },
    problemTab: {
      sectionDescriptions: {
        tellUs:
          "Provide information about your billing, quota management, or technical issue (including requests for technical advice)."
      },
      sectionTitles: {
        tellUs: "Tell us your issue, and we'll help you resolve it"
      },
      title: "Problem description",
      validationMessages: {
        csiRequestPending:
          "Your request to have your Customer Support Identifier (CSI) approved for opening technical support requests (SRs) is pending. Contact your Oracle Support account administrator and ask them to complete the approval in My Oracle Support (MOS). For immediate assistance with your issue, see 'Talk to Oracle Support' at <a href='https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm'>https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm</a> and follow the instructions to open a service request (SR).",
        emailNotVerified:
          "An Azure verified email is required to file Oracle support tickets. For immediate assistance with your issue, see 'Talk to Oracle Support' at <a href='https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm'>https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm</a> and follow the instructions to open a service request (SR). Contact your organization's Azure administrator to add an email address to your account.",
        issueTypePrereqs: "Pre-requisites required to create this issue type.",
        noCsiAccount:
          "Your account does not have an associated CSI (a Customer Support Identifier), needed to create a technical support request. For immediate assistance with your issue, see 'Talk to Oracle Support' at <a href='https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm'>https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm</a> and follow the instructions to open a service request (SR). Contact your Oracle Database for Azure administrator to have a CSI added to your account.",
        noCsiRequest:
          "Your My Oracle Support (MOS) account does not have a Customer Support Identifier (CSI) approved by your account administrator. This approval is required to open technical support requests (SRs). Please follow the steps below or for immediate assistance with your issue, see 'Talk to Oracle Support' at <a href='https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm'>https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm</a> and follow the instructions to open a service request.",
        noCsiRequestStep1:
          "Login to your My Oracle Support account at <a href='https://support.oracle.com'>https://support.oracle.com</a>. Then select your user name at the top right of the page, open the menu, and then select 'My Account'.",
        noCsiRequestStep2:
          "On your 'My Account' tab, you must use the 'Request Access' button and enter the Customer Support Identifier (CSI) for your account and then your administrator must approve. Your Oracle Database for Azure account administrator will give you the CSI number to add on your 'My Account' tab in your <a href='https://support.oracle.com'>https://support.oracle.com</a> account.",
        noEmail:
          "An Azure verified email is required to file Oracle support tickets. For immediate assistance with your issue, see 'Talk to Oracle Support' at <a href='https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm'>https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm</a> and follow the instructions to open a service request (SR). Contact your organization's Azure administrator to add an email address to your account.",
        noMosAccount1:
          "To open a technical support request (SR), you must have a My Oracle Support (MOS) account. For immediate assistance with your issue, see 'Talk to Oracle Support' at <a href='https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm'>https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm</a> and follow the instructions to open a service request.",
        noMosAccount1Step1:
          "To create your My Oracle Support account, visit <a href='https://support.oracle.com'>https://support.oracle.com</a>. Use the verified email that is associated with your Azure account. If needed, contact your Azure administrator to have an email address added to your account.",
        noMosAccount1Step2:
          "Login to your My Oracle Support account at <a href='https://support.oracle.com'>https://support.oracle.com</a>. Then select your user name at the top right of the page, open the menu, and then select 'My Account'.",
        noMosAccount1Step3:
          "On your 'My Account' tab, you must use the 'Request Access' button and enter the Customer Support Identifier (CSI) for your account and then your administrator must approve. Your Oracle Database for Azure account administrator will give you the CSI number to add on your 'My Account' tab in your <a href='https://support.oracle.com'>https://support.oracle.com</a> account.",
        noMosAccount2:
          "If you are an Oracle Support account administrator, follow these steps to complete the verification process and approve the request:",
        noMosAccount2Step1:
          "Sign in to <a href='https://support.oracle.com'>https://support.oracle.com</a>.",
        noMosAccount2Step2:
          "Accept the terms and conditions. Go to your user name at the top of the page, open the menu, and then click 'My Account'.",
        noMosAccount2Step3:
          "In the menu bar at the top of the page, click the 'Message Center' icon, and then click 'Approve Pending User Request'.",
        title: "Unable to create support requests"
      }
    },
    submitPayload: {
      quotaTitle: "Limit increase for {quota}"
    },
    titles: {
      long: "Create support request",
      short: "Create support request"
    },
    userWarning:
      "After you submit your support request, return to the Support listing to review and respond to updates in your ticket. Check your support tickets regularly for messages from our Support team to ensure your issues are addressed in a timely manner."
  },
  createVMCluster: {
    basicsTab: {
      title: "Basics"
    },
    configurationTab: {
      title: "Configuration"
    },
    networkingTab: {
      title: "Networking"
    },
    securityTab: {
      title: "Security"
    },
    titles: {
      long: "Create VM cluster",
      short: "Create VM cluster"
    }
  },
  createVmDb: {
    basicsTab: {
      sectionTitles: {
        instanceDetails: "Instance details",
        projectDetails: "Project details"
      },
      title: "Basics"
    },
    configurationTab: {
      sectionTitles: {
        databaseDetails: "Database details",
        databaseHomeDetails: "Database home details",
        databaseSystemDetails: "Database system details"
      },
      title: "Configuration"
    },
    managementTab: {
      info: {
        backupInfo:
          "All prerequisites for backing up to Oracle Cloud Infrastructure Object Storage must be met in the local and remote regions for automatic and remote backups to work."
      },
      sectionTitles: {
        backupHeader: "Backup"
      },
      title: "Management"
    },
    networkingTab: {
      sectionTitles: {
        databaseSystemNetworking: "Database system networking",
        networkPeering: "Network peering"
      },
      title: "Networking"
    },
    securityTab: {
      sectionTitles: {
        databaseAdministratorCredentials: "Database administrator credentials",
        databaseSystemAuthentication: "Database system authentication"
      },
      title: "Security"
    },
    titles: {
      long: "Create Base Database",
      longFromBackup: "Create Base Database from backup",
      short: "Create Base Database",
      shortFromBackup: "Create Base Database from backup"
    }
  },
  dbEditionOptions: {
    enterpriseEdition: "Oracle Database Enterprise Edition (EE)",
    standardEdition: "Oracle Database Standard Edition (SE)"
  },
  dbSystemDatabaseEditionOptions: {
    standardEdition: "Standard edition",
    enterpriseEdition: "Enterprise edition",
    enterpriseEditionHighPerformance: "Enterprise edition high performance",
    enterpriseEditionExtremePerformance:
      "Enterprise edition extreme performance"
  },
  deployment: {
    emptyList: {
      title: "No deployment to display."
    },
    loadError: {
      general: {
        details:
          "The deployment resource is unavailable, please try again later.",
        title: "The deployment resource was unavailable"
      },
      notFound: {
        details:
          "The deployment resource was not found, it may have been deleted.",
        title: "The deployment resource was not found"
      }
    },
    messages: {
      cleanup:
        "Deployment records completed more than 90 days ago will be automatically cleaned up. Note that any resources created by the deployments will not be affected."
    },
    overview: {
      sectionTitles: {
        deploymentDetails: "Deployment details",
        deploymentFailed: "Deployment failed. Click here for details ->",
        deploymentStatusAccepted: "Your deployment is accepted",
        deploymentStatusCanceled: "Your deployment is canceled",
        deploymentStatusCanceling: "Your deployment is being canceled",
        deploymentStatusFailed: "Your deployment has failed",
        deploymentStatusInProgress: "Your deployment is in progress",
        deploymentStatusSucceeded: "Your deployment is complete",
        nextSteps: "Next steps"
      }
    },
    titles: {
      deployment: "Deployment",
      long: "Deployments",
      short: "Deployments"
    }
  },
  detailAdbs: {
    connect: {
      sectionTitles: {
        connectionStrings: "Connection strings"
      }
    },
    loadError: {
      general: {
        details:
          "The Autonomous database is unavailable, please try again later.",
        title: "The Autonomous database was unavailable"
      },
      notFound: {
        details:
          "The Autonomous database was not found, it may have been deleted.",
        title: "The Autonomous database was not found"
      }
    },
    networking: {
      sectionTitles: {
        ipsAndOrCidrs: "IP Addresses or CIDRs"
      }
    },
    titles: {
      long: "Autonomous Database",
      short: "Autonomous Database"
    },
    upgradeToATP: {
      title: "Convert to Autonomous Transaction Processing",
      warning: "This action is irrevocable",
      description:
        "You can convert this instance to Autonomous Transaction Processing. After conversion, Oracle bills you for the following:",
      description1stPoint:
        "Oracle Autonomous Transaction Processing - OCPU per hour (B90453)",
      description2ndPoint:
        "Oracle Autonomous Transaction Processing - Exadata Storage (B90455)",
      descriptionConfirmation:
        "Are you sure you want to convert the instance <strong>{instanceName}</strong> to Autonomous Transaction Processing?",
      descriptionNote:
        "<strong>Note:</strong> The license type remains License included after conversion but you can change it to BYOL at any time."
    }
  },
  detailApex: {
    loadError: {
      general: {
        details: "The APEX instance is unavailable, please try again later.",
        title: "The APEX instance was unavailable"
      },
      notFound: {
        details: "The APEX instance was not found, it may have been deleted.",
        title: "The APEX instance was not found"
      }
    },
    titles: {
      long: "APEX instance",
      short: "APEX instance"
    }
  },
  detailExaDb: {
    connect: {
      sectionTitles: {
        connectionStrings: "Connection strings"
      }
    },
    loadError: {
      general: {
        details:
          "The Exadata database resource is unavailable, please try again later.",
        title: "The Exadata database resource was unavailable"
      },
      notFound: {
        details:
          "The Exadata database resource was not found, it may have been deleted.",
        title: "The Exadata database resource was not found"
      }
    },
    titles: {
      long: "Exadata Database",
      short: "Exadata Database"
    }
  },
  detailInfra: {
    loadError: {
      general: {
        details:
          "The Exadata infrastructure resource is unavailable, please try again later.",
        title: "The Exdata infrastructure resource was unavailable"
      },
      notFound: {
        details:
          "The Exadata infrastructure resource was not found, it may have been deleted.",
        title: "The Exdata infrastructure resource was not found"
      }
    },
    titles: {
      long: "Exadata Infrastructure",
      short: "Exadata Infrastructure "
    }
  },
  detailMcvcn: {
    configuration: {
      sectionTitles: {
        ociSubnets: "OCI subnets"
      }
    },
    loadError: {
      general: {
        details:
          "The virtual cloud network resource is unavailable, please try again later.",
        title: "The virtual cloud network resource was unavailable"
      },
      notFound: {
        details:
          "The virtual cloud network resource was not found, it may have been deleted.",
        title: "The virtual cloud network resource was not found"
      }
    },
    titles: {
      long: "Virtual Cloud Network",
      short: "Virtual Cloud Network"
    }
  },
  detailMysql: {
    crashRecoveryConfirmation: {
      enableConfirmation:
        "Are you sure you want to enable crash recovery for the MySQL HeatWave <strong>{dbName}</strong> ?",
      enableDescription:
        "Enable crash recovery to ensure durability and enable data recovery in the event of an unexpected server exit. You can disable it to improve performance of large data imports.",
      enableTitle: "Enable crash recovery",
      disableConfirmation:
        "Are you sure you want to disable crash recovery for the MySQL HeatWave <strong>{dbName}</strong> ?",
      disableTitle: "Disable crash recovery",
      disableWarnig:
        "Disabling crash recovery prevents the creation of backups and prevents stopping or restarting the MySQL HeatWave. To perform those actions, you must enable crash recovery. Disabling crash recovery is only recommended for batch loading or testing."
    },
    infoBlock: {
      crashRecoveryDisabled:
        "You need to enable crash recovery to allow the stop and restart actions."
    },
    loadError: {
      general: {
        details:
          "The MySQL HeatWave resource is unavailable, please try again later.",
        title: "The MySQL HeatWave resource was unavailable"
      },
      notFound: {
        details:
          "The MySQL HeatWave resource was not found, it may have been deleted.",
        title: "The MySQL HeatWave resource was not found"
      }
    },
    messages: {
      backupDisabled:
        "You need to enable crash recovery to allow automatic backups.",
      crashRecoverDisabled:
        "You need to disable automatic backups to disable crash recovery."
    },
    titles: {
      long: "MySQL HeatWave",
      short: "MySQL HeatWave"
    }
  },
  detailMysqlBackup: {
    loadError: {
      general: {
        details:
          "The MySQL HeatWave backup resource is unavailable, please try again later.",
        title: "The MySQL HeatWave backup resource was unavailable"
      },
      notFound: {
        details:
          "The MySQL HeatWave backup resource was not found, it may have been deleted.",
        title: "The MySQL HeatWave backup resource was not found"
      }
    },
    titles: {
      long: "MySQL HeatWave backup",
      short: "MySQL HeatWave backup"
    },
    warning: "You need to enable crash recovery to allow creating backups."
  },
  detailSupport: {
    loadError: {
      general: {
        details:
          "The support request resource is unavailable, please try again later.",
        title: "The support request resource was unavailable"
      },
      notFound: {
        details:
          "The support request resource was not found, it may have been deleted.",
        title: "The support request resource was not found"
      }
    },
    overview: {
      titles: {
        contactInfo: "Contact info",
        details: "Details",
        overview: "Overview"
      }
    },
    titles: {
      short: "Support",
      supportRequest: "Support Request: {title}"
    }
  },
  detailVmCluster: {
    loadError: {
      general: {
        details:
          "The VM cluster resource is unavailable, please try again later.",
        title: "The VM cluster resource was unavailable"
      },
      notFound: {
        details:
          "The VM cluster resource was not found, it may have been deleted.",
        title: "The VM cluster resource was not found"
      }
    },
    titles: {
      long: "VM Cluster",
      short: "VM Cluster"
    }
  },
  detailVmDb: {
    connect: {
      sectionTitles: {
        connectionStrings: "Connection strings"
      }
    },
    loadError: {
      general: {
        details: "The Base database is unavailable, please try again later.",
        title: "The Base database was unavailable"
      },
      notFound: {
        details: "The Base database was not found, it may have been deleted.",
        title: "The Base database was not found"
      }
    },
    titles: {
      long: "Base Database",
      short: "Base Database"
    }
  },
  detailVmDbSystem: {
    loadError: {
      general: {
        details:
          "The Base database system resource is unavailable, please try again later.",
        title: "The Base database system resource was unavailable"
      },
      notFound: {
        details:
          "The Base database system resource was not found, it may have been deleted.",
        title: "The Base database system resource was not found"
      }
    },
    titles: {
      long: "Base Database System",
      short: "Base Database System"
    }
  },
  feedback: {
    infoBlock: "If you need help, please contact support.",
    subtitle: "Thank you for taking the time to give us feedback.",
    title: "Send feedback to Oracle"
  },
  footer: {
    oracleCopyright:
      "Copyright {copyRightSymbol} {year}, Oracle and/or its affiliates. All rights reserved.",
    termsOfUseAndPrivacy: "Terms of Use and Privacy"
  },
  groupBy: {
    containerDb: "Group by container database",
    databaseSystem: "Group by database system",
    location: "Group by location",
    resourceGroup: "Group by resource group",
    status: "Group by status",
    subscription: "Group by subscription"
  },
  hints: {
    accessFromAllowedIPs:
      "<strong>Secure access from allowed IP addresses</strong> - Restrict access to specified IP addresses from the public internet. ",
    accessFromAzurePrivateEndpoint:
      "<strong>Private endpoint access only</strong> - Restrict access to a private endpoint within an Azure VNet.",
    accessFromEverywhere:
      "<strong>Secure access from everywhere</strong> - Allow users with database credentials to access the database from everywhere.",
    adbsDisabledActionsAdbsStatus:
      "{adbsActions} are not available when the autonomous database status is {adbsStatus}",
    allocateStorageLocal:
      "Select this option to allocate more space to the RECO disk group, which can be used to store backups. You must select this option if you want to use local Exadata storage as a backup destination for any databases that you create in this VM Cluster.",
    allocateStorageSparse:
      "Select this option to create a sparse disk group, which is required to support Exadata snapshot functionality.",
    bringYourOwnLicense:
      "<strong>Bring your own license (BYOL)</strong> - Bring my organization’s Oracle Database software license to the Database service. <a href={url}>Learn more</a>",
    cdbDisabledActions:
      "{pdbActions} are not available for container databases",
    createHeatWave: "Do you want to create a HeatWave cluster?",
    createVnet: "Only one virtual network attachment is allowed",
    deleteAdbsConfirmation:
      "Do you want to delete the Autonomous database '{dbName}'?",
    deleteBackupConfirmation:
      "Do you want to delete the backup '{backupName}'?",
    deleteExaCSDBConfirmation:
      "Do you want to delete the Exadata database '{dbName}'?",
    deleteHeatWave:
      "Do you want to delete the HeatWave cluster? Creating a new HeatWave cluster will take some time.",
    deleteMcVcnConfirmation:
      "Do you want to delete the virtual cloud network '{mcVcn}'?",
    deleteMysqlConfirmation:
      "Do you want to delete the MySQL HeatWave '{dbName}'?",
    deletePluggableDBConfirmation:
      "Do you want to delete the pluggable database '{dbName}'?",
    deleteVMClusterConfirmation:
      "Do you want to delete the virtual machine cluster '{clusterName}'?",
    deleteVMDbConfirmation:
      "Do you want to delete the Base database '{dbName}'?",
    deleteVMDbSystemConfirmation:
      "Do you want to delete the Base database system '{name}'?",
    detachVirtualNetworkConfirmation:
      "Do you want to detach the virtual network '{name}'",
    disabledAddCapacityAction:
      "To add capacity, first scale the Exadata infrastructure.",
    downloadKey:
      "Download the private key while opting to generate the ssh key so that you can connect to the database system using SSH. It will not be shown again.",
    editNetworkMcvcnCreate:
      "New virtual cloud networks can be created from the create a resource page.",
    enablePitrPolicy:
      "Enables you to restore from a DB system at a point in time",
    exaDbBackupDisabledDeleteAction:
      "Incremental backup types cannot be deleted.",
    ipsAndOrCidrs:
      "You can enter IP addresses. For example 192.1.1.1. You can also enter CIDRs. For example 10.0.0.1/16.",
    licenseIncluded:
      "<strong>License included</strong> - Subscribe to new Oracle Database software licenses and the Database service.",
    mcvcnDeleteInfo:
      "Only virtual cloud networks without any virtual network attachments can be deleted.",
    networkPeering:
      "Enabling network peering will allow bidirectional access from the Azure virtual network to the OCI virtual cloud network.",
    noHeatWave: "There is no HeatWave cluster associated with this DB system.",
    noResourcesMatch: "No resources match your filters",
    autoScaling:
      "Allows system to use up to three times the number of cores specified by the ECPU/OCPU count as the workload increases.",
    oracleDbEE:
      "<strong>Oracle Database Enterprise Edition (EE)</strong> - User-selected OCPU limit. <a href={url}>Learn more</a>",
    oracleDbSE:
      "<strong>Oracle Database Standard Edition (SE)</strong> - Up to 8 OCPUs, including auto scale. <a href={url}>Learn more</a>",
    ecpuOracleDbEE:
      "<strong>Oracle Database Enterprise Edition (EE)</strong> - User-selected ECPU limit. <a href={url}>Learn more</a>",
    ecpuOracleDbSE:
      "<strong>Oracle Database Standard Edition (SE)</strong> - Up to 32 ECPUs , including auto scale. <a href={url}>Learn more</a>",
    adbsECPUFAQ: "Autonomous Database ECPU Model <a href={url}>FAQ</a>",
    pasteToUpload: "Paste the public key here to upload",
    pdbDisabledActionCdbStatus:
      "{pdbAction} is not available when the container database status is {cdbStatus}",
    pdbDisabledActionPdbStatus:
      "{pdbAction} is not available when the pluggable database status is {pdbStatus}",
    pdbDisabledActionsCdbStatus:
      "{pdbActions} are not available when the container database status is {cdbStatus}",
    pdbDisabledActionsPdbStatus:
      "{pdbActions} are not available when the pluggable database status is {pdbStatus}",
    regionAddNotAvailable:
      "Current plan does not grant you access to add additional locations. Please upgrade to a paid account.",
    regionAddOciTenant: "Only paid accounts can be extended to a new location.",
    regionNotAdded:
      "To be able to use this region, the location must first be added from the subscription management page.",
    requiretMTLSAuthentication:
      "If you select this option, mTLS will be required to authenticate connections to your Autonomous Database.",
    restartAdbsConfirmation:
      "Do you want to restart the Autonomous database '{dbName}'?",
    restartHeatWaveClusterConfirmation:
      "Do you want to restart the HeatWave cluster for MySQL DB system '{name}'?",
    restartMysqlConfirmation:
      "Do you want to restart the MySQL DB System '{dbName}'?",
    restoreBackupConfirmation:
      "Are you sure you want to restore the database using backup '{backupName}'?",
    rotateWalletAdbsConfirmation:
      "Rotating the wallet invalidates the certificate keys associated with the existing wallet and generates a new wallet. Existing connections to the Autonomous Database that use the old wallet will be terminated over a period of time and will need to be re-established using the new wallet. If you need to terminate all existing connections to a database immediately, stop and restart the database. Do you want to rotate the Autonomous database '{dbName}' wallet?",
    routeVNET:
      "Using direct connection routing will route Autonomous Database (ADB) client traffic from the Azure virtual network over a high-speed secure link. All other ADB traffic will continue to use secure connections over the public Internet.",
    scaleExaInfra:
      "After scaling your infrastructure, you must scale up the VM cluster to use the increased capacity.",
    selectAExadataBase: "Select a Exadata Base",
    selectAHeatwaveShape: "Select a HeatWave (shape)",
    selectAMySQLVersion: "Select a MySQL version",
    selectASystemModelShape: "Select a System model (shape)",
    selectAvailableDataStorage: "Select an available data storage",
    selectOcpuCountChangeToFlex:
      "OCPU count will remain the same during the migration and can be increased post migration.",
    selectOracleGridInfrastructureVersion:
      "Select Oracle Grid infrastructure version",
    selectVMShape: "Select a VM shape",
    shapeUnavailable:
      "Some shapes are unavailable because they are not compatible with the current DB system shape.",
    startAdbsConfirmation:
      "Do you want to start the Autonomous database '{dbName}'?",
    startExaCSDBConfirmation:
      "Do you want to start the Exadata database '{dbName}'?",
    startHeatWaveClusterConfirmation:
      "Do you want to start the HeatWave cluster for MySQL DB system '{name}'?",
    startMysqlConfirmation:
      "Do you want to start the MySQL DB System '{dbName}'?",
    startVmDBConfirmation: "Do you want to start the Base database '{dbName}'?",
    stopAdbsConfirmation:
      "Do you want to stop the Autonomous database '{dbName}'?",
    stopExaCSDBConfirmation:
      "Do you want to stop the Exadata database '{dbName}'?",
    stopHeatWaveClusterConfirmation:
      "Do you want to stop the HeatWave cluster for MySQL DB system '{name}'?",
    stopMysqlConfirmation:
      "Do you want to stop the MySQL DB System '{dbName}'?",
    stopVmDBConfirmation: "Do you want to stop the Base database '{dbName}'?",
    tagsNoChanges: "No changes",
    tagsToAdd: "{count} to be added",
    tagsToDelete: "{count} to be deleted",
    tagsTooltipAdding: "Adding: {resources}",
    tagsTooltipDeleting: "Deleting: {resources}",
    tagsTooltipUpdating: "Updating: {resources}",
    tagsToUpdate: "{count} to be updated",
    tdeWalletPasswordInfo:
      "TDE wallet password must match TDE wallet password of container database.",
    tooltipAdminPassword:
      "The database admin password must contain at least 2 uppercase, 2 lowercase, 2 special, and 2 numeric characters. The special characters must be _, #, or -.",
    toolTipCidr:
      "The virtual network's address space specified in CIDR notation. <a href={link}>Learn more about sizing guidelines</a>",
    tooltipFilePrivateInfo:
      "Make this selection if your file may contain (i) personal information (ii) protected health information (PHI) subject to HIPAA. Do not provide any other sensitive personal information (such as payment card data) or U.S. federal government covered defense information (CDI) or controlled unclassified information (CUI) that requires protections greater than those specified in <a href={link}>Oracle GCS Security Practices</a>. Please note: The information you provide will be transferred and accessed on a global basis by Oracle resources.",
    tooltipMySQLAdminPassword:
      "The database admin password must contain at least 1 uppercase, 1 lowercase, 1 numeric, and 1 special characters.",
    tooltipMysqlAdminPassword:
      "The password must be between {minValue} and {maxValue} characters long, and must contain at least 1 numeric character, 1 lowercase character, 1 uppercase character, and 1 special (non-alphanumeric) character.",
    tooltipMySQLBackup: "The backup",
    tooltipMySQLBackupRetentionPeriod:
      "The retention period defines how long to store the backups, in days.",
    tooltipMySQLCrashRecovery:
      "Enable crash recovery to ensure durability and enable data recovery in the event of an unexpected server exit. You can disable it to improve performance of large data imports.",
    toolTipMySQLDatabaseDescription:
      "The User provided data about the database system.",
    toolTipMySQLDatabaseSystem: "The database system",
    toolTipMySQLDatabaseSystemName:
      "The user-friendly name for the database system.",
    toolTipMySQLDataStorageSize:
      "The amount of storage to allocate to the database system for all data and log files.",
    tooltipMySQLDeleteProtected:
      "Protects the database system against delete operations. To delete the database system, this option must be disabled. By default, database systems are not delete protected.",
    tooltipMySQLEnableAutomaticBackups:
      "Enables automatic backups. You must also specify a retention period, and select a backup window. {pitrPolicy}",
    toolTipMySQLHostName:
      "The hostname must be unique within the subnet. If it is not unique, the database system will fail to provision.",
    toolTipMySQLIpAddress:
      "Define a private IP address to assign to the database system's endpoint. The address must be available in the subnet's CIDR. If you do not specify an address, Oracle automatically assigns a private IP address from the subnet. <a href={link}>Learn more</a>",
    tooltipMySQLRequireFinalBackup:
      "Require a final backup before deleting the database system. By default, skip final backup.",
    tooltipMySQLRetainAutomaticBackups:
      "Retain automatic backups after the database system is deleted. By default, Automatic Backups are deleted if the database system is deleted.",
    toolTipMySQLUserName:
      "The administrator username is required to be between {min} and {max} characters and it must not contain any of the following symbols: {characters} or any of the following reserved names: {reservedNames}.",
    tooltipMySQLWindowStartTime:
      "The backup window start time defines the start of the time period during which your database system is backed up.",
    tooltipNetworkVirtualAppliance:
      "Specify the IP address of the network virtual appliance to which return traffic is sent to",
    tooltipPasswordAdb:
      'Password must contain at least one uppercase letter, one lowercase letter, and one number. The password cannot contain the double quote (") character or the username "admin", regardless of casing.',
    tooltipPluggableDatabase:
      "A pluggable database (PDB) is a portable collection of schemas, schema objects, and non-schema objects that can be plugged into a container database (CDB). Learn more about <a href={link}>pluggable databases (PDBs)</a>.",
    toolTipRegion:
      "Choose the Azure region that's right for you and your customers.",
    toolTipResourceGroup:
      "A resource group is a collection of resources that share the same lifecycle, permissions, and policies.",
    toolTipRestoreAdbsTime:
      "Specify a timestamp within the last 60 days to use for the point-in-time restore.",
    toolTipSelectOcpuCount:
      "The value must be between {minValue} and {maxValue} with an increment of {stepValue}.",
    toolTipSubscription:
      "All resources in an Azure subscription are billed together.",
    tooltipTagName:
      "Note that if you create tags and then change resource settings on other tabs your tags will be automatically updated.",
    tooltipTagValue:
      "Tag values are case-sensitive and are limited to 256 characters.",
    tooltipTdePassword:
      "The new TDE wallet password must contain at least 2 uppercase, 2 lowercase, 2 special, and 2 numeric characters. The special characters must be _, #, or -.",
    tooltipVNET:
      "If you do not see the VNET you are looking for in the list of available networks, you may need to do some additional configuration to the VNET to use it with ODSA. <a href={link}>Learn more...</a>",
    tooltipWalletPassword:
      "Password must be 8 to 60 characters and contain at least 1 alphabetic and 1 numeric character.",
    unlockMyPDBAdminAccount:
      "You must provide a PDB Admin password when unlocking the PDB admin account.",
    useDifferentTDEPassword:
      "If no other password if provided, the password entered for the SYS user is also used for the TDE wallet.",
    vmClusterAvailableCapacity:
      "Additional capacity available for this VM cluster : <strong>{nodeCount} database servers (nodes), {storageSize} GB of storage servers</strong>",
    vmClusterAvailableCapacityForNodeCount:
      "Additional capacity available for this VM cluster : <strong>{nodeCount} database servers (nodes)</strong>",
    vmClusterAvailableCapacityForStorage:
      "Additional capacity available for this VM cluster : <strong>{storageSize} GB of storage servers</strong>",
    vnetDeleteInfo:
      "Only virtual networks in failed or created state can be detached"
  },
  help: {
    documentation: {
      contactSupportCommunityHelp:
        "How to contact support and how to get community help.",
      description:
        "Visit the <a href={link}>Oracle Help Center</a> for complete Oracle Database Service for Azure documentation.",
      title: "Documentation"
    },
    needMoreHelp: {
      description:
        "Visit the support center to create and manage support requests and quota increases.",
      title: "Need more help or additional quota?"
    },
    title: "Help + support"
  },
  home: {
    banner: {
      noAccessToLinkedSubscriptions:
        "You don't have any Azure subscriptions linked to {shortBrand}. This could be because you have not been assigned the 'Contributor' role for the Azure subscriptions you want to use with {shortBrand}, or it could be because you have not completed the subscription linking step in the {shortBrand} portal. To get the 'Contributor' role for your subscriptions, contact your Azure account administrator. To link your subscriptions to {shortBrand}, navigate to the <strong>Subscription Management</strong> page in the {shortBrand} portal."
    },
    gettingStarted: {
      step1Description:
        "You must assign a Multicloudlink administrator role to a user in Azure before proceeding to subscription linking. Click below to get started.",
      step1Title: "Step 1: Assign {shortBrand} roles (required)",
      step2Description:
        "Subscription links define connections to Azure; you must have at least one subscription link defined to provision Oracle Database resources. Click below to view, and manage subscription links.",
      step2Title: "Step 2: Link your subscriptions (required)"
    },
    gettingStartedNewUser: {
      description:
        "For the Azure subscriptions you want to use with {shortBrand}, ask your Azure account administrator to assign the 'contributor' role to your user so that you can access the subscriptions in {shortBrand}. After your subscription roles are updated, log out of the {shortBrand} portal, wait a few minutes, and then log back in to establish a new authentication session that includes your role updates.",
      importantNote:
        "<strong>Important:</strong> After logging in to the {shortBrand} portal, if none of the subscriptions you want to use are linked to {shortBrand}, you will see an banner explaining that you need to link your Azure subscriptions to use {shortBrand}. Navigate to <strong>Subscription</strong> Management page in the {shortBrand} portal to link your subscriptions.",
      title: "Assign Azure roles for {shortBrand} subscriptions"
    },
    links: {
      gettingStartedADB: "Getting started with Autonomous Database",
      gettingStartedExadata: "Getting started with Exadata Database",
      gettingStartedMySQL: "Getting started with MySQL HeatWave",
      gettingStartedProvisioning: "All {shortBrand} Database documentation",
      gettingStartedVMDB: "Getting started with Base Database"
    },
    sections: {
      gettingStarted: "Welcome to {longBrand}",
      navigate: "Navigate",
      oracleService: "Oracle services",
      usefulLinks: "Useful links"
    }
  },
  inactivitySignOut: {
    confirmation: {
      buttonCancel: "Sign out now",
      buttonConfirm: "Do not sign out",
      message:
        "You will be signed out in less than one minute due to inactivity, do you wish to stay signed in?",
      title: "Logging out"
    },
    settings: {
      custom: "Custom duration",
      min15: "After 15 minutes",
      min30: "After 30 minutes",
      min45: "After 45 minutes",
      min60: "After 1 hour",
      min90: "After 1.5 hours",
      min120: "After 2 hours",
      never: "Never"
    }
  },
  instructions: {
    assignRoles: {
      azureLinkLabel: "Continue to Azure subscriptions",
      importantNote:
        "<strong>Note:</strong> After you update user roles in Azure, <strong>you will need to log out</strong> of the {shortBrand} portal, wait a few minutes, and <strong>then log back in</strong> for the role updates to be reflected in {shortBrand}. After logging in again, if none of the subscriptions you want to use are linked to {shortBrand}, you will see a banner explaining that you need to link your subscriptions to {shortBrand} to complete your service set up. Navigate to the <strong>Subscription Management</strong> page in the {shortBrand} portal to link your subscriptions.",
      title: "Assign Azure roles for {shortBrand} subscriptions",
      step1: "Sign in to the Azure portal.",
      step2:
        "Under <strong>Azure services</strong>, click <strong>Subscriptions</strong>. If you don't see <strong>Subscriptions</strong> here, use the search box to find it and navigate to the Subscriptions page.",
      step3:
        "In the list of subscriptions, click on the name of the subscription you want to manage to see details about the subscription.",
      step3b: "Click <strong>Access control (IAM)</strong> in the left panel",
      step4:
        "Click <strong>+ Add</strong>, then click <strong>Add role assignment</strong>.",
      step5:
        "Find the <strong>Contributor</strong> role and click <strong>View</strong> to see the role details.",
      step6: "Click <strong>Members</strong>.",
      step7:
        "In the <strong>Select members</strong> panel, select the Oracle Database Service enterprise application.",
      step8:
        "In the Assign access to field, select <strong>User, group, or service principal</strong>.",
      step9:
        "Click <strong>Review + assign</strong> and review the assignment details.",
      step10:
        "Click the <strong>Review + assign</strong> button to confirm the assignment.",
      step11:
        "Repeat these steps for the <strong>Network Contributor, Monitoring Metrics Publisher, and EventGrid Data Sender</strong> roles.",
      step12:
        "Under Azure services, click <strong>Azure Active Directory</strong>.",
      step13:
        "Under <strong>Manage</strong>, click <strong>Enterprise applications</strong>.",
      step14:
        "In the list of enterprise applications, click on the name of the <strong>Oracle Database Service</strong> application to load the application's Overview page.",
      step15:
        "Under <strong>Manage</strong>, click <strong>Users and groups</strong>.",
      step16:
        "Select the users you want to manage by clicking the checkbox beside the user name.",
      step17: "Click <strong>Edit</strong>.",
      step18:
        "On the <strong>Edit Assignment</strong> page, under <strong>Select a role</strong>, click <strong>None Selected</strong> to open the Select a role panel.",
      step19: "Select the Multicloud User role.",
      step20:
        "Click <strong>Select</strong>. The <strong>Edit Assignment</strong> page displays.",
      step21:
        "Review the assignment information, then click <strong>Assign</strong> to complete the assignment."
    },
    linkSubscriptions: {
      azureLinkLabel: "Continue to Azure enterprise applications",
      title: "Assign Multicloudlink Administrator role to {shortBrand} user",
      step1: "Sign in to the Azure portal.",
      step2:
        "Under <strong>Azure services</strong>, click <strong>More services</strong>, then click <strong>Azure Active Directory</strong>. Alternately, use the search box to search for Azure Active Directory and navigate to the service page.",
      step3:
        "Under <strong>Manage</strong>, click <strong>Enterprise applications</strong>.",
      step4:
        "In the list of enterprise applications, click on the name of the <strong>Oracle Database Service</strong> application to load the application's Overview page.",
      step5:
        "Under <strong>Manage</strong>, click <strong>Users and groups</strong>.",
      step6:
        "Select the users you want to manage by clicking the checkbox beside the user name.",
      step7: "Click <strong>Edit</strong>.",
      step8:
        "On the <strong>Edit Assignment</strong> page, under <strong>Select a role</strong>, click <strong>None Selected</strong> to open the <strong>Select a role</strong> panel.",
      step9:
        "Select the <strong>ODSA Multicloudlink Administrator</strong> role.",
      step10:
        "Click <strong>Select</strong>. The <strong>Edit Assignment</strong> page displays.",
      step11:
        "Review the assignment information, then click <strong>Assign</strong> to complete the assignment.",
      step12: "Sign out and sign back into {shortBrand}."
    }
  },
  labels: {
    additionalNodeCount: "Additional node count",
    additionalStorageInGB: "Additional storage (GB)",
    accessFromAllowedIPs: "Secure access from allowed IP addresses",
    accessFromAzurePrivateEndpoint: "Private endpoint access only",
    accessFromAzurePrivateEndpointPeering:
      "Private endpoint access only (peered)",
    accessFromEverywhere: "Secure access from everywhere",
    accessType: "Access type",
    activity: "Activity log",
    address: "Address",
    addresses: "Addresses",
    addressRange: "Address range",
    allocateStorageLocal: "Allocate storage for local backups",
    allocateStorageSparse: "Allocate storage for Exadata sparse snapshots",
    apex: "APEX",
    appearance: "Appearance",
    attachedDatabases: "Attached databases",
    automaticBackups: "Automatic backups",
    autonomousDatabase: "Autonomous database",
    autonomousDatabaseDedicated:
      "Autonomous Database on dedicated infrastructure",
    availableDataStorage: "Available data storage",
    availableDataStorageGB: "Available data storage (GB)",
    backup: "Backup",
    backupPlan: "Backup plan",
    backupRestoreLatest: "Restore to latest",
    backupRestoreSCN: "Restore to Oracle database system change number (SCN)",
    backupRestoreTimestamp: "Restore to a timestamp ({timezoneName})",
    backupRetentionPeriod: "Backup retention period",
    backups: "Backups",
    backupScheduling: "Backup scheduling",
    backupSizeInGB: "Backup size (GB)",
    backupType: "Backup type",
    byol: "Bring your own license (BYOL)",
    byolEE: "Bring your own license (BYOL) - Enterprise Edition",
    byolSE: "Bring your own license (BYOL) - Standard Edition",
    categories: "Categories",
    characterSet: "Character set",
    cidr: "CIDR",
    cidrBlocks: "CIDR blocks",
    closeTicket: "Close ticket",
    comments: "Comments",
    completed: "Completed",
    configuration: "Configuration",
    connect: "Connect",
    connectionString: "Connection string",
    connectionStrings: "Connection strings",
    connectionTypeMTLS: "mTLS",
    connectionTypeTCP: "TCP",
    connectionTypeTLS: "TLS",
    consentToContact: "Oracle can email you about your feedback",
    containedVmCluster: "Contained VM cluster",
    containedVmClusters: "Contained VM clusters",
    containerDatabases: "Container databases",
    containerDb: "Container database",
    coreCount: "Core count",
    crashRecovery: "Crash recovery",
    createAResource: "Create a resource",
    created: "Created",
    createdOn: "Created on",
    createHeatWave: "Create HeatWave cluster",
    createNewVcn: "Create new OCI virtual cloud network",
    creationType: "Creation Type",
    currentQuotaLimit: "Current {quota} limit",
    currentQuotaUsed: "Current {quota} used",
    customDuration: "Custom duration",
    database: "Database",
    databaseAccessEndpoint: "Database access endpoint",
    databaseBackupName: "Database backup name",
    databaseEdition: "Database edition",
    databaseHome: "Database home",
    databaseHomeName: "Database home name",
    databaseHomeVersion: "Database home version",
    databaseHomeLocation: "Database home location",
    databaseHomes: "Database homes",
    databaseName: "Database name",
    databaseNodes: "Database nodes",
    databases: "Databases",
    databaseServers: "Database servers",
    databaseSystem: "Database system",
    databaseSystems: "Database systems",
    databaseSystemName: "Database system name",
    databaseUniqueName: "Database unique name",
    databaseVersion: "Database version",
    dataStorageSize: "Data storage size (GB)",
    dbSystemShape: "DB system shape",
    dedicated: "Dedicated infrastructure",
    defaultLocation: "Default location",
    defaultSubscription: "Default subscription",
    deleteAdbs: "Delete Autonomous database",
    deleteBackup: "Delete full backup",
    deleteExaCSDB: "Delete Exadata database",
    deleteExaInfra: "Delete Exadata infrastructure",
    deleteHeatWave: "Delete HeatWave cluster",
    deletionPlan: "Deletion plan",
    deleteMcVcn: "Delete virtual cloud network",
    deleteMysql: "Delete MySQL HeatWave",
    deleteVMCluster: "Delete virtual machine cluster",
    deleteVMDb: "Delete Base database",
    deploymentId: "Deployment Id",
    deploymentName: "Deployment name",
    description: "Description",
    detachVirtualNetwork: "Detach virtual network",
    docked: "Docked",
    duration: "Duration",
    editTags: "Edit tags",
    email: "Email",
    enableAutomaticBackups: "Enable automatic backups",
    enableAutoScaling: "Enable auto scaling",
    ended: "Ended",
    enterTimestamp: "Enter timestamp ({timezoneName})",
    errorDetails: "Error details",
    errorMessage: "Error message",
    errors: "Errors",
    exaCS: "Exadata Database",
    exadataDatabase: "Exadata database",
    exadataDatabaseName: "Exadata database name",
    exadataInfra: "Exadata infrastructure",
    exadataInfraName: "Exadata infrastructure name",
    exadataSystem: "Exadata system",
    exadataSystemModel: "Exadata system model",
    exadataSystemName: "Exadata system name",
    favorite: "Favorite",
    feedbackPlaceholder: "Tell us about your experience...",
    feedbackSatisfaction: "Are you satisfied with your experience?",
    file: "File",
    filePrivateInfo: "File contains private information",
    flyout: "Flyout",
    full: "Full",
    giVersion: "GI version",
    heatwave: "HeatWave",
    heatwaveCluster: "HeatWave cluster",
    heatwaveClusterStatus: "HeatWave cluster status",
    hostIp: "Host IP",
    hostName: "Hostname prefix",
    hostNameOnly: "Hostname",
    id: "ID",
    infra: "Infrastructure",
    infras: "Infrastructures",
    incremental: "Incremental",
    internalFQDN: "Internal FQDN",
    ipAddress: "Database system IP address",
    ipsAndOrCidrs: "IP Addresses or CIDRs",
    issueType: "Issue type",
    language: "Language",
    lastAccessed: "Last accessed",
    lastViewed: "Last viewed",
    license: "License",
    licenseIncluded: "License included",
    licenseType: "License type",
    listenerPort: "Listener port",
    location: "Location",
    locations: "Locations",
    management: "Management",
    memoryInGB: "Memory (GB)",
    menuBehavior: "Menu behavior",
    messages: "Messages",
    mutualAuthentication: "Mutual TLS (mTLS) authentication",
    mySQLBackup: "Backup",
    mySQLDatabaseDescription: "Database description",
    mySQLDatabaseSystem: "Database system",
    mySQLDatabaseSystemName: "Database system name",
    mySQLDeleteProtected: "Delete protected",
    mySQLEnableCrashRecovery: "Enable crash recovery",
    mySQLEnableDeleteProtected: "Enable delete protected",
    mySQLEnableHeatwaveCluster: "Enable HeatWave cluster",
    mySQLEnableRequireFinalBackup: "Enable require final backups",
    mySQLEnableRetainAutomaticBackups: "Enable retain automatic backups",
    mySQLHeatwave: "MySQL HeatWave",
    mySQLHeatwaveShape: "HeatWave shape",
    mySQLPort: "MySQL port",
    mySQLPortX: "MySQL X Protocol port",
    mySQLRequireFinalBackup: "Require final backups",
    mySQLRestoreFromBackup: "Restore from backup",
    mySQLRetainAutomaticBackups: "Retain automatic backups",
    mySQLVersion: "MySQL version",
    mySQLWindowStartTime: "Window start time",
    name: "Name",
    nationalCharacterSet: "National character set",
    networking: "Networking",
    networkLink: "Network link",
    networkPeering: "Network peering",
    networkVirtualAppliance: "Network virtual appliance",
    newAdminPassword: "New admin password",
    newAdminConfirmPassword: "Confirm new admin password",
    newQuotaLimit: "New limit",
    newTdeWalletPassword: "New TDE wallet password",
    newTdeWalletConfirmPassword: "Confirm new TDE wallet password",
    nodeCount: "Node count",
    notifications: "Notifications",
    ociCidr: "OCI CIDR",
    ociCidrBlocks: "OCI VCN CIDR blocks",
    ociCidrs: "OCI CIDRs",
    ocid: "OCID",
    ociResource: "OCI resource",
    ociTenant: "OCI tenant",
    autoScaling: "Auto scaling",
    ocpuCount: "OCPU count",
    ecpuCount: "ECPU count",
    updateToECPUBillingModel: "Steps to update to ECPU billing model",
    ocpuCountPerVM: "OCPU count per node",
    oldTdeWalletPassword: "Old TDE wallet password",
    openMode: "Open mode",
    operationDetails: "Operation details",
    oracleDatabaseEdition: "Oracle Database Edition",
    oracleGridInfrastructureVersion: "Oracle Grid Infrastructure version",
    oracleSIDPrefix: "Oracle SID prefix",
    overview: "Overview",
    password: "Password",
    passwordConfirm: "Confirm password",
    pdbAdminPassword: "PDB Admin Password",
    pdbAdminPasswordConfirm: "Confirm PDB Admin Password",
    pdbName: "Pluggable database name",
    personaDirectory: "Directory: {name} ({id})",
    personaDomain: "Domain: {domain}",
    personaEmail: "Email: {email}",
    personaName: "Name: {name}",
    pitrPolicy: "Enable point in time restore",
    pluggableDatabase: "Pluggable database",
    pluggableDatabaseName: "Pluggable database name",
    pluggableDatabases: "Pluggable databases",
    pointInTimeRestore: "Point in time restore",
    popularProducts: "Popular products",
    privateEndpointIp: "Private endpoint IP",
    privateEndpointUrl: "Private endpoint URL",
    problemStartDate: "Problem start date",
    problemType: "Problem type",
    provideIssueDetails: "Please provide details about your issue",
    providerQuotaType: "{provider} quota type",
    quotaType: "Quota type",
    quotaUsageAndLimitAndRequested:
      "(Current usage: {usage}, Current limit: {oldLimit}, Requested limit: {newLimit})",
    recent: "Recent",
    recentlyCreated: "Recently created",
    region: "Region",
    registerExistingOciVcn: "Register existing OCI virtual cloud network",
    reopenTicket: "Reopen ticket",
    requestDetails: "Request details",
    requestedOCPUCount: "Requested OCPU count",
    requestSummary: "Request summary",
    requiretMTLSAuthentication: "Require mutual TLS (mTLS) authentication",
    resource: "Resource",
    resourceGroup: "Resource group",
    resourceId: "Resource Id",
    resources: "Resources",
    resourceToBeDeleted: "Resource to be deleted",
    resourceType: "Resource type",
    restartAdbs: "Restart Autonomous database",
    restartHeatWaveCluster: "Restart HeatWave cluster",
    restartMysql: "Restart MySQL DB System",
    restore: "Restore",
    retentionDays: "Retention days",
    retentionPeriod: "Retention period",
    retentionPeriodDays: "Retention period(days)",
    routerIpAddress: "Router IP Address",
    rotateWallet: "Rotate wallet",
    routerIpAddresses: "Router IP addresses",
    routeVNET: "Route using direct connection",
    scanDnsName: "Scan DNS name",
    scanIps: "Scan IPs",
    scanListenerPort: "Scan listener port",
    scanListenerPortSsl: "Scan listener SSL port",
    scheduling: "Scheduling (UTC)",
    security: "Security",
    selectResources: "Select resources",
    serviceType: "Service type",
    settings: "Portal settings",
    serverless: "Serverless",
    severity: "Severity",
    shape: "Shape",
    shapeSelection: "Shape Selection",
    shared: "Shared infrastructure",
    sidPrefix: "Oracle SID prefix",
    signingOut: "Signing out",
    signingOutSetting: "Signing out",
    signOutInactive: "Sign me out when inactive",
    softwareEdition: "Software edition",
    sshPublicKey: "SSH public key",
    sshPublicKeySource: "SSH public key source",
    startAdbs: "Start Autonomous database",
    started: "Started",
    startExaCSDB: "Start Exadata database",
    startHeatWaveCluster: "Start HeatWave cluster",
    startMysql: "Start MySQL DB System",
    startTime: "Start time",
    startVmDB: "Start Base database",
    status: "Status",
    stopAdbs: "Stop Autonomous database",
    stopExaCSDB: "Stop Exadata database",
    stopHeatWaveCluster: "Stop HeatWave cluster",
    stopMysql: "Stop MySQL DB System",
    stopVmDB: "Stop Base database",
    storage: "Storage",
    storageInTB: "Storage (TB)",
    storageLocalBackup: "Local backups storage",
    StorageManagementSoftware: "Storage management software",
    storageServers: "Storage servers",
    storageSparseSnapshots: "Exadata sparse snapshots storage",
    storageType: "Storage management software",
    storageValueInGBPlaceholder: "{storage} GB",
    storageVolumePerformance: "Storage performance",
    subnet: "Subnet",
    subnetOcids: "OCI subnet OCIDs",
    subnets: "Subnets",
    subscription: "Subscription",
    subscriptionId: "Subscription ID",
    subscriptionLocation: "Subscriptions + locations",
    summary: "Summary",
    support: "Support",
    supportRequestId: "Support request ID",
    systemModelShape: "System model (shape)",
    systemVersion: "System version",
    tableName: "Table name",
    tags: "Tags",
    tdePassword: "TDE wallet password",
    tdePasswordConfirm: "TDE wallet password confirm",
    tdeWalletPassword: "TDE wallet password of database",
    title: "Title",
    tnsName: "TNS name",
    toolsAccessEndpoint: "Tools access endpoint",
    totalMemorySize: "Total memory size",
    totalNodeCount: "Total node count",
    totalStorageInGB: "Total storage size (GB)",
    type: "Type",
    unlockMyPDBAdminAccount: "Unlock my PDB Admin account",
    updated: "Updated",
    upgradeNow: "Upgrade now",
    uploadKey: "Upload key",
    useDifferentTDEPassword: "Use another password for the TDE wallet",
    username: "Username",
    vcnOcid: "OCI virtual cloud network OCID",
    viewAll: "View All",
    vips: "VIPs",
    virtualCloudNetwork: "Virtual cloud network",
    virtualNetwork: "Virtual network",
    virtualNetworkAddresses: "Virtual network addresses",
    virtualNetworksAttachments: "Virtual networks attachments",
    vmCluster: "VM cluster",
    vmClusterConfiguration: "OCPU",
    vmClusterName: "VM cluster name",
    vmClusters: "VM clusters",
    vmDatabase: "Base database",
    vmDatabaseName: "Base database name",
    vmShape: "VM shape",
    workloadType: "Workload type"
  },
  listAdbs: {
    emptyList: {
      title: "No Autonomous databases match your filters."
    },
    filters: {
      listView: "List view"
    }
  },
  listApex: {
    emptyList: {
      title: "No APEX instances match your filters."
    },
    titles: {
      long: "APEX instances",
      short: "APEX instances"
    }
  },
  listCloudLink: {
    emptyList: {
      title: "No subscriptions match your filters."
    },
    filters: {
      listView: "List view"
    },
    messages: {
      lastOperationMessage: "{message} ({dateTime})",
      numActiveLocations: "{count} active",
      numFailedLocations: "{count} failed",
      numPendingLocations: "{count} pending"
    },
    titles: {
      locations: "Subscription Locations",
      long: "Subscription Management",
      short: "Subscription Management"
    }
  },
  listExaDb: {
    emptyList: {
      title: "No Exadata databases match your filters."
    },
    filters: {
      listView: "List view"
    },
    titles: {
      long: "Exadata Databases",
      short: "Exadata Databases"
    }
  },
  listExaInfra: {
    emptyList: {
      title: "No Exadata infrastructures match your filters."
    },
    filters: {
      listView: "List view"
    }
  },
  listFavorite: {
    titles: {
      long: "Favorite resources",
      short: "Favorite resources"
    },
    emptyList: {
      title: "No resources have been favorited"
    }
  },
  listMcvcn: {
    emptyList: {
      title: "No virtual cloud network match your filters."
    },
    titles: {
      long: "Virtual Cloud Networks",
      short: "Virtual Cloud Networks"
    }
  },
  listMysql: {
    emptyList: {
      title: "No MySQL HeatWave match your filters."
    },
    filters: {
      listView: "List view"
    },
    titles: {
      long: "MySQL HeatWave",
      short: "MySQL HeatWave"
    }
  },
  listNetLink: {
    filters: {
      listView: "List view"
    }
  },
  listRecent: {
    titles: {
      long: "Recent",
      short: "Recent"
    },
    emptyList: {
      title: "No resources have been viewed recently"
    }
  },
  listSupport: {
    emptyList: {
      title: "No recent support requests"
    },
    filters: {
      allHistory: "All history",
      past7days: "Past 7 days",
      past14days: "Past 14 days",
      past30days: "Past 30 days",
      past60days: "Past 60 days",
      past90days: "Past 90 days"
    },
    messages: {
      createDisabled:
        'You need "{role}" role to be able to create support requests. Ask your Azure admin or the person who signed up to ODSA to assign this role to you.'
    },
    status: {
      open: "Open"
    },
    titles: {
      long: "Support requests",
      short: "Support requests"
    }
  },
  listVmCluster: {
    emptyList: {
      title: "No VM clusters match your filters."
    },
    filters: {
      listView: "List view"
    }
  },
  listVmDb: {
    emptyList: {
      title: "No Base databases match your filters."
    },
    filters: {
      listView: "List view"
    },
    titles: {
      long: "Base Databases",
      short: "Base Databases"
    }
  },
  listVmDbSystem: {
    emptyList: {
      title: "No Base database systems match your filters."
    },
    filters: {
      listView: "List view"
    }
  },
  initErrors: {
    errorMessages: {
      duplicateSubscriptions:
        "Multiple active Multicloud links linked to the same subscription",
      noLocation: "Account not associated with a location"
    },
    genericError: {
      title: "Unexpected Error",
      message: "An unexpected error has occurred: {errorMessage}"
    },
    linkingInProgress: {
      title: "Account Setup In Progress",
      message: "The account is currently being setup. Try again later."
    },
    linkingUnknown: {
      title: "Unknown Account",
      message: "Unable to determine the state of the account: {apiError}"
    }
  },
  notifications: {
    apiErrorMsg:
      "Additional details from the underlying API that might be helpful: {errMsg}",
    deployedServices: {
      apex: "APEX instance",
      autonomousSharedDatabase: "Autonomous database",
      exadataDatabase: "Exadata database",
      exadataInfra: "Exadata infrastructure",
      exadataSystem: "Exadata system",
      exadataVmCluster: "Exadata VM cluster",
      mysqlDatabase: "MySQL HeatWave",
      vmDatabase: "Base database"
    },
    failure: {
      messages: {
        addCapacityVMCluster: "Failed to add capacity to VM Cluster '{name}'.",
        addRegion: "Failed to add location '{name}'.",
        addSshKey: "Failed to add SSH key on '{name}'.",
        applyTags: "Failed to apply tags on '{name}'.",
        attachVirtualNetwork: "Failed to attach virtual network '{name}.",
        clonePluggableDb: "Failed to clone pluggable database '{name}'.",
        closeSupportRequest: "Failed to close support request '{name}'.",
        createBackup: "Failed to create backup '{name}'.",
        createHeatWave:
          "Failed to create HeatWave cluster for MySQL DB system '{name}'.",
        createMcvcn: "Failed to create virtual cloud network '{name}'.",
        createPluggableDb: "Failed to create pluggable database '{name}'.",
        createResourceDeployment:
          "Deployment '{deploymentName}' for {resourceType} '{name}' failed to start.",
        createSupportRequest: "Failed to create support request.",
        deleteAdbs: "Failed to delete database '{name}'.",
        deleteBackup: "Failed to delete backup '{name}'.",
        deleteExaDb: "Failed to delete Exadata database '{name}'.",
        deleteExaInfra: "Failed to delete Exadata infrastructure '{name}'.",
        deleteHeatWave:
          "Failed to delete HeatWave cluster for MySQL DB system '{name}'",
        deleteMcVcn: "Failed to delete virtual cloud network '{name}'.",
        deleteMysql: "Failed to delete MySQL HeatWave '{name}'.",
        deletePluggableDb: "Failed to delete pluggable database '{name}'.",
        deleteVMCluster: "Failed to delete VM cluster '{name}'",
        deleteVMDb: "Failed to delete Base database '{name}'.",
        deleteVMDbSystem: "Failed to delete Base database systen '{name}'.",
        deployResource: "Failed to deploy {resourceType} '{name}'",
        detachVirtualNetwork: "Failed to detach virtual network '{name}'",
        downloadWallet:
          "Failed to download Autonomous database '{name}' wallet.",
        linkSubscription: "Failed to link subscription '{name}'.",
        loadAdbs: "Failed to load Autonomous database list",
        loadBackups: "Failed to load backups",
        loadBilling: "Failed to load billing",
        loadCloudLinks: "Failed to load Multicloud links",
        loadContainerDatabase: "Failed to load Exadata container database",
        loadDatabaseHomes: "Failed to load Exadata database homes",
        loadDatabaseNodes: "Failed to load Exadata database nodes",
        loadDatabases: "Failed to load Exadata databases",
        loadDbCharacterSet: "Failed to load Db character set list",
        loadDbNationalCharacterSet:
          "Failed to load Db national character set list",
        loadDbSystemInitialStorageSizes: "Failed to load initial storage sizes",
        loadDBSystemShape: "Failed to load Exadata system model list",
        loadDbSystemStorageScaleSizes: "Failed to load storage scale sizes",
        loadDbVersion: "Failed to load database version list",
        loadDeployment: "Failed to load deployment",
        loadExaDbCdbs: "Failed to load Exadata container database list",
        loadExadbHome: "Failed to load Exadata database home",
        loadExadbInfra: "Failed to load Exadata infrastructure",
        loadExadbInfraList: "Failed to load Exadata infrastructure list",
        loadExadbNode: "Failed to load Exadata database node",
        loadExaDbPdbs: "Failed to load Exadata pluggable database list",
        loadHeatwaveCluster: "Failed to load HeatWave cluster",
        loadInfra: "Failed to load Exadata infrastructure",
        loadMcvcn: "Failed to load virtual cloud network",
        loadMcvcns: "Failed to load virtual cloud network list",
        loadMysql: "Failed to load MySQL HeatWave list",
        loadMysqlBackupDetail: "Failed to load MySQL backup '{name}'",
        loadMysqlDatabase: "Failed to load MySQL database",
        loadMySQLHeatwaveShape: "Failed to load MySQL HeatWave shapes list",
        loadMySQLSystemModelShape: "Failed to load MySQL shapes list",
        loadMySQLVersion: "Failed to load MySQL version list",
        loadNetLink: "Failed to load network link",
        loadNetworkLinkWithName: "Failed to load network link '{name}'",
        loadOracleGridInfraVersion:
          "Failed to load Oracle Grid Infrastructure version list",
        loadPluggableDatabase: "Failed to load pluggable database",
        loadQuotas: "Failed to load quotas",
        loadQuotasLimitAvailability:
          "Failed to load the quota limit availability",
        loadQuotasLimitValue: "Failed to load the quota limit value",
        loadResourceGroup: "Failed to load resource group list",
        loadSchema: "Failed to load schema.",
        loadSubnets: "Failed to load subnet list",
        loadSubscription: "Failed to load subscription list",
        loadSupport: "Failed to load support request",
        loadSupportList: "Failed to load support request list",
        loadVMClusterConfig: "Failed to load OCPU configuration",
        loadVmClusterCapacity: "Failed to load VM Cluster available capacity",
        loadVMCluster: "Failed to load Exadata VM Cluster",
        loadVMClusters: "Failed to load Exadata VM Clusters",
        loadVMDbCdbs: "Failed to load Base container database list",
        loadVMDbHome: "Failed to load Base database home",
        loadVMDbNode: "Failed to load Base database node",
        loadVMDbPdbs: "Failed to load Base pluggable database list",
        loadVmDbSystem: "Failed to load Base database system",
        loadVmDbSystems: "Failed to load Base database system list",
        loadVNET: "Failed to load VNET list",
        reopenSupportRequest: "Failed to reopen support request '{name}'.",
        restartAdbs: "Failed to restart Autonomous database '{name}'.",
        restartHeatWaveCluster:
          "Failed to restart HeatWave cluster for MySQL DB system '{name}'.",
        restartMysql: "Failed to restart MySQL DB System '{name}'.",
        restore: "Failed to restore database '{name}'.",
        restoreAdbs: "Failed to restore Autonomous database '{name}'.",
        restoreFromBackup:
          "Failed to restore database '{name}' from backup '{backupName}'.",
        rotateWallet: "Failed to rotate Autonomous database '{name}' wallet.",
        scaleAdbs: "Failed to scale Autonomous database '{name}'.",
        scaleExaInfra: "Failed to scale Exadata infrastructure '{name}'.",
        scaleVmCluster: "Failed to scale VM cluster '{name}'.",
        scaleVmDbSystem: "Failed to scale Base database system '{name}'",
        searchSupportRequest:
          "We could not locate your support request. Please confirm the support request ID is valid.",
        startAdbs: "Failed to start Autonomous database '{name}'.",
        startExaDb: "Failed to start Exadata database '{name}'.",
        startHeatWaveCluster:
          "Failed to start HeatWave cluster for MySQL DB system '{name}'.",
        startMysql: "Failed to start MySQL DB System '{name}'.",
        startVMDb: "Failed to start Base database '{name}'.",
        stopAdbs: "Failed to stop Autonomous database '{name}'.",
        stopExaDb: "Failed to stop Exadata database '{name}'.",
        stopHeatWaveCluster:
          "Failed to stop HeatWave cluster for MySQL DB system '{name}'.",
        stopMysql: "Failed to stop MySQL DB System '{name}'.",
        stopVMDb: "Failed to stop Base database '{name}'.",
        submitFeedback: "Failed to submit feedback.",
        updateAdbsDbName:
          "Failed to update database name for Autonomous database '{name}'.",
        updateAdbsLicense:
          "Failed to update license for Autonomous database '{name}'",
        updateAdbsNetworking:
          "Failed to update network access for Autonomous database '{name}'.",
        updateAdbsNetworkMutualAuth:
          "Failed to update mutual TLS (mTLS) authentication for Autonomous database '{name}'.",
        updateAdbsPassword:
          "Failed to update password for Autonomous database '{name}'.",
        updateExadataVmClusterLicense:
          "Failed to update license for Exadata VM cluster '{name}'",
        updateHeatWaveNodeCount:
          "Failed to update node count for HeatWave cluster for MySQL DB system '{name}'",
        updateMysqlBackup:
          "Failed to update backup setting for MySQL HeatWave '{name}'.",
        updateMysqlBackupDescription:
          "Failed to update description for MySQL backup'{name}'.",
        updateMysqlBackupRetentionDays:
          "Failed to update retention days for MySQL backup'{name}'.",
        updateMysqlCrashRecovery:
          "Failed to update crash recovery setting for MySQL HeatWave '{name}'.",
        updateMysqlDataStorageSize:
          "Failed to update data storage size for MySQL database system '{name}'.",
        updateMysqlDeletionPlan:
          "Failed to update deletion plan setting for MySQL HeatWave '{name}'.",
        updateMysqlDescription:
          "Failed to update description for MySQL database system'{name}'.",
        updateMysqlPassword:
          "Failed to update password for MySQL database '{name}'.",
        updateMysqlShape:
          "Failed to update shape for MySQL database system '{name}'.",
        updateMysqlVersion:
          "Failed to update version for MySQL database system '{name}'.",
        updateVmDbSystemLicense:
          "Failed to update license for Base database system '{name}'",
        updateVmDbSystemStorage:
          "Failed to update storage for Base database system '{name}'",
        updateExaCdbBackup:
          "Failed to update backup configuration for Exadata database '{name}'.",
        updateSupportRequest: "Failed to update support request '{name}'.",
        updateVmCdbBackup:
          "Failed to update backup configuration for Base database '{name}'.",
        updateVmCdbPassword:
          "Failed to update password for Base database '{name}'.",
        updateVmTdePassword:
          "Failed to update TDE wallet password for Base database '{name}'.",
        upgradeToATP: "Failed to upgrade '{name}' to ATP.",
        uploadSupportRequestFile:
          "Failed to upload file to support request '{name}'.",
        validationCheck: "Failed to validate user input."
      },
      titles: {
        addCapacityVMCluster: "Failed to add capacity to VM Cluster",
        addRegion: "Failed to add location",
        addSshKey: "Failed to add SSH key",
        applyTags: "Failed to apply tags",
        attach: "Failed to attach",
        clone: "Failed to clone",
        closeSupportRequest: "Failed to close support request",
        create: "Failed to create",
        createResourceDeployment: "{resourceType} deployment",
        delete: "Failed to delete",
        deployResource: "Failed to deploy {resourceType}",
        detach: "Failed to detach",
        downloadWallet: "Failed to download wallet",
        linkSubscription: "Failed to link subscription",
        load: "Failed to load",
        reopenSupportRequest: "Failed to reopen support request",
        restart: "Failed to restart",
        restore: "Failed to restore",
        restoreAdbs: "Failed to restore",
        rotate: "Failed to rotate wallet",
        scale: "Failed to scale",
        scaleVmDbSystem: "Failed to scale Base database system",
        searchSupportRequest: "Failed to locate support request",
        start: "Failed to start",
        stop: "Failed to stop",
        submitFeedback: "Failed to submit feedback",
        updateAdbsDbName: "Failed to update Autonomous database name",
        updateAdbsLicense: "Failed to update Autonomous database license",
        updateAdbsNetworking:
          "Failed to update Autonomous database network access",
        updateAdbsNetworkMutualAuth:
          "Failed to update Autonomous database mutual TLS (mTLS) authentication",
        updateAdbsPassword: "Failed to update Autonomous database password",
        updateExadataVmClusterLicense:
          "Failed to update Exadata VM cluster license",
        updateHeatWaveNodeCount: "Failed to update HeatWave cluster node count",
        updateMysqlBackup: "Failed to update MySQL HeatWave backup setting",
        updateMysqlBackupDescription:
          "Failed to update MySQL backup description",
        updateMysqlBackupRetentionDays:
          "Failed to update MySQL backup retention days",
        updateMysqlCrashRecovery:
          "Failed to update MySQL HeatWave crash recovery setting",
        updateMysqlDataStorageSize:
          "Failed to update MySQL database data storage size",
        updateMysqlDeletionPlan:
          "Failed to update MySQL HeatWave deletion plan setting",
        updateMysqlDescription: "Failed to update MySQL database description",
        updateMysqlPassword: "Failed to update MySQL database password",
        updateMysqlShape: "Failed to update MySQL database system shape",
        updateMysqlVersion: "Failed to update MySQL database system version",
        updateVmDbSystemLicense:
          "Failed to update Base database system license",
        updateVmDbSystemStorage:
          "Failed to update Base database system storage",
        updateExaCdbBackup:
          "Failed to update Exadata database backup configuration",
        updateSupportRequest: "Failed to update support request",
        updateVmCdbBackup:
          "Failed to update Base database backup configuration",
        updateVmCdbPassword: "Failed to update Base database password",
        updateVmTdePassword:
          "Failed to update Base database TDE wallet password",
        upgradeToATP: "Failed to upgrade to ATP",
        uploadSupportRequestFile: "Failed to upload",
        validationCheck: "Failed to validate user input."
      }
    },
    inProgress: {
      messages: {
        addCapacityVMCluster: "Adding capacity to VM cluster '{name}'",
        addRegion: "Adding location '{name}'.",
        addSshKey: "Adding SSH key on '{name}'.",
        applyTags: "Applying tags on '{name}'.",
        attachVirtualNetwork: "Attaching virtual network '{name}.'",
        clonePluggableDb:
          "Cloning of pluggable database '{name}' is in progress.",
        closeSupportRequest:
          "Closing of support request '{name}' is in progress",
        createBackup: "Creation of backup '{name}' is in progress.",
        createHeatWave:
          "Creating HeatWave cluster for MySQL DB system '{name}'.",
        createMcvcn:
          "Creation of virtual cloud network '{name}' is in progress.",
        createPluggableDb:
          "Creation of pluggable database '{name}' is in progress.",
        deleteAdbs: "Deleting Autonomous database '{name}'.",
        deleteBackup: "Deleting backup '{name}'.",
        deleteExaDb: "Deleting Exadata database '{name}'.",
        deleteExaInfra: "Deleting Exadata infrastructure '{name}'.",
        deleteHeatWave:
          "Deleting HeatWave cluster for MySQL DB system '{name}'.",
        deleteMcVcn: "Deleting virtual cloud network '{name}'.",
        deleteMysql: "Deleting MySQL HeatWave '{name}'.",
        deletePluggableDb: "Deleting pluggable database '{name}'.",
        deleteVMCluster: "Deleting VM cluster '{name}'.",
        deleteVMDb: "Deleting Base database '{name}'.",
        deleteVMDbSystem: "Deleting Base database system '{name}'.",
        deployResource:
          "Deployment '{deploymentName}' for {resourceType} '{name}' is in progress.",
        detachVirtualNetwork: "Detaching virtual network '{name}'",
        linkSubscription: "Linking subscription '{name}'.",
        reopenSupportRequest:
          "Reopening of support request '{name}' is in progress",
        restartHeatWaveCluster:
          "Restarting HeatWave cluster for MySQL DB system '{name}'.",
        restartAdbs: "Restarting Autonomous database '{name}'.",
        restartMysql: "Restarting MySQL DB System '{name}'.",
        restore: "Restoring database '{name}'.",
        restoreAdbs: "Restoring Autonomous database '{name}'.",
        restoreFromBackup:
          "Restoring database '{name}' from backup '{backupName}'.",
        rotateWallet: "Rotating Autonomous database '{name}' wallet.",
        scaleAdbs: "Scaling Autonomous database '{name}'.",
        scaleExaInfra: "Scaling Exadata infrastructure '{name}'.",
        scaleVmCluster: "Scaling VM cluster '{name}'.",
        scaleVmDbSystem: "Scaling Base database system '{name} is in progress'",
        startAdbs: "Starting Autonomous database '{name}'.",
        startExaDb: "Starting Exadata database '{name}'.",
        startHeatWaveCluster:
          "Starting HeatWave cluster for MySQL DB system '{name}'.",
        startMysql: "Starting MySQL DB System '{name}'.",
        startVMDb: "Starting Base database '{name}'.",
        stopAdbs: "Stopping Autonomous database '{name}'.",
        stopExaDb: "Stopping Exadata database '{name}'.",
        stopHeatWaveCluster:
          "Stopping HeatWave cluster for MySQL DB system '{name}'.",
        stopMysql: "Stopping MySQL DB System '{name}'.",
        stopVMDb: "Stopping Base database '{name}'.",
        updateAdbsDbName:
          "Database name update for Autonomous database '{name}' is in progress",
        updateAdbsLicense:
          "License update for Autonomous database '{name} is in progress'",
        updateAdbsNetworking:
          "Network access update for Autonomous database '{name} is in progress'",
        updateAdbsNetworkMutualAuth:
          "Mutual TLS (mTLS) authentication update for Autonomous database '{name} is in progress'",
        updateAdbsPassword:
          "Password update for Autonomous database '{name}' is in progress",
        updateExaCdbBackup:
          "Backup configuration update for Exadata database '{name}' is in progress",
        updateExadataVmClusterLicense:
          "License update for Exadata VM cluster '{name} is in progress'",
        updateHeatWaveNodeCount:
          "Node count update for HeatWave cluster for MySQL DB system '{name}' is in progress",
        updateMysqlBackup:
          "Backup setting update for MySQL HeatWave '{name}' is in progress",
        updateMysqlBackupDescription:
          "Description update for MySQL backup '{name}' is in progress",
        updateMysqlBackupRetentionDays:
          "Retention days update for MySQL backup '{name}' is in progress",
        updateMysqlCrashRecovery:
          "Crash recovery setting update for MySQL HeatWave '{name}' is in progress",
        updateMysqlDataStorageSize:
          "Data storage size update for MySQL database system '{name}' is in progress",
        updateMysqlDeletionPlan:
          "Deletion plan setting update for MySQL HeatWave '{name}' is in progress",
        updateMysqlDescription:
          "Description update for MySQL database system '{name}' is in progress",
        updateMysqlPassword:
          "Password update for MySQL database '{name}' is in progress",
        updateMysqlShape:
          "Shape update for MySQL database system '{name}' is in progress",
        updateMysqlVersion:
          "Version update for MySQL database system '{name}' is in progress",
        updateSupportRequest:
          "Update of support request '{name}' is in progress",
        updateVmCdbBackup:
          "Backup configuration update for Base database '{name}' is in progress",
        updateVmCdbPassword:
          "Password update for Base database '{name}' is in progress",
        updateVmDbSystemLicense:
          "License update for Base database system '{name} is in progress'",
        updateVmDbSystemStorage:
          "Storage update for Base database system '{name} is in progress'",
        updateVmTdePassword:
          "TDE wallet password update for Base database '{name}' is in progress",
        upgradeToATP: "Upgrading '{name}' to ATP is in progress",
        uploadSupportRequestFile:
          "Upload file to support request '{name}' is in progress"
      },
      titles: {
        addCapacityVMCluster: "Adding Capacity for VM Cluster",
        addRegion: "Adding location",
        addSshKey: "Adding SSH key",
        applyTags: "Applying tags",
        attachVirtualNetwork: "Attaching virtual network",
        clonePluggableDb: "Cloning pluggable database",
        closeSupportRequest: "Closing support request",
        createBackup: "Creating backup",
        createHeatWave: "Creating HeatWave cluster",
        createMcvcn: "Creating virtual cloud network",
        createPluggableDb: "Creating pluggable database",
        deleteAdbs: "Deleting Autonomous database",
        deleteBackup: "Deleting backup",
        deleteExaDb: "Deleting Exadata database",
        deleteExaInfra: "Deleting Exadata infrastructure",
        deleteHeatWave: "Deleting HeatWave cluster",
        deleteMcVcn: "Deleting virtual cloud network",
        deleteMysql: "Deleting MySQL HeatWave",
        deletePluggableDb: "Deleting pluggable database",
        deleteVMCluster: "Deleting VM cluster",
        deleteVMDb: "Deleting Base database",
        deleteVMDbSystem: "Deleting Base database system",
        deployResource: "{resourceType} deployment in progress...",
        detachVirtualNetwork: "Detaching virtual network",
        linkSubscription: "Linking subscription",
        reopenSupportRequest: "Reopening support request",
        restartAdbs: "Restarting Autonomous database",
        restartHeatWaveCluster: "Restarting HeatWave cluster",
        restartMysql: "Restarting MySQL DB System",
        restore: "Restoring database",
        restoreAdbs: "Restoring Autonomous database",
        rotateWallet: "Rotating Autonomous database wallet",
        scaleAdbs: "Scaling Autonomous database",
        scaleExaInfra: "Scaling Exadata infrastructure",
        scaleVmCluster: "Scaling VM cluster",
        scaleVmDbSystem: "Scaling Base database system",
        startAdbs: "Starting Autonomous database",
        startExaDb: "Starting Exadata database",
        startHeatWaveCluster: "Starting HeatWave cluster",
        startMysql: "Starting MySQL DB System",
        startVMDb: "Starting Base database",
        stopAdbs: "Stopping Autonomous database",
        stopExaDb: "Stopping Exadata database",
        stopHeatWaveCluster: "Stopping HeatWave cluster",
        stopMysql: "Stopping MySQL DB System",
        stopVMDb: "Stopping Base database",
        updateAdbsDbName: "Updating database name for Autonomous database",
        updateAdbsLicense: "Updating license for Autonomous database",
        updateAdbsNetworking: "Updating network access for Autonomous database",
        updateAdbsNetworkMutualAuth:
          "Updating mutual TLS (mTLS) authentication for Autonomous database",
        updateAdbsPassword: "Updating password for Autonomous database",
        updateExaCdbBackup:
          "Updating backup configuration for Exadata database",
        updateExadataVmClusterLicense:
          "Updating license for Exadata VM cluster",
        updateHeatWaveNodeCount: "Updating node count",
        updateMysqlBackup: "Updating backup setting for MySQL HeatWave",
        updateMysqlBackupDescription: "Updating description for MySQL backup",
        updateMysqlBackupRetentionDays:
          "Updating retention days for MySQL backup",
        updateMysqlCrashRecovery:
          "Updating crash recovery setting for MySQL HeatWave",
        updateMysqlDataStorageSize:
          "Updating data storage size for MySQL database system",
        updateMysqlDeletionPlan:
          "Updating deletion plan setting for MySQL HeatWave",
        updateMysqlDescription:
          "Updating description for MySQL database system",
        updateMysqlPassword: "Updating password for MySQL database",
        updateMysqlShape: "Updating shape for MySQL database system",
        updateMysqlVersion: "Updating version for MySQL database system",
        updateSupportRequest: "Updating support request",
        updateVmCdbBackup: "Updating backup configuration for Base database",
        updateVmCdbPassword: "Updating password for Base database",
        updateVmDbSystemLicense: "Updating license for Base database system",
        updateVmDbSystemStorage: "Updating storage for Base database system",
        updateVmTdePassword: "Updating TDE wallet password for Base database",
        uploadSupportRequestFile: "Uploading file to support request",
        upgradeToATP: "Upgrading to ATP"
      }
    },
    success: {
      messages: {
        addCapacityVMCluster:
          "Successfully added capacity to VM cluster '{name}'",
        addRegion: "Successfully added location '{name}'.",
        addSshKey: "Successfully added SSH key '{name}'.",
        applyTags: "Successfully Applied tags on '{name}'.",
        attachVirtualNetwork: "Successfully attached virtual network '{name}'",
        clonePluggableDb: "Successfully cloned pluggable database '{name}'.",
        closeSupportRequest: "Successfully closed support request '{name}'.",
        createBackup: "Successfully created backup '{name}'.",
        createHeatWave:
          "Successfully created HeatWave cluster for MySQL DB system '{name}'.",
        createMcvcn: "Successfully created virtual cloud network '{name}'.",
        createPluggableDb: "Successfully created pluggable database '{name}'.",
        createSupportRequest: "Successfully created support request.",
        deleteAdbs: "Successfully deleted Autonomous database '{name}'.",
        deleteBackup: "Successfully deleted backup '{name}'.",
        deleteExaDb: "Successfully deleted Exadata database '{name}'.",
        deleteExaInfra: "Successfully deleted Exadata infrastructure '{name}'.",
        deleteHeatWave:
          "Succesfully deleted HeatWave cluster for MySQL DB system '{name}'",
        deleteMcVcn: "Successfully deleted virtual cloud network '{name}'.",
        deleteMysql: "Successfully deleted MySQL HeatWave '{name}'.",
        deletePluggableDb: "Successfully deleted pluggable database '{name}'.",
        deleteVMCluster: "Successfully deleted VM cluster '{name}'",
        deleteVMDb: "Successfully deleted Base database '{name}'.",
        deleteVMDbSystem: "Successfully deleted Base database system '{name}'.",
        detachVirtualNetwork: "Successfully detached virtual network '{name}'.",
        deployResource: "Successfully deployed {resourceType} '{name}'",
        downloadWallet:
          "Successfully downloaded Autonomous database '{name}' wallet.",
        linkSubscription: "Successfully linked subscription '{name}'.",
        reopenSupportRequest: "Successfully reopened support request '{name}'.",
        restartAdbs: "Successfully restarted Autonomous database '{name}'.",
        restartHeatWaveCluster:
          "Successfully restarted HeatWave cluster for MySQL DB system '{name}'.",
        restartMysql: "Successfully restarted MySQL DB System '{name}'.",
        restore: "Successfully restored database '{name}'.",
        restoreAdbs: "Successfully restored Autonomous database '{name}'.",
        restoreFromBackup:
          "Successfully restored database '{name}' from backup '{backupName}'.",
        rotateWallet:
          "Successfully rotated Autonomous database '{name}' wallet.",
        scaleAdbs: "Successfully scaled Autonomous database '{name}'.",
        scaleExaInfra: "Successfully scaled Exadata infrastructure '{name}'.",
        scaleVmCluster: "Successfully scaled VM cluster '{name}'.",
        scaleVmDbSystem: "Successfully scaled Base database system '{name}'",
        startAdbs: "Successfully started Autonomous database '{name}'.",
        startExaDb: "Successfully started Exadata database '{name}'.",
        startHeatWaveCluster:
          "Successfully started HeatWave cluster for MySQL DB system '{name}'.",
        startMysql: "Successfully started MySQL DB System '{name}'.",
        startVMDb: "Successfully started Base database '{name}'.",
        stopAdbs: "Successfully stopped Autonomous database '{name}'.",
        stopExaDb: "Successfully stopped Exadata database '{name}'.",
        stopHeatWaveCluster:
          "Successfully stopped HeatWave cluster for MySQL DB system '{name}'.",
        stopMysql: "Successfully stopped MySQL DB System '{name}'.",
        stopVMDb: "Successfully stopped Base database '{name}'.",
        submitFeedback: "Succesfully submitted your feedback.",
        updateAdbsDbName:
          "Successfully updated database name for Autonomous database '{name}'.",
        updateAdbsLicense:
          "Successfully updated license for Autonomous database '{name}'",
        updateAdbsNetworking:
          "Successfully updated network access for Autonomous database '{name}'.",
        updateAdbsNetworkMutualAuth:
          "Successfully updated mutual TLS (mTLS) authentication for Autonomous database '{name}'.",
        updateAdbsPassword:
          "Successfully updated password for Autonomous database '{name}'.",
        updateExaCdbBackup:
          "Successfully updated backup configuration for Exadata database '{name}'.",
        updateExadataVmClusterLicense:
          "Successfully updated license for Exadata VM cluster '{name}'",
        updateHeatWaveNodeCount:
          "Successfully updated node count for HeatWave cluster for MySQL DB system '{name}'",
        updateMysqlBackup:
          "Successfully updated backup setting for MySQL HeatWave '{name}'.",
        updateMysqlBackupDescription:
          "Successfully updated description for MySQL backup '{name}'.",
        updateMysqlBackupRetentionDays:
          "Successfully updated retention days for MySQL backup '{name}'.",
        updateMysqlCrashRecovery:
          "Successfully updated crash recovery setting for MySQL HeatWave '{name}'.",
        updateMysqlDataStorageSize:
          "Successfully updated data storage size for MySQL database system '{name}'.",
        updateMysqlDeletionPlan:
          "Successfully updated deletion plan setting for MySQL HeatWave '{name}'.",
        updateMysqlDescription:
          "Successfully updated description for MySQL database system '{name}'.",
        updateMysqlPassword:
          "Successfully updated password for MySQL database '{name}'.",
        updateMysqlShape:
          "Successfully updated shape for MySQL database system '{name}'.",
        updateMysqlVersion:
          "Successfully updated version for MySQL database system '{name}'.",
        updateSupportRequest: "Successfully updated support request '{name}'.",
        updateVmCdbBackup:
          "Successfully updated backup configuration for Base database '{name}'.",
        updateVmCdbPassword:
          "Successfully updated password for Base database '{name}'.",
        updateVmDbSystemLicense:
          "Successfully updated license for Base database system '{name}'",
        updateVmDbSystemStorage:
          "Successfully updated storage for Base database system '{name}'",
        updateVmTdePassword:
          "Successfully updated TDE wallet password for Base database '{name}'.",
        upgradeToATP: "Successfully upgraded '{name}' to ATP.",
        uploadSupportRequestFile:
          "Successfully uploaded file to support request '{name}'."
      },
      titles: {
        addCapacityVMCluster: "Added capacity to VM cluster",
        addRegion: "Added location",
        addSshKey: "Added SSH key",
        applyTags: "Applied tags",
        attachVirtualNetwork: "Attached virtual network",
        clonePluggableDb: "Cloned pluggable database",
        closeSupportRequest: "Closed support request",
        createBackup: "Created backup",
        createHeatWave: "Created HeatWave cluster",
        createMcvcn: "Created virtual cloud network",
        createPluggableDb: "Created pluggable database",
        createSupportRequest: "Created support request",
        deleteAdbs: "Deleted Autonomous database",
        deleteBackup: "Deleted backup",
        deleteExaDb: "Deleted Exadata database",
        deleteExaInfra: "Deleted Exadata infrastructure",
        deleteHeatWave: "Deleted HeatWave cluster",
        deleteMcVcn: "Deleted virtual cloud network",
        deleteMysql: "Deleted MySQL HeatWave",
        deletePluggableDb: "Deleted pluggable database",
        deleteVMCluster: "Deleted VM cluster",
        deleteVMDb: "Deleted Base database",
        deleteVMDbSystem: "Deleted Base database system",
        detachVirtualNetwork: "Detach virtual network",
        deployResource: "{resourceType} deployed successfully",
        downloadWallet: "Downloaded Autonomous database wallet",
        linkSubscription: "Linked subscription",
        reopenSupportRequest: "Reopened support request",
        restartAdbs: "Restarted Autonomous database",
        restartHeatWaveCluster: "Restarted HeatWave cluster",
        restartMysql: "Restarted MySQL DB System",
        restore: "Restored database",
        restoreAdbs: "Restored Autonomous database",
        rotateWallet: "Rotated Autonomous database wallet",
        scaleAdbs: "Scaled Autonomous database",
        scaleExaInfra: "Scaled Exadata infrastructure",
        scaleVmCluster: "Scaled VM cluster",
        scaleVmDbSystem: "Scaled Base database system",
        startAdbs: "Started Autonomous database",
        startExaDb: "Started Exadata database",
        startHeatWaveCluster: "Started HeatWave cluster",
        startMysql: "Started MySQL DB System",
        startVMDb: "Started Base database",
        stopAdbs: "Stopped Autonomous database",
        stopExaDb: "Stopped Exadata database",
        stopHeatWaveCluster: "Stopped HeatWave cluster",
        stopMysql: "Stopped MySQL DB System",
        stopVMDb: "Stopped Base database",
        submitFeedback: "Submitted feedback",
        updateAdbsDbName: "Updated database name for Autonomous database",
        updateAdbsLicense: "Updated license for Autonomous database",
        updateAdbsNetworking:
          "Updated network information for Autonomous database",
        updateAdbsNetworkMutualAuth:
          "Updated mutual TLS (mTLS) authentication for Autonomous database",
        updateAdbsPassword: "Updated password for Autonomous database",
        updateExaCdbBackup:
          "Updated backup configuration for Exadata database ",
        updateExadataVmClusterLicense: "Updated license for Exadata VM cluster",
        updateHeatWaveNodeCount: "Updated node count for HeatWave cluster",
        updateMysqlBackup: "Updated backup setting for MySQL HeatWave",
        updateMysqlBackupDescription: "Updated description for MySQL backup",
        updateMysqlBackupRetentionDays:
          "Updated retention days for MySQL backup",
        updateMysqlCrashRecovery:
          "Updated crash recovery setting for MySQL HeatWave",
        updateMysqlDataStorageSize:
          "Updated data storage size for MySQL database system",
        updateMysqlDeletionPlan:
          "Updated deletion plan setting for MySQL HeatWave",
        updateMysqlDescription: "Updated description for MySQL database system",
        updateMysqlPassword: "Updated password for MySQL database",
        updateMysqlShape: "Updated MySQL database system shape",
        updateMysqlVersion: "Updated MySQL database system version",
        updateSupportRequest: "Updated support request",
        updateVmCdbBackup: "Updated backup configuration for Base database",
        updateVmCdbPassword: "Updated password for Base database",
        updateVmDbSystemLicense: "Updated license for Base database system",
        updateVmDbSystemStorage: "Updated storage for Base database system",
        updateVmTdePassword: "Updated TDE wallet password for Base database",
        upgradeToATP: "Upgrade to ATP",
        uploadSupportRequestFile: "Uploaded file"
      }
    },
    watermarkMessage: "No new notifications from this session",
    when: {
      dayAgo: "a day ago",
      daysAgo: "{day} days ago",
      hourAgo: "an hour ago",
      hoursAgo: "{hour} hours ago",
      minuteAgo: "a minute ago",
      minutesAgo: "{minute} minutes ago",
      monthAgo: "a month ago",
      monthsAgo: "{month} months ago",
      secondsAgo: "a few seconds ago",
      weekAgo: "a week ago",
      weeksAgo: "{week} weeks ago",
      yearsAgo: "more than year ago"
    }
  },
  openModes: {
    migrate: "Migrate",
    mounted: "Mounted",
    readOnly: "Read only",
    readWrite: "Read/Write"
  },
  portalBrand: {
    long: "Oracle Database Service for Azure",
    short: "ODSA"
  },
  promoAlertModal: {
    exadataDatabaseNotAvailable: "Exadata Database is not available",
    exadataDatabaseCannotCreated:
      "You currently have Oracle Cloud Free Tier. Upgrade your account to access Exadata Database."
  },
  resourceErrors: {
    invalidProvider: "The resource namespace '{provider}' is invalid.",
    invalidResourceType:
      "The resource type '{resourceType}' could not be found in the namespace '{provider}'.",
    resourceNotFound: "Resource not found"
  },
  resourceTypes: {
    adbs: "Autonomous database",
    deployment: "Deployment",
    exaDb: "Exadata database",
    exaInfra: "Exadata infrastructure",
    exaVmCluster: "Exadata VM cluster",
    mcvcn: "Virtual cloud network",
    mysql: "MySQL Heatwave",
    mysqlBackup: "MySQL Heatwave backup",
    vmDb: "Base database",
    vmDbSystem: "Base database system"
  },
  satisfaction: {
    extremelyNotSatisfied: "Extremely not satisfied",
    extremelySatisfied: "Extremely satisfied",
    neutral: "Neutral",
    notSatisfied: "Not satisfied",
    satisfied: "Satisfied"
  },
  serviceProviders: {
    adbShared: "Autonomous Database",
    cloudlink: "Subscription management",
    exadataDedicated: "Exadata Database Dedicated",
    mySqlHeatwave: "MySQL HeatWave",
    network: "Network",
    vmDatabase: "Base Database"
  },
  softwareEditionOptions: {
    enterpriseEdition: "Enterprise Edition",
    enterpriseEditionExtremePerformance:
      "Enterprise edition extreme performance",
    enterpriseEditionHighPerformance: "Enterprise edition high performance",
    standardEdition: "Standard Edition"
  },
  sshKeySources: {
    generateNewKeyPair: "Generate new key pair",
    uploadExistingPublicKey: "Upload existing public key"
  },
  storageManagements: {
    asm: "Oracle Grid Infrastructure",
    lvm: "Logical Volume Manager"
  },
  storagePerformanceModeOptions: {
    balanced: "Balanced",
    higherPerformance: "Higher performance"
  },
  subscriptionStatus: {
    failed: "Failed",
    inProgress: "In progress",
    linked: "Linked",
    linking: "Linking...",
    succeeded: "Succeeded",
    unlinked: "Unlinked",
    updating: "Updating..."
  },
  supportIssueTypes: {
    billing: "Billing",
    quotas: "Service and subscription limits (quotas)",
    quotasShort: "Quotas",
    technical: "Technical"
  },
  supportIssueStateTypes: {
    closed: "Closed",
    closeRequested: "Close requested",
    pendingCustomer: "Pending customer",
    pendingOracle: "Pending Oracle"
  },
  supportItemTypes: {
    activityAttachmentTitle: "Attachment added",
    activityCloseTitle: "Support request closed",
    activityNotesTitle: "Comments added",
    activityProblemDescriptionTitle: "Problem description",
    activityReopenTitle: "Support request reopened",
    activityTitle: "Activity ({status})",
    activityUpdateTitle: "Support request update",
    limitApprovedTitle: "Limit increase approved",
    limitNotApprovedTitle: "Limit increase not approved",
    limitPatiallyApprovedTitle: "Limit increase partially approved",
    limitPendingTitle: "Limit increase pending",
    limitTitle: "Limit increase ({status})",
    technicalTitle: "Technical issue"
  },
  supportSeverity: {
    high: "High",
    highest: "Highest",
    low: "Low",
    medium: "Medium"
  },
  tags: {
    description:
      "Tags are name/value pairs that enable you to categorize resources and view consolidated billing by applying the same tag to multiple resources and resource groups. These tags are only useable from the OCI console or OCI APIs. These tags are not useable from within the Azure portal or Azure APIs. ",
    info: {
      learnMore: "Learn more about tags"
    },
    note:
      "Note that if you create tags and then change resource settings on other tabs, your tags will be automatically updated.",
    title: "Tags"
  },
  topHeader: {
    cloudShell: "Cloud Shell",
    directoriesSubscriptions: "Directories + subscriptions",
    feedback: "Feedback",
    help: "Help + support",
    hidePortalMenu: "Hide portal menu",
    notifications: "Notifications",
    settings: "Settings",
    showPortalMenu: "Show portal menu",
    signOut: "Sign out"
  },
  topServices: {
    apex: "APEX Instances",
    autonomousDatabases: "Autonomous Database",
    autonomousSharedDatabase: "Autonomous Databases",
    billing: "Billing",
    deployments: "Deployments",
    exadata: "Exadata Database",
    exadataDatabases: "Exadata Databases",
    exadataInfras: "Exadata Infrastructures",
    exadataSystems: "Exadata Systems",
    exadataVmClusters: "Exadata VM Clusters",
    mysqlDatabase: "MySQL HeatWave",
    mcvcn: "Virtual Cloud Network",
    subscriptionManagement: "Subscription Management",
    support: "Support",
    vmDatabase: "Base Database",
    vmDatabases: "Base Databases"
  },
  trialAccountInfo: {
    promo:
      "You currently have Oracle Cloud Free Tier. Upgrade your account to access more services.",
    promoConsumed:
      "You've used all your trial credits. Upgrade to access all of Oracle Cloud Infrastructure services.",
    promoExpired:
      "Your trial credits have expired. Upgrade to access all of Oracle Cloud Infrastructure services."
  },
  updateSupportRequest: {
    title: "Add comment to ticket"
  },
  uploadSupportRequestFile: {
    title: "Upload file to support request"
  },
  validation: {
    addressOverlap: "{address1} overlaps {address2}",
    addressWithinRange: "The address must be within the range {cidr}",
    addressWithinRanges:
      "The address must be within one of the following ranges {cidrs}",
    dbNameValidation:
      "The name must contain only letters and numbers, starting with a letter. Spaces are not allowed",
    dbPasswordValidation:
      'Password must contain at least one uppercase letter, one lowercase letter, and one number. The password cannot contain the double quote (") character.',
    deleteExaInfraCb: "You must check the box to confirm the deletion.",
    inputRange:
      "The value must be between {minValue} and {maxValue} characters long.",
    integerMax: "The value must be at most {maxValue}.",
    integerStepMin:
      "The value must be at least {minValue} or greater with an increment of {stepValue}.",
    integerZeroOrMinRange: "The value must be 0 or at least {minValue}.",
    integerZeroOrRange:
      "The value must be 0 or between {minValue} and {maxValue}.",
    invalidCidrPrefixRange: "Invalid CIDR prefix.",
    invalidCustomDuration: "Invalid custom duration.",
    mdsAdminUserNameCharactersNotAllowedValidation:
      "The following characters are not permitted: {characters}",
    mdsAdminUserNameLengthValidation:
      "The username must be between {minValue} and {maxValue} characters.",
    mdsAdminUserNameReservedNameValidation:
      "The usernames {name} are reserved and cannot be used.",
    mdsDescriptionValidation:
      "Description must be between 1 and 400 characters, inclusive.",
    mdsHostnameValidation:
      "The hostname must be alphanumeric, may not contain spaces, special characters, except hyphens (-), and start or end with a hyphen.",
    nameChars: "The name must be between {minValue} and {maxValue} characters.",
    nameStartChar: "The value must begin with an alphabetic character.",
    nameStartAplhaNumeric:
      "The value must begin with an alphanumeric character.",
    nameValidation:
      "The name must begin with a letter or number, end with a letter, number or underscore, and may contain only letters, numbers, underscores, periods, or hyphens.",
    networkLinkNameValidation:
      "The name must begin with a letter or number, and may contain only letters, numbers, underscores.",
    passwordConfirmation: "Password and confirm password must match.",
    passwordValidation:
      "Password must contain at least 2 uppercase, 2 lowercase, 2 special, and 2 numeric characters. The special characters must be _, #, or -.",
    passwordValidationMysql:
      "Password must contain at least 1 numeric, 1 lowercase, 1 uppercase, and 1 special (non-alphanumeric) character.",
    quotaLimitUnavailable:
      "There was an issue retrieving the current limit. Please contact Support.",
    required: "The value must not be empty.",
    roleMissingForCreate:
      'You need "{role}" role to be able to create this resource. Ask your Azure admin or the person who signed up to ODSA to assign this role to you.',
    roleMissingForList:
      'You need "{role}" role to be able to list the resources. Ask your Azure admin or the person who signed up to ODSA to assign this role to you.',
    sameDbNames:
      "The database and pluggable database names should be different.",
    subStringDbName: "Database name cannot be a substring of hostname",
    tagKeyInvalidChars:
      "The following characters are not supported: '.' and space. Tag name must contain only printable ASCII characters.",
    tagKeyRequiredWithValue:
      "Tag name is required because the tag value is provided.",
    tagKeyRequiredNoValue: "Tag name is required.",
    tagKeyUnique:
      "Invalid tag name. The tag name '{tagName}' is used more than once. Tag names are case-insensitive.",
    tagResourceTypeRequired: "Please choose a resource type.",
    unableToValidate: "Failed to validate input due to unexpected error.",
    unique: "The value is not unique.",
    valueAlphaNumeric: "The value can contain only alphanumeric characters.",
    valueAlphaNumericHyphen:
      "The value must contain only alphanumeric characters and hyphens (-).",
    valueAlphaNumericUnderscore:
      "The value must contain only alphanumeric characters and underscores (_).",
    valueMaxLen: "The value must be at most {length} characters in length.",
    versionValidationMysql:
      "Enabling PitrPolicy requires version {value} or a more recent version.",
    walletPasswordValidation:
      "Password must be 8 to 60 characters and contain at least 1 alphabetic and 1 numeric character."
  },
  workloadTypes: {
    apex: "APEX",
    dataWarehouse: "Data Warehouse",
    json: "JSON Database",
    transactionProcessing: "Transaction Processing"
  }
};

export const i18n = new LoomI18n();

/**
 * Initialize the instance. If no {@code defaultLanguage} are provided, tries to automatically detect the language
 * @param defaultLanguage The language to use initially
 */
export function init(
  defaultLanguage?: string,
  localeOptions?: {
    defaultLocale?: string;
    defaultCurrency?: Currency.Code;
    defaultTimezone?: string;
  }
): Promise<void> {
  return i18n.init({
    remoteResourcesPatterns: [
      "https://locales.plugins.oci.oraclecloud.com/ociaz-console/1.15.0/{{lng}}.json",
      "https://locales.plugins.oci.oraclecloud.com/ociaz-console/{{lng}}.json"
    ],
    defaultLanguage: defaultLanguage,
    resources: bundled,
    localesData: {
      en: enLocaleData
    },
    defaultCurrency: localeOptions && localeOptions.defaultCurrency,
    defaultTimezone: localeOptions && localeOptions.defaultTimezone
  });
}

/**
 * Add a notification callback on initialization time. Please set this before you call @method init
 * @param callback
 */
export function onInit(
  callback: (initLang: string, error?: any) => void
): void {
  i18n.onInit(callback);
}

/**
 * Helper functions for i18n.
 */
export const i18nUtils = {
  /**
   * Function to format currency.
   * @param value
   * @deprecated We will be removing "Code" and only have optional "Options" object where you can specify the currency
   * @param codeOrOptions Currency code to use, defaults to USD. Use getCurrencyCode to map string to Currency.Code enum
   * or Options object to set currencyCode(defaults to USD), displayFormat(Defaults to Symbol) and precision
   * @deprecated We will be removing "displayFormat" in the future. You can set this in the Options object
   * @param displayFormat Display as symbol or name. Eg. $5.00 or 5 US Dollars, Defaults to Symbol.
   * This is ignored if "Options" object is provided
   */
  formatCurrency: function formatCurrency(
    value: number,
    codeOrOptions?: Currency.Code | Currency.Options,
    displayFormat?: Currency.DisplayFormat
  ): string {
    return i18n.formatCurrency(value, codeOrOptions, displayFormat);
  },

  /**
   * Get Currency.Code enum from the specified string. If not found, returns the string value cast as enum.
   * @param code Currency code string to look up.
   */
  getCurrencyCode: function getCurrencyCode(code: string): Currency.Code {
    return (<any>Currency.Code).code || code;
  }
};

/**
 * Add a notification callback on missing translation key. Useful for applications that may need to do
 * work once the translation key is missing in translation files.
 * @param callback
 */
export function onMissingKey(callback: (key: string) => void): void {
  i18n.onMissingKey(callback);
}

/**
 * Function to check if the instance is initialized.
 */
export function isInit(): boolean {
  return i18n.isInitialized;
}

/**
 * Change the language of the current instance.
 * @param language New language to use. Can be either language code (e.g. "en"), or locale code (e.g. "en-US"))
 */
export function changeLanguage(language: string): Promise<void> {
  return i18n.changeLanguage(language);
}

/**
 * Add a notification callback on change of language. Useful for applications that may need
 * to do work once the language is changed (e.g. forceUpdate of the root component in react)
 * @param callback
 */
export function onLanguageChanged(
  callback: (language: string, error?: any) => void
): void {
  i18n.onLanguageChanged(callback);
}

function shouldReturnKey(
  key: string,
  returnKey: boolean | undefined,
  props: { [key: string]: any }
) {
  if (returnKey) {
    return key;
  } else {
    return i18n.t(key, props);
  }
}

export const actions = {
  addCapacity: function actions_addCapacity(returnKey?: boolean): string {
    return shouldReturnKey("actions.addCapacity", returnKey, {});
  },
  addComment: function actions_addComment(returnKey?: boolean): string {
    return shouldReturnKey("actions.addComment", returnKey, {});
  },
  addRegion: function actions_addRegion(returnKey?: boolean): string {
    return shouldReturnKey("actions.addRegion", returnKey, {});
  },
  addResources: function actions_addResources(returnKey?: boolean): string {
    return shouldReturnKey("actions.addResources", returnKey, {});
  },
  addSshKey: function actions_addSshKey(returnKey?: boolean): string {
    return shouldReturnKey("actions.addSshKey", returnKey, {});
  },
  addSubscriptionHelp: function actions_addSubscriptionHelp(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.addSubscriptionHelp", returnKey, {});
  },
  addToFavorites: function actions_addToFavorites(returnKey?: boolean): string {
    return shouldReturnKey("actions.addToFavorites", returnKey, {});
  },
  apexLaunch: function actions_apexLaunch(returnKey?: boolean): string {
    return shouldReturnKey("actions.apexLaunch", returnKey, {});
  },
  apply: function actions_apply(returnKey?: boolean): string {
    return shouldReturnKey("actions.apply", returnKey, {});
  },
  attach: function actions_attach(returnKey?: boolean): string {
    return shouldReturnKey("actions.attach", returnKey, {});
  },
  changePassword: function actions_changePassword(returnKey?: boolean): string {
    return shouldReturnKey("actions.changePassword", returnKey, {});
  },
  changeTdePassword: function actions_changeTdePassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.changeTdePassword", returnKey, {});
  },
  clear: function actions_clear(returnKey?: boolean): string {
    return shouldReturnKey("actions.clear", returnKey, {});
  },
  clone: function actions_clone(returnKey?: boolean): string {
    return shouldReturnKey("actions.clone", returnKey, {});
  },
  close: function actions_close(returnKey?: boolean): string {
    return shouldReturnKey("actions.close", returnKey, {});
  },
  configureVMCluster: function actions_configureVMCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.configureVMCluster", returnKey, {});
  },
  configureDbSystemShape: function actions_configureDbSystemShape(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.configureDbSystemShape", returnKey, {});
  },
  connectionStrings: function actions_connectionStrings(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.connectionStrings", returnKey, {});
  },
  convert: function actions_convert(returnKey?: boolean): string {
    return shouldReturnKey("actions.convert", returnKey, {});
  },
  copyConnectionString: function actions_copyConnectionString(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.copyConnectionString", returnKey, {});
  },
  copySshPublicKey: function actions_copySshPublicKey(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.copySshPublicKey", returnKey, {});
  },
  create: function actions_create(returnKey?: boolean): string {
    return shouldReturnKey("actions.create", returnKey, {});
  },
  createAdbs: function actions_createAdbs(returnKey?: boolean): string {
    return shouldReturnKey("actions.createAdbs", returnKey, {});
  },
  createApex: function actions_createApex(returnKey?: boolean): string {
    return shouldReturnKey("actions.createApex", returnKey, {});
  },
  createAResource: function actions_createAResource(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.createAResource", returnKey, {});
  },
  createASupportRequest: function actions_createASupportRequest(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.createASupportRequest", returnKey, {});
  },
  createExaDb: function actions_createExaDb(returnKey?: boolean): string {
    return shouldReturnKey("actions.createExaDb", returnKey, {});
  },
  createExaInfra: function actions_createExaInfra(returnKey?: boolean): string {
    return shouldReturnKey("actions.createExaInfra", returnKey, {});
  },
  createExaSystem: function actions_createExaSystem(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.createExaSystem", returnKey, {});
  },
  createHeatWave: function actions_createHeatWave(returnKey?: boolean): string {
    return shouldReturnKey("actions.createHeatWave", returnKey, {});
  },
  createMysql: function actions_createMysql(returnKey?: boolean): string {
    return shouldReturnKey("actions.createMysql", returnKey, {});
  },
  createNew: function actions_createNew(returnKey?: boolean): string {
    return shouldReturnKey("actions.createNew", returnKey, {});
  },
  createVMCluster: function actions_createVMCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.createVMCluster", returnKey, {});
  },
  createVMDb: function actions_createVMDb(returnKey?: boolean): string {
    return shouldReturnKey("actions.createVMDb", returnKey, {});
  },
  dashboard: function actions_dashboard(returnKey?: boolean): string {
    return shouldReturnKey("actions.dashboard", returnKey, {});
  },
  databaseActions: function actions_databaseActions(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.databaseActions", returnKey, {});
  },
  delete: function actions_delete(returnKey?: boolean): string {
    return shouldReturnKey("actions.delete", returnKey, {});
  },
  deleteAll: function actions_deleteAll(returnKey?: boolean): string {
    return shouldReturnKey("actions.deleteAll", returnKey, {});
  },
  detach: function actions_detach(returnKey?: boolean): string {
    return shouldReturnKey("actions.detach", returnKey, {});
  },
  disable: function actions_disable(returnKey?: boolean): string {
    return shouldReturnKey("actions.disable", returnKey, {});
  },
  disableCrashRecovery: function actions_disableCrashRecovery(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.disableCrashRecovery", returnKey, {});
  },
  discardChanges: function actions_discardChanges(returnKey?: boolean): string {
    return shouldReturnKey("actions.discardChanges", returnKey, {});
  },
  dismissAll: function actions_dismissAll(returnKey?: boolean): string {
    return shouldReturnKey("actions.dismissAll", returnKey, {});
  },
  dismissInfo: function actions_dismissInfo(returnKey?: boolean): string {
    return shouldReturnKey("actions.dismissInfo", returnKey, {});
  },
  dismissSuccess: function actions_dismissSuccess(returnKey?: boolean): string {
    return shouldReturnKey("actions.dismissSuccess", returnKey, {});
  },
  downloadKeyCreateResource: function actions_downloadKeyCreateResource(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.downloadKeyCreateResource", returnKey, {});
  },
  downloadKeyUpdateResource: function actions_downloadKeyUpdateResource(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.downloadKeyUpdateResource", returnKey, {});
  },
  downloadWallet: function actions_downloadWallet(returnKey?: boolean): string {
    return shouldReturnKey("actions.downloadWallet", returnKey, {});
  },
  edit: function actions_edit(returnKey?: boolean): string {
    return shouldReturnKey("actions.edit", returnKey, {});
  },
  enable: function actions_enable(returnKey?: boolean): string {
    return shouldReturnKey("actions.enable", returnKey, {});
  },
  enableCrashRecovery: function actions_enableCrashRecovery(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.enableCrashRecovery", returnKey, {});
  },
  enterDetails: function actions_enterDetails(returnKey?: boolean): string {
    return shouldReturnKey("actions.enterDetails", returnKey, {});
  },
  getStarted: function actions_getStarted(returnKey?: boolean): string {
    return shouldReturnKey("actions.getStarted", returnKey, {});
  },
  goToResource: function actions_goToResource(returnKey?: boolean): string {
    return shouldReturnKey("actions.goToResource", returnKey, {});
  },
  linkSubscription: function actions_linkSubscription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.linkSubscription", returnKey, {});
  },
  metrics: function actions_metrics(returnKey?: boolean): string {
    return shouldReturnKey("actions.metrics", returnKey, {});
  },
  portalMenu: function actions_portalMenu(returnKey?: boolean): string {
    return shouldReturnKey("actions.portalMenu", returnKey, {});
  },
  quickSearch: function actions_quickSearch(returnKey?: boolean): string {
    return shouldReturnKey("actions.quickSearch", returnKey, {});
  },
  removeFromFavorites: function actions_removeFromFavorites(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.removeFromFavorites", returnKey, {});
  },
  restore: function actions_restore(returnKey?: boolean): string {
    return shouldReturnKey("actions.restore", returnKey, {});
  },
  restoreToNewDbSystem: function actions_restoreToNewDbSystem(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.restoreToNewDbSystem", returnKey, {});
  },
  refresh: function actions_refresh(returnKey?: boolean): string {
    return shouldReturnKey("actions.refresh", returnKey, {});
  },
  reopen: function actions_reopen(returnKey?: boolean): string {
    return shouldReturnKey("actions.reopen", returnKey, {});
  },
  restart: function actions_restart(returnKey?: boolean): string {
    return shouldReturnKey("actions.restart", returnKey, {});
  },
  retry: function actions_retry(returnKey?: boolean): string {
    return shouldReturnKey("actions.retry", returnKey, {});
  },
  returnToAddSshKey: function actions_returnToAddSshKey(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.returnToAddSshKey", returnKey, {});
  },
  returnToCreateResource: function actions_returnToCreateResource(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("actions.returnToCreateResource", returnKey, {});
  },
  rotateWallet: function actions_rotateWallet(returnKey?: boolean): string {
    return shouldReturnKey("actions.rotateWallet", returnKey, {});
  },
  save: function actions_save(returnKey?: boolean): string {
    return shouldReturnKey("actions.save", returnKey, {});
  },
  scale: function actions_scale(returnKey?: boolean): string {
    return shouldReturnKey("actions.scale", returnKey, {});
  },
  search: function actions_search(returnKey?: boolean): string {
    return shouldReturnKey("actions.search", returnKey, {});
  },
  seeAll: function actions_seeAll(returnKey?: boolean): string {
    return shouldReturnKey("actions.seeAll", returnKey, {});
  },
  select: function actions_select(returnKey?: boolean): string {
    return shouldReturnKey("actions.select", returnKey, {});
  },
  serviceMenu: function actions_serviceMenu(returnKey?: boolean): string {
    return shouldReturnKey("actions.serviceMenu", returnKey, {});
  },
  start: function actions_start(returnKey?: boolean): string {
    return shouldReturnKey("actions.start", returnKey, {});
  },
  stop: function actions_stop(returnKey?: boolean): string {
    return shouldReturnKey("actions.stop", returnKey, {});
  },
  submit: function actions_submit(returnKey?: boolean): string {
    return shouldReturnKey("actions.submit", returnKey, {});
  },
  submitFeedback: function actions_submitFeedback(returnKey?: boolean): string {
    return shouldReturnKey("actions.submitFeedback", returnKey, {});
  },
  updateDetails: function actions_updateDetails(returnKey?: boolean): string {
    return shouldReturnKey("actions.updateDetails", returnKey, {});
  },
  upgradeAccount: function actions_upgradeAccount(returnKey?: boolean): string {
    return shouldReturnKey("actions.upgradeAccount", returnKey, {});
  },
  upload: function actions_upload(returnKey?: boolean): string {
    return shouldReturnKey("actions.upload", returnKey, {});
  },
  uploadFile: function actions_uploadFile(returnKey?: boolean): string {
    return shouldReturnKey("actions.uploadFile", returnKey, {});
  },
  viewAccount: function actions_viewAccount(returnKey?: boolean): string {
    return shouldReturnKey("actions.viewAccount", returnKey, {});
  }
};

export const activityTypes = {
  close: function activityTypes_close(returnKey?: boolean): string {
    return shouldReturnKey("activityTypes.close", returnKey, {});
  },
  notes: function activityTypes_notes(returnKey?: boolean): string {
    return shouldReturnKey("activityTypes.notes", returnKey, {});
  },
  problemDescription: function activityTypes_problemDescription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("activityTypes.problemDescription", returnKey, {});
  },
  reopen: function activityTypes_reopen(returnKey?: boolean): string {
    return shouldReturnKey("activityTypes.reopen", returnKey, {});
  },
  update: function activityTypes_update(returnKey?: boolean): string {
    return shouldReturnKey("activityTypes.update", returnKey, {});
  }
};

export const addSshKey = {
  title: function addSshKey_title(returnKey?: boolean): string {
    return shouldReturnKey("addSshKey.title", returnKey, {});
  }
};

export const ariaLabel = {
  dbNodesList: function ariaLabel_dbNodesList(returnKey?: boolean): string {
    return shouldReturnKey("ariaLabel.dbNodesList", returnKey, {});
  },
  dismissOptions: function ariaLabel_dismissOptions(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ariaLabel.dismissOptions", returnKey, {});
  },
  more: function ariaLabel_more(returnKey?: boolean): string {
    return shouldReturnKey("ariaLabel.more", returnKey, {});
  },
  morePortalTools: function ariaLabel_morePortalTools(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ariaLabel.morePortalTools", returnKey, {});
  },
  mySqlCommand: function ariaLabel_mySqlCommand(returnKey?: boolean): string {
    return shouldReturnKey("ariaLabel.mySqlCommand", returnKey, {});
  },
  resourceList: function ariaLabel_resourceList(returnKey?: boolean): string {
    return shouldReturnKey("ariaLabel.resourceList", returnKey, {});
  },
  scn: function ariaLabel_scn(returnKey?: boolean): string {
    return shouldReturnKey("ariaLabel.scn", returnKey, {});
  }
};

export const backupTypes = {
  full: function backupTypes_full(returnKey?: boolean): string {
    return shouldReturnKey("backupTypes.full", returnKey, {});
  },
  incremental: function backupTypes_incremental(returnKey?: boolean): string {
    return shouldReturnKey("backupTypes.incremental", returnKey, {});
  },
  virtualFull: function backupTypes_virtualFull(returnKey?: boolean): string {
    return shouldReturnKey("backupTypes.virtualFull", returnKey, {});
  }
};

export const creationTypes = {
  automatic: function creationTypes_automatic(returnKey?: boolean): string {
    return shouldReturnKey("creationTypes.automatic", returnKey, {});
  },
  manual: function creationTypes_manual(returnKey?: boolean): string {
    return shouldReturnKey("creationTypes.manual", returnKey, {});
  }
};

export const clonePluggableDatabase = {
  title: function clonePluggableDatabase_title(returnKey?: boolean): string {
    return shouldReturnKey("clonePluggableDatabase.title", returnKey, {});
  }
};

export const common = {
  addessesCount: function common_addessesCount(
    count: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("common.addessesCount", returnKey, { count });
  },
  cancel: function common_cancel(returnKey?: boolean): string {
    return shouldReturnKey("common.cancel", returnKey, {});
  },
  days: function common_days(returnKey?: boolean): string {
    return shouldReturnKey("common.days", returnKey, {});
  },
  disabled: function common_disabled(returnKey?: boolean): string {
    return shouldReturnKey("common.disabled", returnKey, {});
  },
  enabled: function common_enabled(returnKey?: boolean): string {
    return shouldReturnKey("common.enabled", returnKey, {});
  },
  generated: function common_generated(returnKey?: boolean): string {
    return shouldReturnKey("common.generated", returnKey, {});
  },
  home: function common_home(returnKey?: boolean): string {
    return shouldReturnKey("common.home", returnKey, {});
  },
  hours: function common_hours(returnKey?: boolean): string {
    return shouldReturnKey("common.hours", returnKey, {});
  },
  learnMore: function common_learnMore(returnKey?: boolean): string {
    return shouldReturnKey("common.learnMore", returnKey, {});
  },
  loading: function common_loading(returnKey?: boolean): string {
    return shouldReturnKey("common.loading", returnKey, {});
  },
  memoryGB: function common_memoryGB(
    number: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("common.memoryGB", returnKey, { number });
  },
  memoryMB: function common_memoryMB(
    number: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("common.memoryMB", returnKey, { number });
  },
  memoryTB: function common_memoryTB(
    number: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("common.memoryTB", returnKey, { number });
  },
  minutes: function common_minutes(returnKey?: boolean): string {
    return shouldReturnKey("common.minutes", returnKey, {});
  },
  name: function common_name(returnKey?: boolean): string {
    return shouldReturnKey("common.name", returnKey, {});
  },
  new: function common_new(returnKey?: boolean): string {
    return shouldReturnKey("common.new", returnKey, {});
  },
  no: function common_no(returnKey?: boolean): string {
    return shouldReturnKey("common.no", returnKey, {});
  },
  noResults: function common_noResults(returnKey?: boolean): string {
    return shouldReturnKey("common.noResults", returnKey, {});
  },
  noSubscriptions: function common_noSubscriptions(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("common.noSubscriptions", returnKey, {});
  },
  notRequired: function common_notRequired(returnKey?: boolean): string {
    return shouldReturnKey("common.notRequired", returnKey, {});
  },
  required: function common_required(returnKey?: boolean): string {
    return shouldReturnKey("common.required", returnKey, {});
  },
  type: function common_type(returnKey?: boolean): string {
    return shouldReturnKey("common.type", returnKey, {});
  },
  unknown: function common_unknown(returnKey?: boolean): string {
    return shouldReturnKey("common.unknown", returnKey, {});
  },
  unusable: function common_unusable(returnKey?: boolean): string {
    return shouldReturnKey("common.unusable", returnKey, {});
  },
  uploaded: function common_uploaded(returnKey?: boolean): string {
    return shouldReturnKey("common.uploaded", returnKey, {});
  },
  value: function common_value(returnKey?: boolean): string {
    return shouldReturnKey("common.value", returnKey, {});
  },
  version: function common_version(returnKey?: boolean): string {
    return shouldReturnKey("common.version", returnKey, {});
  },
  yes: function common_yes(returnKey?: boolean): string {
    return shouldReturnKey("common.yes", returnKey, {});
  }
};

export const configTitles = {
  default: function configTitles_default(returnKey?: boolean): string {
    return shouldReturnKey("configTitles.default", returnKey, {});
  },
  noStorageAllocatedForLocalAndSparseSummary: function configTitles_noStorageAllocatedForLocalAndSparseSummary(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "configTitles.noStorageAllocatedForLocalAndSparseSummary",
      returnKey,
      {}
    );
  },
  ocpuCountPerVM: function configTitles_ocpuCountPerVM(
    count: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("configTitles.ocpuCountPerVM", returnKey, { count });
  },
  provideRequestDetails: function configTitles_provideRequestDetails(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("configTitles.provideRequestDetails", returnKey, {});
  },
  quotaRequests: function configTitles_quotaRequests(
    count: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("configTitles.quotaRequests", returnKey, { count });
  },
  requestedOCPUCount: function configTitles_requestedOCPUCount(
    count: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("configTitles.requestedOCPUCount", returnKey, {
      count
    });
  },
  storageForLocal: function configTitles_storageForLocal(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("configTitles.storageForLocal", returnKey, {});
  },
  storageForLocalAndSparse: function configTitles_storageForLocalAndSparse(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "configTitles.storageForLocalAndSparse",
      returnKey,
      {}
    );
  },
  storageForLocalAndSparseSummary: function configTitles_storageForLocalAndSparseSummary(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "configTitles.storageForLocalAndSparseSummary",
      returnKey,
      {}
    );
  },
  storageForSparse: function configTitles_storageForSparse(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("configTitles.storageForSparse", returnKey, {});
  },
  storageLocalSummary: function configTitles_storageLocalSummary(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("configTitles.storageLocalSummary", returnKey, {});
  },
  storageSparseSummary: function configTitles_storageSparseSummary(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("configTitles.storageSparseSummary", returnKey, {});
  }
};

export const createAdbs = {
  basicsTab: {
    sectionTitles: {
      instanceDetails: function createAdbs_basicsTab_sectionTitles_instanceDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createAdbs.basicsTab.sectionTitles.instanceDetails",
          returnKey,
          {}
        );
      },
      projectDetails: function createAdbs_basicsTab_sectionTitles_projectDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createAdbs.basicsTab.sectionTitles.projectDetails",
          returnKey,
          {}
        );
      }
    },
    info: {
      learnMore: function createAdbs_basicsTab_info_learnMore(
        url: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createAdbs.basicsTab.info.learnMore",
          returnKey,
          { url }
        );
      }
    },
    title: function createAdbs_basicsTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createAdbs.basicsTab.title", returnKey, {});
    }
  },
  configurationTab: {
    sectionTitles: {
      databaseDetails: function createAdbs_configurationTab_sectionTitles_databaseDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createAdbs.configurationTab.sectionTitles.databaseDetails",
          returnKey,
          {}
        );
      }
    },
    title: function createAdbs_configurationTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createAdbs.configurationTab.title",
        returnKey,
        {}
      );
    }
  },
  networkingTab: {
    sectionTitles: {
      databaseAndToolsAccess: function createAdbs_networkingTab_sectionTitles_databaseAndToolsAccess(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createAdbs.networkingTab.sectionTitles.databaseAndToolsAccess",
          returnKey,
          {}
        );
      }
    },
    title: function createAdbs_networkingTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createAdbs.networkingTab.title", returnKey, {});
    }
  },
  securityTab: {
    sectionTitles: {
      databaseAdministratorCredentials: function createAdbs_securityTab_sectionTitles_databaseAdministratorCredentials(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createAdbs.securityTab.sectionTitles.databaseAdministratorCredentials",
          returnKey,
          {}
        );
      }
    },
    title: function createAdbs_securityTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createAdbs.securityTab.title", returnKey, {});
    }
  },
  titles: {
    long: function createAdbs_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createAdbs.titles.long", returnKey, {});
    },
    short: function createAdbs_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createAdbs.titles.short", returnKey, {});
    }
  }
};

export const createApex = {
  titles: {
    long: function createApex_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createApex.titles.long", returnKey, {});
    },
    short: function createApex_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createApex.titles.short", returnKey, {});
    }
  }
};

export const createCommon = {
  dependencyWarnings: {
    basicsChangeWarning: function createCommon_dependencyWarnings_basicsChangeWarning(
      resourceName: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createCommon.dependencyWarnings.basicsChangeWarning",
        returnKey,
        { resourceName }
      );
    },
    basicsIncomplete: {
      message: function createCommon_dependencyWarnings_basicsIncomplete_message(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.basicsIncomplete.message",
          returnKey,
          {}
        );
      },
      title: function createCommon_dependencyWarnings_basicsIncomplete_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.basicsIncomplete.title",
          returnKey,
          {}
        );
      }
    },
    infrastructureNotSelected: {
      message: function createCommon_dependencyWarnings_infrastructureNotSelected_message(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.infrastructureNotSelected.message",
          returnKey,
          {}
        );
      },
      title: function createCommon_dependencyWarnings_infrastructureNotSelected_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.infrastructureNotSelected.title",
          returnKey,
          {}
        );
      }
    },
    subscriptionNotSelected: {
      message: function createCommon_dependencyWarnings_subscriptionNotSelected_message(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.subscriptionNotSelected.message",
          returnKey,
          {}
        );
      },
      title: function createCommon_dependencyWarnings_subscriptionNotSelected_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.subscriptionNotSelected.title",
          returnKey,
          {}
        );
      }
    },
    virtualNetworkNotSelected: {
      message: function createCommon_dependencyWarnings_virtualNetworkNotSelected_message(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.virtualNetworkNotSelected.message",
          returnKey,
          {}
        );
      },
      title: function createCommon_dependencyWarnings_virtualNetworkNotSelected_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.virtualNetworkNotSelected.title",
          returnKey,
          {}
        );
      }
    },
    vmClusterNotSelected: {
      message: function createCommon_dependencyWarnings_vmClusterNotSelected_message(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.vmClusterNotSelected.message",
          returnKey,
          {}
        );
      },
      title: function createCommon_dependencyWarnings_vmClusterNotSelected_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.vmClusterNotSelected.title",
          returnKey,
          {}
        );
      }
    },
    vmShapeNotSelected: {
      message: function createCommon_dependencyWarnings_vmShapeNotSelected_message(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.vmShapeNotSelected.message",
          returnKey,
          {}
        );
      },
      title: function createCommon_dependencyWarnings_vmShapeNotSelected_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.vmShapeNotSelected.title",
          returnKey,
          {}
        );
      }
    },
    vnetNotSelected: {
      message: function createCommon_dependencyWarnings_vnetNotSelected_message(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.vnetNotSelected.message",
          returnKey,
          {}
        );
      },
      title: function createCommon_dependencyWarnings_vnetNotSelected_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createCommon.dependencyWarnings.vnetNotSelected.title",
          returnKey,
          {}
        );
      }
    }
  },
  loadingErrors: {
    general: function createCommon_loadingErrors_general(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createCommon.loadingErrors.general",
        returnKey,
        {}
      );
    },
    resourceGroups: function createCommon_loadingErrors_resourceGroups(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createCommon.loadingErrors.resourceGroups",
        returnKey,
        {}
      );
    }
  },
  loadingNone: function createCommon_loadingNone(
    itemName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("createCommon.loadingNone", returnKey, { itemName });
  },
  storageNoChangeAfterVMCreation: function createCommon_storageNoChangeAfterVMCreation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "createCommon.storageNoChangeAfterVMCreation",
      returnKey,
      {}
    );
  }
};

export const createExaDb = {
  basicsTab: {
    sectionTitles: {
      instanceDetails: function createExaDb_basicsTab_sectionTitles_instanceDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.basicsTab.sectionTitles.instanceDetails",
          returnKey,
          {}
        );
      },
      projectDetails: function createExaDb_basicsTab_sectionTitles_projectDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.basicsTab.sectionTitles.projectDetails",
          returnKey,
          {}
        );
      }
    },
    title: function createExaDb_basicsTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createExaDb.basicsTab.title", returnKey, {});
    }
  },
  configurationTab: {
    sectionTitles: {
      databaseDetail: function createExaDb_configurationTab_sectionTitles_databaseDetail(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.configurationTab.sectionTitles.databaseDetail",
          returnKey,
          {}
        );
      },
      databaseHomeDetails: function createExaDb_configurationTab_sectionTitles_databaseHomeDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.configurationTab.sectionTitles.databaseHomeDetails",
          returnKey,
          {}
        );
      },
      infrastructureDetail: function createExaDb_configurationTab_sectionTitles_infrastructureDetail(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.configurationTab.sectionTitles.infrastructureDetail",
          returnKey,
          {}
        );
      },
      vmCluster: function createExaDb_configurationTab_sectionTitles_vmCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.configurationTab.sectionTitles.vmCluster",
          returnKey,
          {}
        );
      }
    },
    title: function createExaDb_configurationTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createExaDb.configurationTab.title",
        returnKey,
        {}
      );
    }
  },
  managementTab: {
    info: {
      backupInfo: function createExaDb_managementTab_info_backupInfo(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.managementTab.info.backupInfo",
          returnKey,
          {}
        );
      }
    },
    sectionTitles: {
      backupHeader: function createExaDb_managementTab_sectionTitles_backupHeader(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.managementTab.sectionTitles.backupHeader",
          returnKey,
          {}
        );
      }
    },
    title: function createExaDb_managementTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createExaDb.managementTab.title", returnKey, {});
    }
  },
  networkingTab: {
    sectionTitles: {
      networkPeering: function createExaDb_networkingTab_sectionTitles_networkPeering(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.networkingTab.sectionTitles.networkPeering",
          returnKey,
          {}
        );
      },
      vmClusterNetworking: function createExaDb_networkingTab_sectionTitles_vmClusterNetworking(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.networkingTab.sectionTitles.vmClusterNetworking",
          returnKey,
          {}
        );
      }
    },
    title: function createExaDb_networkingTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createExaDb.networkingTab.title", returnKey, {});
    }
  },
  securityTab: {
    sectionDescriptions: {
      vmClusterAuthentication: function createExaDb_securityTab_sectionDescriptions_vmClusterAuthentication(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.securityTab.sectionDescriptions.vmClusterAuthentication",
          returnKey,
          {}
        );
      }
    },
    sectionTitles: {
      databaseAdministratorCredentials: function createExaDb_securityTab_sectionTitles_databaseAdministratorCredentials(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.securityTab.sectionTitles.databaseAdministratorCredentials",
          returnKey,
          {}
        );
      },
      vmClusterAuthentication: function createExaDb_securityTab_sectionTitles_vmClusterAuthentication(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createExaDb.securityTab.sectionTitles.vmClusterAuthentication",
          returnKey,
          {}
        );
      }
    },
    title: function createExaDb_securityTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createExaDb.securityTab.title", returnKey, {});
    }
  },
  titles: {
    long: function createExaDb_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createExaDb.titles.long", returnKey, {});
    },
    longFromBackup: function createExaDb_titles_longFromBackup(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createExaDb.titles.longFromBackup",
        returnKey,
        {}
      );
    },
    longFullStack: function createExaDb_titles_longFullStack(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createExaDb.titles.longFullStack", returnKey, {});
    },
    short: function createExaDb_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createExaDb.titles.short", returnKey, {});
    },
    shortFromBackup: function createExaDb_titles_shortFromBackup(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createExaDb.titles.shortFromBackup",
        returnKey,
        {}
      );
    },
    shortFullStack: function createExaDb_titles_shortFullStack(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createExaDb.titles.shortFullStack",
        returnKey,
        {}
      );
    }
  }
};

export const createInfra = {
  basicsTab: {
    sectionTitles: {
      instanceDetails: function createInfra_basicsTab_sectionTitles_instanceDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createInfra.basicsTab.sectionTitles.instanceDetails",
          returnKey,
          {}
        );
      },
      projectDetails: function createInfra_basicsTab_sectionTitles_projectDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createInfra.basicsTab.sectionTitles.projectDetails",
          returnKey,
          {}
        );
      }
    },
    title: function createInfra_basicsTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createInfra.basicsTab.title", returnKey, {});
    }
  },
  configurationTab: {
    sectionTitles: {
      infrastructureDetail: function createInfra_configurationTab_sectionTitles_infrastructureDetail(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createInfra.configurationTab.sectionTitles.infrastructureDetail",
          returnKey,
          {}
        );
      }
    },
    title: function createInfra_configurationTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createInfra.configurationTab.title",
        returnKey,
        {}
      );
    }
  },
  titles: {
    long: function createInfra_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createInfra.titles.long", returnKey, {});
    },
    short: function createInfra_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createInfra.titles.short", returnKey, {});
    }
  }
};

export const createMcvcn = {
  basicsTab: {
    sectionTitles: {
      instanceDetails: function createMcvcn_basicsTab_sectionTitles_instanceDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMcvcn.basicsTab.sectionTitles.instanceDetails",
          returnKey,
          {}
        );
      },
      projectDetails: function createMcvcn_basicsTab_sectionTitles_projectDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMcvcn.basicsTab.sectionTitles.projectDetails",
          returnKey,
          {}
        );
      }
    },
    title: function createMcvcn_basicsTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createMcvcn.basicsTab.title", returnKey, {});
    }
  },
  configurationTab: {
    title: function createMcvcn_configurationTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createMcvcn.configurationTab.title",
        returnKey,
        {}
      );
    }
  },
  titles: {
    long: function createMcvcn_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createMcvcn.titles.long", returnKey, {});
    },
    short: function createMcvcn_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createMcvcn.titles.short", returnKey, {});
    }
  }
};

export const createMysql = {
  basicsTab: {
    sectionTitles: {
      instanceDetails: function createMysql_basicsTab_sectionTitles_instanceDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.basicsTab.sectionTitles.instanceDetails",
          returnKey,
          {}
        );
      },
      projectDetails: function createMysql_basicsTab_sectionTitles_projectDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.basicsTab.sectionTitles.projectDetails",
          returnKey,
          {}
        );
      }
    },
    title: function createMysql_basicsTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createMysql.basicsTab.title", returnKey, {});
    }
  },
  configurationTab: {
    sectionTitles: {
      heatwaveDetails: function createMysql_configurationTab_sectionTitles_heatwaveDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.configurationTab.sectionTitles.heatwaveDetails",
          returnKey,
          {}
        );
      },
      infrastructureDetails: function createMysql_configurationTab_sectionTitles_infrastructureDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.configurationTab.sectionTitles.infrastructureDetails",
          returnKey,
          {}
        );
      },
      restoreDataFromBackup: function createMysql_configurationTab_sectionTitles_restoreDataFromBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.configurationTab.sectionTitles.restoreDataFromBackup",
          returnKey,
          {}
        );
      }
    },
    title: function createMysql_configurationTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createMysql.configurationTab.title",
        returnKey,
        {}
      );
    }
  },
  managementTab: {
    info: {
      backupInfo: function createMysql_managementTab_info_backupInfo(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.managementTab.info.backupInfo",
          returnKey,
          {}
        );
      },
      crashRecoveryInfo: function createMysql_managementTab_info_crashRecoveryInfo(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.managementTab.info.crashRecoveryInfo",
          returnKey,
          {}
        );
      }
    },
    sectionTitles: {
      backupHeader: function createMysql_managementTab_sectionTitles_backupHeader(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.managementTab.sectionTitles.backupHeader",
          returnKey,
          {}
        );
      },
      crashRecoveryHeader: function createMysql_managementTab_sectionTitles_crashRecoveryHeader(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.managementTab.sectionTitles.crashRecoveryHeader",
          returnKey,
          {}
        );
      },
      deletionPlanHeader: function createMysql_managementTab_sectionTitles_deletionPlanHeader(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.managementTab.sectionTitles.deletionPlanHeader",
          returnKey,
          {}
        );
      }
    },
    title: function createMysql_managementTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createMysql.managementTab.title", returnKey, {});
    }
  },
  networkingTab: {
    sectionTitles: {
      databaseSystemNetworking: function createMysql_networkingTab_sectionTitles_databaseSystemNetworking(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.networkingTab.sectionTitles.databaseSystemNetworking",
          returnKey,
          {}
        );
      },
      networkPeering: function createMysql_networkingTab_sectionTitles_networkPeering(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.networkingTab.sectionTitles.networkPeering",
          returnKey,
          {}
        );
      }
    },
    title: function createMysql_networkingTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createMysql.networkingTab.title", returnKey, {});
    }
  },
  securityTab: {
    sectionTitles: {
      administratorCredentials: function createMysql_securityTab_sectionTitles_administratorCredentials(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createMysql.securityTab.sectionTitles.administratorCredentials",
          returnKey,
          {}
        );
      }
    },
    title: function createMysql_securityTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createMysql.securityTab.title", returnKey, {});
    }
  },
  titles: {
    long: function createMysql_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createMysql.titles.long", returnKey, {});
    },
    short: function createMysql_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createMysql.titles.short", returnKey, {});
    }
  }
};

export const createNewPanels = {
  addCapacity: {
    title: function createNewPanels_addCapacity_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.addCapacity.title",
        returnKey,
        {}
      );
    }
  },
  addRegion: {
    title: function createNewPanels_addRegion_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createNewPanels.addRegion.title", returnKey, {});
    },
    descriptionMultipleCloudLinks: function createNewPanels_addRegion_descriptionMultipleCloudLinks(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.addRegion.descriptionMultipleCloudLinks",
        returnKey,
        {}
      );
    },
    descriptionSingleCloudLink: function createNewPanels_addRegion_descriptionSingleCloudLink(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.addRegion.descriptionSingleCloudLink",
        returnKey,
        {}
      );
    }
  },
  backup: {
    title: function createNewPanels_backup_title(returnKey?: boolean): string {
      return shouldReturnKey("createNewPanels.backup.title", returnKey, {});
    }
  },
  changePassword: {
    title: function createNewPanels_changePassword_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.changePassword.title",
        returnKey,
        {}
      );
    }
  },
  changeTdePassword: {
    title: function createNewPanels_changeTdePassword_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.changeTdePassword.title",
        returnKey,
        {}
      );
    }
  },
  configCoreCount: {
    title: function createNewPanels_configCoreCount_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.configCoreCount.title",
        returnKey,
        {}
      );
    }
  },
  configStorage: {
    title: function createNewPanels_configStorage_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.configStorage.title",
        returnKey,
        {}
      );
    },
    description: function createNewPanels_configStorage_description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.configStorage.description",
        returnKey,
        {}
      );
    }
  },
  dbHome: {
    title: function createNewPanels_dbHome_title(returnKey?: boolean): string {
      return shouldReturnKey("createNewPanels.dbHome.title", returnKey, {});
    }
  },
  deleteExaInfra: {
    confirmCb: function createNewPanels_deleteExaInfra_confirmCb(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.deleteExaInfra.confirmCb",
        returnKey,
        {}
      );
    },
    databaseNotLoaded: function createNewPanels_deleteExaInfra_databaseNotLoaded(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.deleteExaInfra.databaseNotLoaded",
        returnKey,
        {}
      );
    },
    description: function createNewPanels_deleteExaInfra_description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.deleteExaInfra.description",
        returnKey,
        {}
      );
    },
    noVmCluster: function createNewPanels_deleteExaInfra_noVmCluster(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.deleteExaInfra.noVmCluster",
        returnKey,
        {}
      );
    },
    vmClusterNotLoaded: function createNewPanels_deleteExaInfra_vmClusterNotLoaded(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.deleteExaInfra.vmClusterNotLoaded",
        returnKey,
        {}
      );
    },
    warningInfoBlock: function createNewPanels_deleteExaInfra_warningInfoBlock(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.deleteExaInfra.warningInfoBlock",
        returnKey,
        {}
      );
    }
  },
  editBackup: {
    title: function createNewPanels_editBackup_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createNewPanels.editBackup.title", returnKey, {});
    }
  },
  editDatabaseName: {
    title: function createNewPanels_editDatabaseName_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editDatabaseName.title",
        returnKey,
        {}
      );
    }
  },
  editDataStorageSize: {
    title: function createNewPanels_editDataStorageSize_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editDataStorageSize.title",
        returnKey,
        {}
      );
    }
  },
  editDescription: {
    title: function createNewPanels_editDescription_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editDescription.title",
        returnKey,
        {}
      );
    }
  },
  editMySqlBackupPlan: {
    title: function createNewPanels_editMySqlBackupPlan_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editMySqlBackupPlan.title",
        returnKey,
        {}
      );
    }
  },
  editMySqlDeletionPlan: {
    title: function createNewPanels_editMySqlDeletionPlan_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editMySqlDeletionPlan.title",
        returnKey,
        {}
      );
    }
  },
  editMySqlVersion: {
    title: function createNewPanels_editMySqlVersion_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editMySqlVersion.title",
        returnKey,
        {}
      );
    }
  },
  editNetworking: {
    title: function createNewPanels_editNetworking_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editNetworking.title",
        returnKey,
        {}
      );
    }
  },
  editNetworkingMTLS: {
    title: function createNewPanels_editNetworkingMTLS_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editNetworkingMTLS.title",
        returnKey,
        {}
      );
    }
  },
  editRetentionDays: {
    title: function createNewPanels_editRetentionDays_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editRetentionDays.title",
        returnKey,
        {}
      );
    }
  },
  editShape: {
    title: function createNewPanels_editShape_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createNewPanels.editShape.title", returnKey, {});
    }
  },
  editStorage: {
    title: function createNewPanels_editStorage_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.editStorage.title",
        returnKey,
        {}
      );
    }
  },
  licenseType: {
    title: function createNewPanels_licenseType_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.licenseType.title",
        returnKey,
        {}
      );
    }
  },
  linkSubscription: {
    title: function createNewPanels_linkSubscription_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.linkSubscription.title",
        returnKey,
        {}
      );
    },
    descriptionMultipleCloudLinks: function createNewPanels_linkSubscription_descriptionMultipleCloudLinks(
      name: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.linkSubscription.descriptionMultipleCloudLinks",
        returnKey,
        { name }
      );
    },
    descriptionSingleCloudLink: function createNewPanels_linkSubscription_descriptionSingleCloudLink(
      name: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.linkSubscription.descriptionSingleCloudLink",
        returnKey,
        { name }
      );
    }
  },
  mcvcn: {
    title: function createNewPanels_mcvcn_title(returnKey?: boolean): string {
      return shouldReturnKey("createNewPanels.mcvcn.title", returnKey, {});
    }
  },
  mysqlManualBackup: {
    title: function createNewPanels_mysqlManualBackup_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.mysqlManualBackup.title",
        returnKey,
        {}
      );
    }
  },
  nodeCountEstimator: {
    columns: function createNewPanels_nodeCountEstimator_columns(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.nodeCountEstimator.columns",
        returnKey,
        {}
      );
    },
    emptyList: {
      title: function createNewPanels_nodeCountEstimator_emptyList_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createNewPanels.nodeCountEstimator.emptyList.title",
          returnKey,
          {}
        );
      }
    },
    generateEstimate: function createNewPanels_nodeCountEstimator_generateEstimate(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.nodeCountEstimator.generateEstimate",
        returnKey,
        {}
      );
    },
    infoBlock: {
      nodeCount: function createNewPanels_nodeCountEstimator_infoBlock_nodeCount(
        number: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createNewPanels.nodeCountEstimator.infoBlock.nodeCount",
          returnKey,
          { number }
        );
      },
      memorySizeGB: function createNewPanels_nodeCountEstimator_infoBlock_memorySizeGB(
        number: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createNewPanels.nodeCountEstimator.infoBlock.memorySizeGB",
          returnKey,
          { number }
        );
      },
      memorySizeMB: function createNewPanels_nodeCountEstimator_infoBlock_memorySizeMB(
        number: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createNewPanels.nodeCountEstimator.infoBlock.memorySizeMB",
          returnKey,
          { number }
        );
      },
      memorySizeTB: function createNewPanels_nodeCountEstimator_infoBlock_memorySizeTB(
        number: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createNewPanels.nodeCountEstimator.infoBlock.memorySizeTB",
          returnKey,
          { number }
        );
      }
    },
    memory: function createNewPanels_nodeCountEstimator_memory(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.nodeCountEstimator.memory",
        returnKey,
        {}
      );
    },
    mySqlCommand: {
      title: function createNewPanels_nodeCountEstimator_mySqlCommand_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createNewPanels.nodeCountEstimator.mySqlCommand.title",
          returnKey,
          {}
        );
      },
      description: function createNewPanels_nodeCountEstimator_mySqlCommand_description(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createNewPanels.nodeCountEstimator.mySqlCommand.description",
          returnKey,
          {}
        );
      }
    },
    regenerateEstimate: function createNewPanels_nodeCountEstimator_regenerateEstimate(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.nodeCountEstimator.regenerateEstimate",
        returnKey,
        {}
      );
    },
    rowCount: function createNewPanels_nodeCountEstimator_rowCount(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.nodeCountEstimator.rowCount",
        returnKey,
        {}
      );
    },
    tableList: {
      row: {
        columnsCell: function createNewPanels_nodeCountEstimator_tableList_row_columnsCell(
          columns: string,
          varlen: string,
          returnKey?: boolean
        ): string {
          return shouldReturnKey(
            "createNewPanels.nodeCountEstimator.tableList.row.columnsCell",
            returnKey,
            { columns, varlen }
          );
        }
      }
    },
    title: function createNewPanels_nodeCountEstimator_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.nodeCountEstimator.title",
        returnKey,
        {}
      );
    }
  },
  quotaDetails: {
    title: function createNewPanels_quotaDetails_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.quotaDetails.title",
        returnKey,
        {}
      );
    }
  },
  resourceGroup: {
    description: function createNewPanels_resourceGroup_description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.resourceGroup.description",
        returnKey,
        {}
      );
    }
  },
  restore: {
    restoreDbToPoint: function createNewPanels_restore_restoreDbToPoint(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.restore.restoreDbToPoint",
        returnKey,
        {}
      );
    },
    title: function createNewPanels_restore_title(returnKey?: boolean): string {
      return shouldReturnKey("createNewPanels.restore.title", returnKey, {});
    }
  },
  scaleCoreCount: {
    title: function createNewPanels_scaleCoreCount_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.scaleCoreCount.title",
        returnKey,
        {}
      );
    }
  },
  scaleExaInfra: {
    title: function createNewPanels_scaleExaInfra_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.scaleExaInfra.title",
        returnKey,
        {}
      );
    }
  },
  scaleVmDbSystem: {
    title: function createNewPanels_scaleVmDbSystem_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.scaleVmDbSystem.title",
        returnKey,
        {}
      );
    }
  },
  supportSearch: {
    description: function createNewPanels_supportSearch_description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.supportSearch.description",
        returnKey,
        {}
      );
    }
  },
  virtualNetwork: {
    title: function createNewPanels_virtualNetwork_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.virtualNetwork.title",
        returnKey,
        {}
      );
    }
  },
  vmShape: {
    title: function createNewPanels_vmShape_title(returnKey?: boolean): string {
      return shouldReturnKey("createNewPanels.vmShape.title", returnKey, {});
    }
  },
  walletDownload: {
    description: function createNewPanels_walletDownload_description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.walletDownload.description",
        returnKey,
        {}
      );
    },
    title: function createNewPanels_walletDownload_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createNewPanels.walletDownload.title",
        returnKey,
        {}
      );
    }
  }
};

export const createPluggableDatabase = {
  title: function createPluggableDatabase_title(returnKey?: boolean): string {
    return shouldReturnKey("createPluggableDatabase.title", returnKey, {});
  }
};

export const createSupport = {
  detailsTab: {
    sectionDescriptions: {
      problemDetails: function createSupport_detailsTab_sectionDescriptions_problemDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.detailsTab.sectionDescriptions.problemDetails",
          returnKey,
          {}
        );
      },
      tellUs: function createSupport_detailsTab_sectionDescriptions_tellUs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.detailsTab.sectionDescriptions.tellUs",
          returnKey,
          {}
        );
      }
    },
    sectionTitles: {
      problemDetails: function createSupport_detailsTab_sectionTitles_problemDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.detailsTab.sectionTitles.problemDetails",
          returnKey,
          {}
        );
      },
      tellUs: function createSupport_detailsTab_sectionTitles_tellUs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.detailsTab.sectionTitles.tellUs",
          returnKey,
          {}
        );
      }
    },
    title: function createSupport_detailsTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createSupport.detailsTab.title", returnKey, {});
    }
  },
  problemTab: {
    sectionDescriptions: {
      tellUs: function createSupport_problemTab_sectionDescriptions_tellUs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.sectionDescriptions.tellUs",
          returnKey,
          {}
        );
      }
    },
    sectionTitles: {
      tellUs: function createSupport_problemTab_sectionTitles_tellUs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.sectionTitles.tellUs",
          returnKey,
          {}
        );
      }
    },
    title: function createSupport_problemTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createSupport.problemTab.title", returnKey, {});
    },
    validationMessages: {
      csiRequestPending: function createSupport_problemTab_validationMessages_csiRequestPending(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.csiRequestPending",
          returnKey,
          {}
        );
      },
      emailNotVerified: function createSupport_problemTab_validationMessages_emailNotVerified(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.emailNotVerified",
          returnKey,
          {}
        );
      },
      issueTypePrereqs: function createSupport_problemTab_validationMessages_issueTypePrereqs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.issueTypePrereqs",
          returnKey,
          {}
        );
      },
      noCsiAccount: function createSupport_problemTab_validationMessages_noCsiAccount(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noCsiAccount",
          returnKey,
          {}
        );
      },
      noCsiRequest: function createSupport_problemTab_validationMessages_noCsiRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noCsiRequest",
          returnKey,
          {}
        );
      },
      noCsiRequestStep1: function createSupport_problemTab_validationMessages_noCsiRequestStep1(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noCsiRequestStep1",
          returnKey,
          {}
        );
      },
      noCsiRequestStep2: function createSupport_problemTab_validationMessages_noCsiRequestStep2(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noCsiRequestStep2",
          returnKey,
          {}
        );
      },
      noEmail: function createSupport_problemTab_validationMessages_noEmail(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noEmail",
          returnKey,
          {}
        );
      },
      noMosAccount1: function createSupport_problemTab_validationMessages_noMosAccount1(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noMosAccount1",
          returnKey,
          {}
        );
      },
      noMosAccount1Step1: function createSupport_problemTab_validationMessages_noMosAccount1Step1(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noMosAccount1Step1",
          returnKey,
          {}
        );
      },
      noMosAccount1Step2: function createSupport_problemTab_validationMessages_noMosAccount1Step2(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noMosAccount1Step2",
          returnKey,
          {}
        );
      },
      noMosAccount1Step3: function createSupport_problemTab_validationMessages_noMosAccount1Step3(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noMosAccount1Step3",
          returnKey,
          {}
        );
      },
      noMosAccount2: function createSupport_problemTab_validationMessages_noMosAccount2(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noMosAccount2",
          returnKey,
          {}
        );
      },
      noMosAccount2Step1: function createSupport_problemTab_validationMessages_noMosAccount2Step1(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noMosAccount2Step1",
          returnKey,
          {}
        );
      },
      noMosAccount2Step2: function createSupport_problemTab_validationMessages_noMosAccount2Step2(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noMosAccount2Step2",
          returnKey,
          {}
        );
      },
      noMosAccount2Step3: function createSupport_problemTab_validationMessages_noMosAccount2Step3(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.noMosAccount2Step3",
          returnKey,
          {}
        );
      },
      title: function createSupport_problemTab_validationMessages_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createSupport.problemTab.validationMessages.title",
          returnKey,
          {}
        );
      }
    }
  },
  submitPayload: {
    quotaTitle: function createSupport_submitPayload_quotaTitle(
      quota: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createSupport.submitPayload.quotaTitle",
        returnKey,
        { quota }
      );
    }
  },
  titles: {
    long: function createSupport_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createSupport.titles.long", returnKey, {});
    },
    short: function createSupport_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createSupport.titles.short", returnKey, {});
    }
  },
  userWarning: function createSupport_userWarning(returnKey?: boolean): string {
    return shouldReturnKey("createSupport.userWarning", returnKey, {});
  }
};

export const createVMCluster = {
  basicsTab: {
    title: function createVMCluster_basicsTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createVMCluster.basicsTab.title", returnKey, {});
    }
  },
  configurationTab: {
    title: function createVMCluster_configurationTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createVMCluster.configurationTab.title",
        returnKey,
        {}
      );
    }
  },
  networkingTab: {
    title: function createVMCluster_networkingTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createVMCluster.networkingTab.title",
        returnKey,
        {}
      );
    }
  },
  securityTab: {
    title: function createVMCluster_securityTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createVMCluster.securityTab.title",
        returnKey,
        {}
      );
    }
  },
  titles: {
    long: function createVMCluster_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createVMCluster.titles.long", returnKey, {});
    },
    short: function createVMCluster_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createVMCluster.titles.short", returnKey, {});
    }
  }
};

export const createVmDb = {
  basicsTab: {
    sectionTitles: {
      instanceDetails: function createVmDb_basicsTab_sectionTitles_instanceDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.basicsTab.sectionTitles.instanceDetails",
          returnKey,
          {}
        );
      },
      projectDetails: function createVmDb_basicsTab_sectionTitles_projectDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.basicsTab.sectionTitles.projectDetails",
          returnKey,
          {}
        );
      }
    },
    title: function createVmDb_basicsTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createVmDb.basicsTab.title", returnKey, {});
    }
  },
  configurationTab: {
    sectionTitles: {
      databaseDetails: function createVmDb_configurationTab_sectionTitles_databaseDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.configurationTab.sectionTitles.databaseDetails",
          returnKey,
          {}
        );
      },
      databaseHomeDetails: function createVmDb_configurationTab_sectionTitles_databaseHomeDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.configurationTab.sectionTitles.databaseHomeDetails",
          returnKey,
          {}
        );
      },
      databaseSystemDetails: function createVmDb_configurationTab_sectionTitles_databaseSystemDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.configurationTab.sectionTitles.databaseSystemDetails",
          returnKey,
          {}
        );
      }
    },
    title: function createVmDb_configurationTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createVmDb.configurationTab.title",
        returnKey,
        {}
      );
    }
  },
  managementTab: {
    info: {
      backupInfo: function createVmDb_managementTab_info_backupInfo(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.managementTab.info.backupInfo",
          returnKey,
          {}
        );
      }
    },
    sectionTitles: {
      backupHeader: function createVmDb_managementTab_sectionTitles_backupHeader(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.managementTab.sectionTitles.backupHeader",
          returnKey,
          {}
        );
      }
    },
    title: function createVmDb_managementTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createVmDb.managementTab.title", returnKey, {});
    }
  },
  networkingTab: {
    sectionTitles: {
      databaseSystemNetworking: function createVmDb_networkingTab_sectionTitles_databaseSystemNetworking(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.networkingTab.sectionTitles.databaseSystemNetworking",
          returnKey,
          {}
        );
      },
      networkPeering: function createVmDb_networkingTab_sectionTitles_networkPeering(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.networkingTab.sectionTitles.networkPeering",
          returnKey,
          {}
        );
      }
    },
    title: function createVmDb_networkingTab_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createVmDb.networkingTab.title", returnKey, {});
    }
  },
  securityTab: {
    sectionTitles: {
      databaseAdministratorCredentials: function createVmDb_securityTab_sectionTitles_databaseAdministratorCredentials(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.securityTab.sectionTitles.databaseAdministratorCredentials",
          returnKey,
          {}
        );
      },
      databaseSystemAuthentication: function createVmDb_securityTab_sectionTitles_databaseSystemAuthentication(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "createVmDb.securityTab.sectionTitles.databaseSystemAuthentication",
          returnKey,
          {}
        );
      }
    },
    title: function createVmDb_securityTab_title(returnKey?: boolean): string {
      return shouldReturnKey("createVmDb.securityTab.title", returnKey, {});
    }
  },
  titles: {
    long: function createVmDb_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("createVmDb.titles.long", returnKey, {});
    },
    longFromBackup: function createVmDb_titles_longFromBackup(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("createVmDb.titles.longFromBackup", returnKey, {});
    },
    short: function createVmDb_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("createVmDb.titles.short", returnKey, {});
    },
    shortFromBackup: function createVmDb_titles_shortFromBackup(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "createVmDb.titles.shortFromBackup",
        returnKey,
        {}
      );
    }
  }
};

export const dbEditionOptions = {
  enterpriseEdition: function dbEditionOptions_enterpriseEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("dbEditionOptions.enterpriseEdition", returnKey, {});
  },
  standardEdition: function dbEditionOptions_standardEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("dbEditionOptions.standardEdition", returnKey, {});
  }
};

export const dbSystemDatabaseEditionOptions = {
  standardEdition: function dbSystemDatabaseEditionOptions_standardEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "dbSystemDatabaseEditionOptions.standardEdition",
      returnKey,
      {}
    );
  },
  enterpriseEdition: function dbSystemDatabaseEditionOptions_enterpriseEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "dbSystemDatabaseEditionOptions.enterpriseEdition",
      returnKey,
      {}
    );
  },
  enterpriseEditionHighPerformance: function dbSystemDatabaseEditionOptions_enterpriseEditionHighPerformance(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "dbSystemDatabaseEditionOptions.enterpriseEditionHighPerformance",
      returnKey,
      {}
    );
  },
  enterpriseEditionExtremePerformance: function dbSystemDatabaseEditionOptions_enterpriseEditionExtremePerformance(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "dbSystemDatabaseEditionOptions.enterpriseEditionExtremePerformance",
      returnKey,
      {}
    );
  }
};

export const deployment = {
  emptyList: {
    title: function deployment_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("deployment.emptyList.title", returnKey, {});
    }
  },
  loadError: {
    general: {
      details: function deployment_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function deployment_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function deployment_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function deployment_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  messages: {
    cleanup: function deployment_messages_cleanup(returnKey?: boolean): string {
      return shouldReturnKey("deployment.messages.cleanup", returnKey, {});
    }
  },
  overview: {
    sectionTitles: {
      deploymentDetails: function deployment_overview_sectionTitles_deploymentDetails(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.deploymentDetails",
          returnKey,
          {}
        );
      },
      deploymentFailed: function deployment_overview_sectionTitles_deploymentFailed(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.deploymentFailed",
          returnKey,
          {}
        );
      },
      deploymentStatusAccepted: function deployment_overview_sectionTitles_deploymentStatusAccepted(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.deploymentStatusAccepted",
          returnKey,
          {}
        );
      },
      deploymentStatusCanceled: function deployment_overview_sectionTitles_deploymentStatusCanceled(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.deploymentStatusCanceled",
          returnKey,
          {}
        );
      },
      deploymentStatusCanceling: function deployment_overview_sectionTitles_deploymentStatusCanceling(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.deploymentStatusCanceling",
          returnKey,
          {}
        );
      },
      deploymentStatusFailed: function deployment_overview_sectionTitles_deploymentStatusFailed(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.deploymentStatusFailed",
          returnKey,
          {}
        );
      },
      deploymentStatusInProgress: function deployment_overview_sectionTitles_deploymentStatusInProgress(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.deploymentStatusInProgress",
          returnKey,
          {}
        );
      },
      deploymentStatusSucceeded: function deployment_overview_sectionTitles_deploymentStatusSucceeded(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.deploymentStatusSucceeded",
          returnKey,
          {}
        );
      },
      nextSteps: function deployment_overview_sectionTitles_nextSteps(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "deployment.overview.sectionTitles.nextSteps",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    deployment: function deployment_titles_deployment(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("deployment.titles.deployment", returnKey, {});
    },
    long: function deployment_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("deployment.titles.long", returnKey, {});
    },
    short: function deployment_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("deployment.titles.short", returnKey, {});
    }
  }
};

export const detailAdbs = {
  connect: {
    sectionTitles: {
      connectionStrings: function detailAdbs_connect_sectionTitles_connectionStrings(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailAdbs.connect.sectionTitles.connectionStrings",
          returnKey,
          {}
        );
      }
    }
  },
  loadError: {
    general: {
      details: function detailAdbs_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailAdbs.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailAdbs_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailAdbs.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailAdbs_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailAdbs.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailAdbs_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailAdbs.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  networking: {
    sectionTitles: {
      ipsAndOrCidrs: function detailAdbs_networking_sectionTitles_ipsAndOrCidrs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailAdbs.networking.sectionTitles.ipsAndOrCidrs",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailAdbs_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailAdbs.titles.long", returnKey, {});
    },
    short: function detailAdbs_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailAdbs.titles.short", returnKey, {});
    }
  },
  upgradeToATP: {
    title: function detailAdbs_upgradeToATP_title(returnKey?: boolean): string {
      return shouldReturnKey("detailAdbs.upgradeToATP.title", returnKey, {});
    },
    warning: function detailAdbs_upgradeToATP_warning(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("detailAdbs.upgradeToATP.warning", returnKey, {});
    },
    description: function detailAdbs_upgradeToATP_description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailAdbs.upgradeToATP.description",
        returnKey,
        {}
      );
    },
    description1stPoint: function detailAdbs_upgradeToATP_description1stPoint(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailAdbs.upgradeToATP.description1stPoint",
        returnKey,
        {}
      );
    },
    description2ndPoint: function detailAdbs_upgradeToATP_description2ndPoint(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailAdbs.upgradeToATP.description2ndPoint",
        returnKey,
        {}
      );
    },
    descriptionConfirmation: function detailAdbs_upgradeToATP_descriptionConfirmation(
      instanceName: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailAdbs.upgradeToATP.descriptionConfirmation",
        returnKey,
        { instanceName }
      );
    },
    descriptionNote: function detailAdbs_upgradeToATP_descriptionNote(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailAdbs.upgradeToATP.descriptionNote",
        returnKey,
        {}
      );
    }
  }
};

export const detailApex = {
  loadError: {
    general: {
      details: function detailApex_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailApex.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailApex_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailApex.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailApex_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailApex.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailApex_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailApex.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailApex_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailApex.titles.long", returnKey, {});
    },
    short: function detailApex_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailApex.titles.short", returnKey, {});
    }
  }
};

export const detailExaDb = {
  connect: {
    sectionTitles: {
      connectionStrings: function detailExaDb_connect_sectionTitles_connectionStrings(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailExaDb.connect.sectionTitles.connectionStrings",
          returnKey,
          {}
        );
      }
    }
  },
  loadError: {
    general: {
      details: function detailExaDb_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailExaDb.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailExaDb_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailExaDb.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailExaDb_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailExaDb.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailExaDb_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailExaDb.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailExaDb_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailExaDb.titles.long", returnKey, {});
    },
    short: function detailExaDb_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailExaDb.titles.short", returnKey, {});
    }
  }
};

export const detailInfra = {
  loadError: {
    general: {
      details: function detailInfra_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailInfra.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailInfra_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailInfra.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailInfra_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailInfra.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailInfra_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailInfra.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailInfra_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailInfra.titles.long", returnKey, {});
    },
    short: function detailInfra_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailInfra.titles.short", returnKey, {});
    }
  }
};

export const detailMcvcn = {
  configuration: {
    sectionTitles: {
      ociSubnets: function detailMcvcn_configuration_sectionTitles_ociSubnets(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMcvcn.configuration.sectionTitles.ociSubnets",
          returnKey,
          {}
        );
      }
    }
  },
  loadError: {
    general: {
      details: function detailMcvcn_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMcvcn.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailMcvcn_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMcvcn.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailMcvcn_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMcvcn.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailMcvcn_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMcvcn.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailMcvcn_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailMcvcn.titles.long", returnKey, {});
    },
    short: function detailMcvcn_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailMcvcn.titles.short", returnKey, {});
    }
  }
};

export const detailMysql = {
  crashRecoveryConfirmation: {
    enableConfirmation: function detailMysql_crashRecoveryConfirmation_enableConfirmation(
      dbName: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.crashRecoveryConfirmation.enableConfirmation",
        returnKey,
        { dbName }
      );
    },
    enableDescription: function detailMysql_crashRecoveryConfirmation_enableDescription(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.crashRecoveryConfirmation.enableDescription",
        returnKey,
        {}
      );
    },
    enableTitle: function detailMysql_crashRecoveryConfirmation_enableTitle(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.crashRecoveryConfirmation.enableTitle",
        returnKey,
        {}
      );
    },
    disableConfirmation: function detailMysql_crashRecoveryConfirmation_disableConfirmation(
      dbName: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.crashRecoveryConfirmation.disableConfirmation",
        returnKey,
        { dbName }
      );
    },
    disableTitle: function detailMysql_crashRecoveryConfirmation_disableTitle(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.crashRecoveryConfirmation.disableTitle",
        returnKey,
        {}
      );
    },
    disableWarnig: function detailMysql_crashRecoveryConfirmation_disableWarnig(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.crashRecoveryConfirmation.disableWarnig",
        returnKey,
        {}
      );
    }
  },
  infoBlock: {
    crashRecoveryDisabled: function detailMysql_infoBlock_crashRecoveryDisabled(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.infoBlock.crashRecoveryDisabled",
        returnKey,
        {}
      );
    }
  },
  loadError: {
    general: {
      details: function detailMysql_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMysql.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailMysql_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMysql.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailMysql_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMysql.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailMysql_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMysql.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  messages: {
    backupDisabled: function detailMysql_messages_backupDisabled(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.messages.backupDisabled",
        returnKey,
        {}
      );
    },
    crashRecoverDisabled: function detailMysql_messages_crashRecoverDisabled(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "detailMysql.messages.crashRecoverDisabled",
        returnKey,
        {}
      );
    }
  },
  titles: {
    long: function detailMysql_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailMysql.titles.long", returnKey, {});
    },
    short: function detailMysql_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailMysql.titles.short", returnKey, {});
    }
  }
};

export const detailMysqlBackup = {
  loadError: {
    general: {
      details: function detailMysqlBackup_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMysqlBackup.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailMysqlBackup_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMysqlBackup.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailMysqlBackup_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMysqlBackup.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailMysqlBackup_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailMysqlBackup.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailMysqlBackup_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailMysqlBackup.titles.long", returnKey, {});
    },
    short: function detailMysqlBackup_titles_short(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("detailMysqlBackup.titles.short", returnKey, {});
    }
  },
  warning: function detailMysqlBackup_warning(returnKey?: boolean): string {
    return shouldReturnKey("detailMysqlBackup.warning", returnKey, {});
  }
};

export const detailSupport = {
  loadError: {
    general: {
      details: function detailSupport_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailSupport.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailSupport_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailSupport.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailSupport_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailSupport.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailSupport_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailSupport.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  overview: {
    titles: {
      contactInfo: function detailSupport_overview_titles_contactInfo(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailSupport.overview.titles.contactInfo",
          returnKey,
          {}
        );
      },
      details: function detailSupport_overview_titles_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailSupport.overview.titles.details",
          returnKey,
          {}
        );
      },
      overview: function detailSupport_overview_titles_overview(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailSupport.overview.titles.overview",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    short: function detailSupport_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailSupport.titles.short", returnKey, {});
    },
    supportRequest: function detailSupport_titles_supportRequest(
      title: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("detailSupport.titles.supportRequest", returnKey, {
        title
      });
    }
  }
};

export const detailVmCluster = {
  loadError: {
    general: {
      details: function detailVmCluster_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmCluster.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailVmCluster_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmCluster.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailVmCluster_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmCluster.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailVmCluster_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmCluster.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailVmCluster_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailVmCluster.titles.long", returnKey, {});
    },
    short: function detailVmCluster_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailVmCluster.titles.short", returnKey, {});
    }
  }
};

export const detailVmDb = {
  connect: {
    sectionTitles: {
      connectionStrings: function detailVmDb_connect_sectionTitles_connectionStrings(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDb.connect.sectionTitles.connectionStrings",
          returnKey,
          {}
        );
      }
    }
  },
  loadError: {
    general: {
      details: function detailVmDb_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDb.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailVmDb_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDb.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailVmDb_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDb.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailVmDb_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDb.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailVmDb_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailVmDb.titles.long", returnKey, {});
    },
    short: function detailVmDb_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailVmDb.titles.short", returnKey, {});
    }
  }
};

export const detailVmDbSystem = {
  loadError: {
    general: {
      details: function detailVmDbSystem_loadError_general_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDbSystem.loadError.general.details",
          returnKey,
          {}
        );
      },
      title: function detailVmDbSystem_loadError_general_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDbSystem.loadError.general.title",
          returnKey,
          {}
        );
      }
    },
    notFound: {
      details: function detailVmDbSystem_loadError_notFound_details(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDbSystem.loadError.notFound.details",
          returnKey,
          {}
        );
      },
      title: function detailVmDbSystem_loadError_notFound_title(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "detailVmDbSystem.loadError.notFound.title",
          returnKey,
          {}
        );
      }
    }
  },
  titles: {
    long: function detailVmDbSystem_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("detailVmDbSystem.titles.long", returnKey, {});
    },
    short: function detailVmDbSystem_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("detailVmDbSystem.titles.short", returnKey, {});
    }
  }
};

export const feedback = {
  infoBlock: function feedback_infoBlock(returnKey?: boolean): string {
    return shouldReturnKey("feedback.infoBlock", returnKey, {});
  },
  subtitle: function feedback_subtitle(returnKey?: boolean): string {
    return shouldReturnKey("feedback.subtitle", returnKey, {});
  },
  title: function feedback_title(returnKey?: boolean): string {
    return shouldReturnKey("feedback.title", returnKey, {});
  }
};

export const footer = {
  oracleCopyright: function footer_oracleCopyright(
    copyRightSymbol: string,
    year: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("footer.oracleCopyright", returnKey, {
      copyRightSymbol,
      year
    });
  },
  termsOfUseAndPrivacy: function footer_termsOfUseAndPrivacy(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("footer.termsOfUseAndPrivacy", returnKey, {});
  }
};

export const groupBy = {
  containerDb: function groupBy_containerDb(returnKey?: boolean): string {
    return shouldReturnKey("groupBy.containerDb", returnKey, {});
  },
  databaseSystem: function groupBy_databaseSystem(returnKey?: boolean): string {
    return shouldReturnKey("groupBy.databaseSystem", returnKey, {});
  },
  location: function groupBy_location(returnKey?: boolean): string {
    return shouldReturnKey("groupBy.location", returnKey, {});
  },
  resourceGroup: function groupBy_resourceGroup(returnKey?: boolean): string {
    return shouldReturnKey("groupBy.resourceGroup", returnKey, {});
  },
  status: function groupBy_status(returnKey?: boolean): string {
    return shouldReturnKey("groupBy.status", returnKey, {});
  },
  subscription: function groupBy_subscription(returnKey?: boolean): string {
    return shouldReturnKey("groupBy.subscription", returnKey, {});
  }
};

export const hints = {
  accessFromAllowedIPs: function hints_accessFromAllowedIPs(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.accessFromAllowedIPs", returnKey, {});
  },
  accessFromAzurePrivateEndpoint: function hints_accessFromAzurePrivateEndpoint(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.accessFromAzurePrivateEndpoint",
      returnKey,
      {}
    );
  },
  accessFromEverywhere: function hints_accessFromEverywhere(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.accessFromEverywhere", returnKey, {});
  },
  adbsDisabledActionsAdbsStatus: function hints_adbsDisabledActionsAdbsStatus(
    adbsActions: string,
    adbsStatus: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.adbsDisabledActionsAdbsStatus", returnKey, {
      adbsActions,
      adbsStatus
    });
  },
  allocateStorageLocal: function hints_allocateStorageLocal(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.allocateStorageLocal", returnKey, {});
  },
  allocateStorageSparse: function hints_allocateStorageSparse(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.allocateStorageSparse", returnKey, {});
  },
  bringYourOwnLicense: function hints_bringYourOwnLicense(
    url: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.bringYourOwnLicense", returnKey, { url });
  },
  cdbDisabledActions: function hints_cdbDisabledActions(
    pdbActions: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.cdbDisabledActions", returnKey, {
      pdbActions
    });
  },
  createHeatWave: function hints_createHeatWave(returnKey?: boolean): string {
    return shouldReturnKey("hints.createHeatWave", returnKey, {});
  },
  createVnet: function hints_createVnet(returnKey?: boolean): string {
    return shouldReturnKey("hints.createVnet", returnKey, {});
  },
  deleteAdbsConfirmation: function hints_deleteAdbsConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deleteAdbsConfirmation", returnKey, {
      dbName
    });
  },
  deleteBackupConfirmation: function hints_deleteBackupConfirmation(
    backupName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deleteBackupConfirmation", returnKey, {
      backupName
    });
  },
  deleteExaCSDBConfirmation: function hints_deleteExaCSDBConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deleteExaCSDBConfirmation", returnKey, {
      dbName
    });
  },
  deleteHeatWave: function hints_deleteHeatWave(returnKey?: boolean): string {
    return shouldReturnKey("hints.deleteHeatWave", returnKey, {});
  },
  deleteMcVcnConfirmation: function hints_deleteMcVcnConfirmation(
    mcVcn: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deleteMcVcnConfirmation", returnKey, {
      mcVcn
    });
  },
  deleteMysqlConfirmation: function hints_deleteMysqlConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deleteMysqlConfirmation", returnKey, {
      dbName
    });
  },
  deletePluggableDBConfirmation: function hints_deletePluggableDBConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deletePluggableDBConfirmation", returnKey, {
      dbName
    });
  },
  deleteVMClusterConfirmation: function hints_deleteVMClusterConfirmation(
    clusterName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deleteVMClusterConfirmation", returnKey, {
      clusterName
    });
  },
  deleteVMDbConfirmation: function hints_deleteVMDbConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deleteVMDbConfirmation", returnKey, {
      dbName
    });
  },
  deleteVMDbSystemConfirmation: function hints_deleteVMDbSystemConfirmation(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.deleteVMDbSystemConfirmation", returnKey, {
      name
    });
  },
  detachVirtualNetworkConfirmation: function hints_detachVirtualNetworkConfirmation(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.detachVirtualNetworkConfirmation",
      returnKey,
      { name }
    );
  },
  disabledAddCapacityAction: function hints_disabledAddCapacityAction(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.disabledAddCapacityAction", returnKey, {});
  },
  downloadKey: function hints_downloadKey(returnKey?: boolean): string {
    return shouldReturnKey("hints.downloadKey", returnKey, {});
  },
  editNetworkMcvcnCreate: function hints_editNetworkMcvcnCreate(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.editNetworkMcvcnCreate", returnKey, {});
  },
  enablePitrPolicy: function hints_enablePitrPolicy(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.enablePitrPolicy", returnKey, {});
  },
  exaDbBackupDisabledDeleteAction: function hints_exaDbBackupDisabledDeleteAction(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.exaDbBackupDisabledDeleteAction",
      returnKey,
      {}
    );
  },
  ipsAndOrCidrs: function hints_ipsAndOrCidrs(returnKey?: boolean): string {
    return shouldReturnKey("hints.ipsAndOrCidrs", returnKey, {});
  },
  licenseIncluded: function hints_licenseIncluded(returnKey?: boolean): string {
    return shouldReturnKey("hints.licenseIncluded", returnKey, {});
  },
  mcvcnDeleteInfo: function hints_mcvcnDeleteInfo(returnKey?: boolean): string {
    return shouldReturnKey("hints.mcvcnDeleteInfo", returnKey, {});
  },
  networkPeering: function hints_networkPeering(returnKey?: boolean): string {
    return shouldReturnKey("hints.networkPeering", returnKey, {});
  },
  noHeatWave: function hints_noHeatWave(returnKey?: boolean): string {
    return shouldReturnKey("hints.noHeatWave", returnKey, {});
  },
  noResourcesMatch: function hints_noResourcesMatch(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.noResourcesMatch", returnKey, {});
  },
  autoScaling: function hints_autoScaling(returnKey?: boolean): string {
    return shouldReturnKey("hints.autoScaling", returnKey, {});
  },
  oracleDbEE: function hints_oracleDbEE(
    url: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.oracleDbEE", returnKey, { url });
  },
  oracleDbSE: function hints_oracleDbSE(
    url: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.oracleDbSE", returnKey, { url });
  },
  ecpuOracleDbEE: function hints_ecpuOracleDbEE(
    url: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.ecpuOracleDbEE", returnKey, { url });
  },
  ecpuOracleDbSE: function hints_ecpuOracleDbSE(
    url: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.ecpuOracleDbSE", returnKey, { url });
  },
  adbsECPUFAQ: function hints_adbsECPUFAQ(
    url: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.adbsECPUFAQ", returnKey, { url });
  },
  pasteToUpload: function hints_pasteToUpload(returnKey?: boolean): string {
    return shouldReturnKey("hints.pasteToUpload", returnKey, {});
  },
  pdbDisabledActionCdbStatus: function hints_pdbDisabledActionCdbStatus(
    pdbAction: string,
    cdbStatus: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.pdbDisabledActionCdbStatus", returnKey, {
      pdbAction,
      cdbStatus
    });
  },
  pdbDisabledActionPdbStatus: function hints_pdbDisabledActionPdbStatus(
    pdbAction: string,
    pdbStatus: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.pdbDisabledActionPdbStatus", returnKey, {
      pdbAction,
      pdbStatus
    });
  },
  pdbDisabledActionsCdbStatus: function hints_pdbDisabledActionsCdbStatus(
    pdbActions: string,
    cdbStatus: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.pdbDisabledActionsCdbStatus", returnKey, {
      pdbActions,
      cdbStatus
    });
  },
  pdbDisabledActionsPdbStatus: function hints_pdbDisabledActionsPdbStatus(
    pdbActions: string,
    pdbStatus: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.pdbDisabledActionsPdbStatus", returnKey, {
      pdbActions,
      pdbStatus
    });
  },
  regionAddNotAvailable: function hints_regionAddNotAvailable(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.regionAddNotAvailable", returnKey, {});
  },
  regionAddOciTenant: function hints_regionAddOciTenant(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.regionAddOciTenant", returnKey, {});
  },
  regionNotAdded: function hints_regionNotAdded(returnKey?: boolean): string {
    return shouldReturnKey("hints.regionNotAdded", returnKey, {});
  },
  requiretMTLSAuthentication: function hints_requiretMTLSAuthentication(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.requiretMTLSAuthentication", returnKey, {});
  },
  restartAdbsConfirmation: function hints_restartAdbsConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.restartAdbsConfirmation", returnKey, {
      dbName
    });
  },
  restartHeatWaveClusterConfirmation: function hints_restartHeatWaveClusterConfirmation(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.restartHeatWaveClusterConfirmation",
      returnKey,
      { name }
    );
  },
  restartMysqlConfirmation: function hints_restartMysqlConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.restartMysqlConfirmation", returnKey, {
      dbName
    });
  },
  restoreBackupConfirmation: function hints_restoreBackupConfirmation(
    backupName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.restoreBackupConfirmation", returnKey, {
      backupName
    });
  },
  rotateWalletAdbsConfirmation: function hints_rotateWalletAdbsConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.rotateWalletAdbsConfirmation", returnKey, {
      dbName
    });
  },
  routeVNET: function hints_routeVNET(returnKey?: boolean): string {
    return shouldReturnKey("hints.routeVNET", returnKey, {});
  },
  scaleExaInfra: function hints_scaleExaInfra(returnKey?: boolean): string {
    return shouldReturnKey("hints.scaleExaInfra", returnKey, {});
  },
  selectAExadataBase: function hints_selectAExadataBase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.selectAExadataBase", returnKey, {});
  },
  selectAHeatwaveShape: function hints_selectAHeatwaveShape(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.selectAHeatwaveShape", returnKey, {});
  },
  selectAMySQLVersion: function hints_selectAMySQLVersion(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.selectAMySQLVersion", returnKey, {});
  },
  selectASystemModelShape: function hints_selectASystemModelShape(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.selectASystemModelShape", returnKey, {});
  },
  selectAvailableDataStorage: function hints_selectAvailableDataStorage(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.selectAvailableDataStorage", returnKey, {});
  },
  selectOcpuCountChangeToFlex: function hints_selectOcpuCountChangeToFlex(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.selectOcpuCountChangeToFlex", returnKey, {});
  },
  selectOracleGridInfrastructureVersion: function hints_selectOracleGridInfrastructureVersion(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.selectOracleGridInfrastructureVersion",
      returnKey,
      {}
    );
  },
  selectVMShape: function hints_selectVMShape(returnKey?: boolean): string {
    return shouldReturnKey("hints.selectVMShape", returnKey, {});
  },
  shapeUnavailable: function hints_shapeUnavailable(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.shapeUnavailable", returnKey, {});
  },
  startAdbsConfirmation: function hints_startAdbsConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.startAdbsConfirmation", returnKey, {
      dbName
    });
  },
  startExaCSDBConfirmation: function hints_startExaCSDBConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.startExaCSDBConfirmation", returnKey, {
      dbName
    });
  },
  startHeatWaveClusterConfirmation: function hints_startHeatWaveClusterConfirmation(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.startHeatWaveClusterConfirmation",
      returnKey,
      { name }
    );
  },
  startMysqlConfirmation: function hints_startMysqlConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.startMysqlConfirmation", returnKey, {
      dbName
    });
  },
  startVmDBConfirmation: function hints_startVmDBConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.startVmDBConfirmation", returnKey, {
      dbName
    });
  },
  stopAdbsConfirmation: function hints_stopAdbsConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.stopAdbsConfirmation", returnKey, { dbName });
  },
  stopExaCSDBConfirmation: function hints_stopExaCSDBConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.stopExaCSDBConfirmation", returnKey, {
      dbName
    });
  },
  stopHeatWaveClusterConfirmation: function hints_stopHeatWaveClusterConfirmation(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.stopHeatWaveClusterConfirmation", returnKey, {
      name
    });
  },
  stopMysqlConfirmation: function hints_stopMysqlConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.stopMysqlConfirmation", returnKey, {
      dbName
    });
  },
  stopVmDBConfirmation: function hints_stopVmDBConfirmation(
    dbName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.stopVmDBConfirmation", returnKey, { dbName });
  },
  tagsNoChanges: function hints_tagsNoChanges(returnKey?: boolean): string {
    return shouldReturnKey("hints.tagsNoChanges", returnKey, {});
  },
  tagsToAdd: function hints_tagsToAdd(
    count: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tagsToAdd", returnKey, { count });
  },
  tagsToDelete: function hints_tagsToDelete(
    count: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tagsToDelete", returnKey, { count });
  },
  tagsTooltipAdding: function hints_tagsTooltipAdding(
    resources: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tagsTooltipAdding", returnKey, { resources });
  },
  tagsTooltipDeleting: function hints_tagsTooltipDeleting(
    resources: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tagsTooltipDeleting", returnKey, {
      resources
    });
  },
  tagsTooltipUpdating: function hints_tagsTooltipUpdating(
    resources: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tagsTooltipUpdating", returnKey, {
      resources
    });
  },
  tagsToUpdate: function hints_tagsToUpdate(
    count: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tagsToUpdate", returnKey, { count });
  },
  tdeWalletPasswordInfo: function hints_tdeWalletPasswordInfo(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tdeWalletPasswordInfo", returnKey, {});
  },
  tooltipAdminPassword: function hints_tooltipAdminPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipAdminPassword", returnKey, {});
  },
  toolTipCidr: function hints_toolTipCidr(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipCidr", returnKey, { link });
  },
  tooltipFilePrivateInfo: function hints_tooltipFilePrivateInfo(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipFilePrivateInfo", returnKey, { link });
  },
  tooltipMySQLAdminPassword: function hints_tooltipMySQLAdminPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipMySQLAdminPassword", returnKey, {});
  },
  tooltipMysqlAdminPassword: function hints_tooltipMysqlAdminPassword(
    minValue: string,
    maxValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipMysqlAdminPassword", returnKey, {
      minValue,
      maxValue
    });
  },
  tooltipMySQLBackup: function hints_tooltipMySQLBackup(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipMySQLBackup", returnKey, {});
  },
  tooltipMySQLBackupRetentionPeriod: function hints_tooltipMySQLBackupRetentionPeriod(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.tooltipMySQLBackupRetentionPeriod",
      returnKey,
      {}
    );
  },
  tooltipMySQLCrashRecovery: function hints_tooltipMySQLCrashRecovery(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipMySQLCrashRecovery", returnKey, {});
  },
  toolTipMySQLDatabaseDescription: function hints_toolTipMySQLDatabaseDescription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.toolTipMySQLDatabaseDescription",
      returnKey,
      {}
    );
  },
  toolTipMySQLDatabaseSystem: function hints_toolTipMySQLDatabaseSystem(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipMySQLDatabaseSystem", returnKey, {});
  },
  toolTipMySQLDatabaseSystemName: function hints_toolTipMySQLDatabaseSystemName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.toolTipMySQLDatabaseSystemName",
      returnKey,
      {}
    );
  },
  toolTipMySQLDataStorageSize: function hints_toolTipMySQLDataStorageSize(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipMySQLDataStorageSize", returnKey, {});
  },
  tooltipMySQLDeleteProtected: function hints_tooltipMySQLDeleteProtected(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipMySQLDeleteProtected", returnKey, {});
  },
  tooltipMySQLEnableAutomaticBackups: function hints_tooltipMySQLEnableAutomaticBackups(
    pitrPolicy: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.tooltipMySQLEnableAutomaticBackups",
      returnKey,
      { pitrPolicy }
    );
  },
  toolTipMySQLHostName: function hints_toolTipMySQLHostName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipMySQLHostName", returnKey, {});
  },
  toolTipMySQLIpAddress: function hints_toolTipMySQLIpAddress(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipMySQLIpAddress", returnKey, { link });
  },
  tooltipMySQLRequireFinalBackup: function hints_tooltipMySQLRequireFinalBackup(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.tooltipMySQLRequireFinalBackup",
      returnKey,
      {}
    );
  },
  tooltipMySQLRetainAutomaticBackups: function hints_tooltipMySQLRetainAutomaticBackups(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.tooltipMySQLRetainAutomaticBackups",
      returnKey,
      {}
    );
  },
  toolTipMySQLUserName: function hints_toolTipMySQLUserName(
    min: string,
    max: string,
    characters: string,
    reservedNames: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipMySQLUserName", returnKey, {
      min,
      max,
      characters,
      reservedNames
    });
  },
  tooltipMySQLWindowStartTime: function hints_tooltipMySQLWindowStartTime(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipMySQLWindowStartTime", returnKey, {});
  },
  tooltipNetworkVirtualAppliance: function hints_tooltipNetworkVirtualAppliance(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.tooltipNetworkVirtualAppliance",
      returnKey,
      {}
    );
  },
  tooltipPasswordAdb: function hints_tooltipPasswordAdb(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipPasswordAdb", returnKey, {});
  },
  tooltipPluggableDatabase: function hints_tooltipPluggableDatabase(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipPluggableDatabase", returnKey, {
      link
    });
  },
  toolTipRegion: function hints_toolTipRegion(returnKey?: boolean): string {
    return shouldReturnKey("hints.toolTipRegion", returnKey, {});
  },
  toolTipResourceGroup: function hints_toolTipResourceGroup(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipResourceGroup", returnKey, {});
  },
  toolTipRestoreAdbsTime: function hints_toolTipRestoreAdbsTime(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipRestoreAdbsTime", returnKey, {});
  },
  toolTipSelectOcpuCount: function hints_toolTipSelectOcpuCount(
    minValue: string,
    maxValue: string,
    stepValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipSelectOcpuCount", returnKey, {
      minValue,
      maxValue,
      stepValue
    });
  },
  toolTipSubscription: function hints_toolTipSubscription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.toolTipSubscription", returnKey, {});
  },
  tooltipTagName: function hints_tooltipTagName(returnKey?: boolean): string {
    return shouldReturnKey("hints.tooltipTagName", returnKey, {});
  },
  tooltipTagValue: function hints_tooltipTagValue(returnKey?: boolean): string {
    return shouldReturnKey("hints.tooltipTagValue", returnKey, {});
  },
  tooltipTdePassword: function hints_tooltipTdePassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipTdePassword", returnKey, {});
  },
  tooltipVNET: function hints_tooltipVNET(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipVNET", returnKey, { link });
  },
  tooltipWalletPassword: function hints_tooltipWalletPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.tooltipWalletPassword", returnKey, {});
  },
  unlockMyPDBAdminAccount: function hints_unlockMyPDBAdminAccount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.unlockMyPDBAdminAccount", returnKey, {});
  },
  useDifferentTDEPassword: function hints_useDifferentTDEPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.useDifferentTDEPassword", returnKey, {});
  },
  vmClusterAvailableCapacity: function hints_vmClusterAvailableCapacity(
    nodeCount: string,
    storageSize: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.vmClusterAvailableCapacity", returnKey, {
      nodeCount,
      storageSize
    });
  },
  vmClusterAvailableCapacityForNodeCount: function hints_vmClusterAvailableCapacityForNodeCount(
    nodeCount: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.vmClusterAvailableCapacityForNodeCount",
      returnKey,
      { nodeCount }
    );
  },
  vmClusterAvailableCapacityForStorage: function hints_vmClusterAvailableCapacityForStorage(
    storageSize: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "hints.vmClusterAvailableCapacityForStorage",
      returnKey,
      { storageSize }
    );
  },
  vnetDeleteInfo: function hints_vnetDeleteInfo(returnKey?: boolean): string {
    return shouldReturnKey("hints.vnetDeleteInfo", returnKey, {});
  }
};

export const help = {
  documentation: {
    contactSupportCommunityHelp: function help_documentation_contactSupportCommunityHelp(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "help.documentation.contactSupportCommunityHelp",
        returnKey,
        {}
      );
    },
    description: function help_documentation_description(
      link: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("help.documentation.description", returnKey, {
        link
      });
    },
    title: function help_documentation_title(returnKey?: boolean): string {
      return shouldReturnKey("help.documentation.title", returnKey, {});
    }
  },
  needMoreHelp: {
    description: function help_needMoreHelp_description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("help.needMoreHelp.description", returnKey, {});
    },
    title: function help_needMoreHelp_title(returnKey?: boolean): string {
      return shouldReturnKey("help.needMoreHelp.title", returnKey, {});
    }
  },
  title: function help_title(returnKey?: boolean): string {
    return shouldReturnKey("help.title", returnKey, {});
  }
};

export const home = {
  banner: {
    noAccessToLinkedSubscriptions: function home_banner_noAccessToLinkedSubscriptions(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "home.banner.noAccessToLinkedSubscriptions",
        returnKey,
        { shortBrand }
      );
    }
  },
  gettingStarted: {
    step1Description: function home_gettingStarted_step1Description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "home.gettingStarted.step1Description",
        returnKey,
        {}
      );
    },
    step1Title: function home_gettingStarted_step1Title(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.gettingStarted.step1Title", returnKey, {
        shortBrand
      });
    },
    step2Description: function home_gettingStarted_step2Description(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "home.gettingStarted.step2Description",
        returnKey,
        {}
      );
    },
    step2Title: function home_gettingStarted_step2Title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.gettingStarted.step2Title", returnKey, {});
    }
  },
  gettingStartedNewUser: {
    description: function home_gettingStartedNewUser_description(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "home.gettingStartedNewUser.description",
        returnKey,
        { shortBrand }
      );
    },
    importantNote: function home_gettingStartedNewUser_importantNote(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "home.gettingStartedNewUser.importantNote",
        returnKey,
        { shortBrand }
      );
    },
    title: function home_gettingStartedNewUser_title(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.gettingStartedNewUser.title", returnKey, {
        shortBrand
      });
    }
  },
  links: {
    gettingStartedADB: function home_links_gettingStartedADB(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.links.gettingStartedADB", returnKey, {});
    },
    gettingStartedExadata: function home_links_gettingStartedExadata(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.links.gettingStartedExadata", returnKey, {});
    },
    gettingStartedMySQL: function home_links_gettingStartedMySQL(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.links.gettingStartedMySQL", returnKey, {});
    },
    gettingStartedProvisioning: function home_links_gettingStartedProvisioning(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "home.links.gettingStartedProvisioning",
        returnKey,
        { shortBrand }
      );
    },
    gettingStartedVMDB: function home_links_gettingStartedVMDB(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.links.gettingStartedVMDB", returnKey, {});
    }
  },
  sections: {
    gettingStarted: function home_sections_gettingStarted(
      longBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.sections.gettingStarted", returnKey, {
        longBrand
      });
    },
    navigate: function home_sections_navigate(returnKey?: boolean): string {
      return shouldReturnKey("home.sections.navigate", returnKey, {});
    },
    oracleService: function home_sections_oracleService(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.sections.oracleService", returnKey, {});
    },
    usefulLinks: function home_sections_usefulLinks(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("home.sections.usefulLinks", returnKey, {});
    }
  }
};

export const inactivitySignOut = {
  confirmation: {
    buttonCancel: function inactivitySignOut_confirmation_buttonCancel(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "inactivitySignOut.confirmation.buttonCancel",
        returnKey,
        {}
      );
    },
    buttonConfirm: function inactivitySignOut_confirmation_buttonConfirm(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "inactivitySignOut.confirmation.buttonConfirm",
        returnKey,
        {}
      );
    },
    message: function inactivitySignOut_confirmation_message(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "inactivitySignOut.confirmation.message",
        returnKey,
        {}
      );
    },
    title: function inactivitySignOut_confirmation_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "inactivitySignOut.confirmation.title",
        returnKey,
        {}
      );
    }
  },
  settings: {
    custom: function inactivitySignOut_settings_custom(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "inactivitySignOut.settings.custom",
        returnKey,
        {}
      );
    },
    min15: function inactivitySignOut_settings_min15(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("inactivitySignOut.settings.min15", returnKey, {});
    },
    min30: function inactivitySignOut_settings_min30(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("inactivitySignOut.settings.min30", returnKey, {});
    },
    min45: function inactivitySignOut_settings_min45(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("inactivitySignOut.settings.min45", returnKey, {});
    },
    min60: function inactivitySignOut_settings_min60(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("inactivitySignOut.settings.min60", returnKey, {});
    },
    min90: function inactivitySignOut_settings_min90(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("inactivitySignOut.settings.min90", returnKey, {});
    },
    min120: function inactivitySignOut_settings_min120(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "inactivitySignOut.settings.min120",
        returnKey,
        {}
      );
    },
    never: function inactivitySignOut_settings_never(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("inactivitySignOut.settings.never", returnKey, {});
    }
  }
};

export const instructions = {
  assignRoles: {
    azureLinkLabel: function instructions_assignRoles_azureLinkLabel(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.assignRoles.azureLinkLabel",
        returnKey,
        {}
      );
    },
    importantNote: function instructions_assignRoles_importantNote(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.assignRoles.importantNote",
        returnKey,
        { shortBrand }
      );
    },
    title: function instructions_assignRoles_title(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.title", returnKey, {
        shortBrand
      });
    },
    step1: function instructions_assignRoles_step1(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step1", returnKey, {});
    },
    step2: function instructions_assignRoles_step2(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step2", returnKey, {});
    },
    step3: function instructions_assignRoles_step3(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step3", returnKey, {});
    },
    step3b: function instructions_assignRoles_step3b(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step3b", returnKey, {});
    },
    step4: function instructions_assignRoles_step4(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step4", returnKey, {});
    },
    step5: function instructions_assignRoles_step5(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step5", returnKey, {});
    },
    step6: function instructions_assignRoles_step6(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step6", returnKey, {});
    },
    step7: function instructions_assignRoles_step7(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step7", returnKey, {});
    },
    step8: function instructions_assignRoles_step8(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step8", returnKey, {});
    },
    step9: function instructions_assignRoles_step9(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step9", returnKey, {});
    },
    step10: function instructions_assignRoles_step10(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step10", returnKey, {});
    },
    step11: function instructions_assignRoles_step11(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step11", returnKey, {});
    },
    step12: function instructions_assignRoles_step12(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step12", returnKey, {});
    },
    step13: function instructions_assignRoles_step13(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step13", returnKey, {});
    },
    step14: function instructions_assignRoles_step14(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step14", returnKey, {});
    },
    step15: function instructions_assignRoles_step15(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step15", returnKey, {});
    },
    step16: function instructions_assignRoles_step16(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step16", returnKey, {});
    },
    step17: function instructions_assignRoles_step17(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step17", returnKey, {});
    },
    step18: function instructions_assignRoles_step18(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step18", returnKey, {});
    },
    step19: function instructions_assignRoles_step19(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step19", returnKey, {});
    },
    step20: function instructions_assignRoles_step20(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step20", returnKey, {});
    },
    step21: function instructions_assignRoles_step21(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("instructions.assignRoles.step21", returnKey, {});
    }
  },
  linkSubscriptions: {
    azureLinkLabel: function instructions_linkSubscriptions_azureLinkLabel(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.azureLinkLabel",
        returnKey,
        {}
      );
    },
    title: function instructions_linkSubscriptions_title(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.title",
        returnKey,
        { shortBrand }
      );
    },
    step1: function instructions_linkSubscriptions_step1(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step1",
        returnKey,
        {}
      );
    },
    step2: function instructions_linkSubscriptions_step2(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step2",
        returnKey,
        {}
      );
    },
    step3: function instructions_linkSubscriptions_step3(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step3",
        returnKey,
        {}
      );
    },
    step4: function instructions_linkSubscriptions_step4(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step4",
        returnKey,
        {}
      );
    },
    step5: function instructions_linkSubscriptions_step5(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step5",
        returnKey,
        {}
      );
    },
    step6: function instructions_linkSubscriptions_step6(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step6",
        returnKey,
        {}
      );
    },
    step7: function instructions_linkSubscriptions_step7(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step7",
        returnKey,
        {}
      );
    },
    step8: function instructions_linkSubscriptions_step8(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step8",
        returnKey,
        {}
      );
    },
    step9: function instructions_linkSubscriptions_step9(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step9",
        returnKey,
        {}
      );
    },
    step10: function instructions_linkSubscriptions_step10(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step10",
        returnKey,
        {}
      );
    },
    step11: function instructions_linkSubscriptions_step11(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step11",
        returnKey,
        {}
      );
    },
    step12: function instructions_linkSubscriptions_step12(
      shortBrand: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "instructions.linkSubscriptions.step12",
        returnKey,
        { shortBrand }
      );
    }
  }
};

export const labels = {
  additionalNodeCount: function labels_additionalNodeCount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.additionalNodeCount", returnKey, {});
  },
  additionalStorageInGB: function labels_additionalStorageInGB(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.additionalStorageInGB", returnKey, {});
  },
  accessFromAllowedIPs: function labels_accessFromAllowedIPs(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.accessFromAllowedIPs", returnKey, {});
  },
  accessFromAzurePrivateEndpoint: function labels_accessFromAzurePrivateEndpoint(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "labels.accessFromAzurePrivateEndpoint",
      returnKey,
      {}
    );
  },
  accessFromAzurePrivateEndpointPeering: function labels_accessFromAzurePrivateEndpointPeering(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "labels.accessFromAzurePrivateEndpointPeering",
      returnKey,
      {}
    );
  },
  accessFromEverywhere: function labels_accessFromEverywhere(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.accessFromEverywhere", returnKey, {});
  },
  accessType: function labels_accessType(returnKey?: boolean): string {
    return shouldReturnKey("labels.accessType", returnKey, {});
  },
  activity: function labels_activity(returnKey?: boolean): string {
    return shouldReturnKey("labels.activity", returnKey, {});
  },
  address: function labels_address(returnKey?: boolean): string {
    return shouldReturnKey("labels.address", returnKey, {});
  },
  addresses: function labels_addresses(returnKey?: boolean): string {
    return shouldReturnKey("labels.addresses", returnKey, {});
  },
  addressRange: function labels_addressRange(returnKey?: boolean): string {
    return shouldReturnKey("labels.addressRange", returnKey, {});
  },
  allocateStorageLocal: function labels_allocateStorageLocal(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.allocateStorageLocal", returnKey, {});
  },
  allocateStorageSparse: function labels_allocateStorageSparse(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.allocateStorageSparse", returnKey, {});
  },
  apex: function labels_apex(returnKey?: boolean): string {
    return shouldReturnKey("labels.apex", returnKey, {});
  },
  appearance: function labels_appearance(returnKey?: boolean): string {
    return shouldReturnKey("labels.appearance", returnKey, {});
  },
  attachedDatabases: function labels_attachedDatabases(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.attachedDatabases", returnKey, {});
  },
  automaticBackups: function labels_automaticBackups(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.automaticBackups", returnKey, {});
  },
  autonomousDatabase: function labels_autonomousDatabase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.autonomousDatabase", returnKey, {});
  },
  autonomousDatabaseDedicated: function labels_autonomousDatabaseDedicated(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.autonomousDatabaseDedicated", returnKey, {});
  },
  availableDataStorage: function labels_availableDataStorage(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.availableDataStorage", returnKey, {});
  },
  availableDataStorageGB: function labels_availableDataStorageGB(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.availableDataStorageGB", returnKey, {});
  },
  backup: function labels_backup(returnKey?: boolean): string {
    return shouldReturnKey("labels.backup", returnKey, {});
  },
  backupPlan: function labels_backupPlan(returnKey?: boolean): string {
    return shouldReturnKey("labels.backupPlan", returnKey, {});
  },
  backupRestoreLatest: function labels_backupRestoreLatest(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.backupRestoreLatest", returnKey, {});
  },
  backupRestoreSCN: function labels_backupRestoreSCN(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.backupRestoreSCN", returnKey, {});
  },
  backupRestoreTimestamp: function labels_backupRestoreTimestamp(
    timezoneName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.backupRestoreTimestamp", returnKey, {
      timezoneName
    });
  },
  backupRetentionPeriod: function labels_backupRetentionPeriod(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.backupRetentionPeriod", returnKey, {});
  },
  backups: function labels_backups(returnKey?: boolean): string {
    return shouldReturnKey("labels.backups", returnKey, {});
  },
  backupScheduling: function labels_backupScheduling(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.backupScheduling", returnKey, {});
  },
  backupSizeInGB: function labels_backupSizeInGB(returnKey?: boolean): string {
    return shouldReturnKey("labels.backupSizeInGB", returnKey, {});
  },
  backupType: function labels_backupType(returnKey?: boolean): string {
    return shouldReturnKey("labels.backupType", returnKey, {});
  },
  byol: function labels_byol(returnKey?: boolean): string {
    return shouldReturnKey("labels.byol", returnKey, {});
  },
  byolEE: function labels_byolEE(returnKey?: boolean): string {
    return shouldReturnKey("labels.byolEE", returnKey, {});
  },
  byolSE: function labels_byolSE(returnKey?: boolean): string {
    return shouldReturnKey("labels.byolSE", returnKey, {});
  },
  categories: function labels_categories(returnKey?: boolean): string {
    return shouldReturnKey("labels.categories", returnKey, {});
  },
  characterSet: function labels_characterSet(returnKey?: boolean): string {
    return shouldReturnKey("labels.characterSet", returnKey, {});
  },
  cidr: function labels_cidr(returnKey?: boolean): string {
    return shouldReturnKey("labels.cidr", returnKey, {});
  },
  cidrBlocks: function labels_cidrBlocks(returnKey?: boolean): string {
    return shouldReturnKey("labels.cidrBlocks", returnKey, {});
  },
  closeTicket: function labels_closeTicket(returnKey?: boolean): string {
    return shouldReturnKey("labels.closeTicket", returnKey, {});
  },
  comments: function labels_comments(returnKey?: boolean): string {
    return shouldReturnKey("labels.comments", returnKey, {});
  },
  completed: function labels_completed(returnKey?: boolean): string {
    return shouldReturnKey("labels.completed", returnKey, {});
  },
  configuration: function labels_configuration(returnKey?: boolean): string {
    return shouldReturnKey("labels.configuration", returnKey, {});
  },
  connect: function labels_connect(returnKey?: boolean): string {
    return shouldReturnKey("labels.connect", returnKey, {});
  },
  connectionString: function labels_connectionString(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.connectionString", returnKey, {});
  },
  connectionStrings: function labels_connectionStrings(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.connectionStrings", returnKey, {});
  },
  connectionTypeMTLS: function labels_connectionTypeMTLS(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.connectionTypeMTLS", returnKey, {});
  },
  connectionTypeTCP: function labels_connectionTypeTCP(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.connectionTypeTCP", returnKey, {});
  },
  connectionTypeTLS: function labels_connectionTypeTLS(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.connectionTypeTLS", returnKey, {});
  },
  consentToContact: function labels_consentToContact(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.consentToContact", returnKey, {});
  },
  containedVmCluster: function labels_containedVmCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.containedVmCluster", returnKey, {});
  },
  containedVmClusters: function labels_containedVmClusters(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.containedVmClusters", returnKey, {});
  },
  containerDatabases: function labels_containerDatabases(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.containerDatabases", returnKey, {});
  },
  containerDb: function labels_containerDb(returnKey?: boolean): string {
    return shouldReturnKey("labels.containerDb", returnKey, {});
  },
  coreCount: function labels_coreCount(returnKey?: boolean): string {
    return shouldReturnKey("labels.coreCount", returnKey, {});
  },
  crashRecovery: function labels_crashRecovery(returnKey?: boolean): string {
    return shouldReturnKey("labels.crashRecovery", returnKey, {});
  },
  createAResource: function labels_createAResource(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.createAResource", returnKey, {});
  },
  created: function labels_created(returnKey?: boolean): string {
    return shouldReturnKey("labels.created", returnKey, {});
  },
  createdOn: function labels_createdOn(returnKey?: boolean): string {
    return shouldReturnKey("labels.createdOn", returnKey, {});
  },
  createHeatWave: function labels_createHeatWave(returnKey?: boolean): string {
    return shouldReturnKey("labels.createHeatWave", returnKey, {});
  },
  createNewVcn: function labels_createNewVcn(returnKey?: boolean): string {
    return shouldReturnKey("labels.createNewVcn", returnKey, {});
  },
  creationType: function labels_creationType(returnKey?: boolean): string {
    return shouldReturnKey("labels.creationType", returnKey, {});
  },
  currentQuotaLimit: function labels_currentQuotaLimit(
    quota: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.currentQuotaLimit", returnKey, { quota });
  },
  currentQuotaUsed: function labels_currentQuotaUsed(
    quota: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.currentQuotaUsed", returnKey, { quota });
  },
  customDuration: function labels_customDuration(returnKey?: boolean): string {
    return shouldReturnKey("labels.customDuration", returnKey, {});
  },
  database: function labels_database(returnKey?: boolean): string {
    return shouldReturnKey("labels.database", returnKey, {});
  },
  databaseAccessEndpoint: function labels_databaseAccessEndpoint(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseAccessEndpoint", returnKey, {});
  },
  databaseBackupName: function labels_databaseBackupName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseBackupName", returnKey, {});
  },
  databaseEdition: function labels_databaseEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseEdition", returnKey, {});
  },
  databaseHome: function labels_databaseHome(returnKey?: boolean): string {
    return shouldReturnKey("labels.databaseHome", returnKey, {});
  },
  databaseHomeName: function labels_databaseHomeName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseHomeName", returnKey, {});
  },
  databaseHomeVersion: function labels_databaseHomeVersion(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseHomeVersion", returnKey, {});
  },
  databaseHomeLocation: function labels_databaseHomeLocation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseHomeLocation", returnKey, {});
  },
  databaseHomes: function labels_databaseHomes(returnKey?: boolean): string {
    return shouldReturnKey("labels.databaseHomes", returnKey, {});
  },
  databaseName: function labels_databaseName(returnKey?: boolean): string {
    return shouldReturnKey("labels.databaseName", returnKey, {});
  },
  databaseNodes: function labels_databaseNodes(returnKey?: boolean): string {
    return shouldReturnKey("labels.databaseNodes", returnKey, {});
  },
  databases: function labels_databases(returnKey?: boolean): string {
    return shouldReturnKey("labels.databases", returnKey, {});
  },
  databaseServers: function labels_databaseServers(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseServers", returnKey, {});
  },
  databaseSystem: function labels_databaseSystem(returnKey?: boolean): string {
    return shouldReturnKey("labels.databaseSystem", returnKey, {});
  },
  databaseSystems: function labels_databaseSystems(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseSystems", returnKey, {});
  },
  databaseSystemName: function labels_databaseSystemName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseSystemName", returnKey, {});
  },
  databaseUniqueName: function labels_databaseUniqueName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseUniqueName", returnKey, {});
  },
  databaseVersion: function labels_databaseVersion(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.databaseVersion", returnKey, {});
  },
  dataStorageSize: function labels_dataStorageSize(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.dataStorageSize", returnKey, {});
  },
  dbSystemShape: function labels_dbSystemShape(returnKey?: boolean): string {
    return shouldReturnKey("labels.dbSystemShape", returnKey, {});
  },
  dedicated: function labels_dedicated(returnKey?: boolean): string {
    return shouldReturnKey("labels.dedicated", returnKey, {});
  },
  defaultLocation: function labels_defaultLocation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.defaultLocation", returnKey, {});
  },
  defaultSubscription: function labels_defaultSubscription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.defaultSubscription", returnKey, {});
  },
  deleteAdbs: function labels_deleteAdbs(returnKey?: boolean): string {
    return shouldReturnKey("labels.deleteAdbs", returnKey, {});
  },
  deleteBackup: function labels_deleteBackup(returnKey?: boolean): string {
    return shouldReturnKey("labels.deleteBackup", returnKey, {});
  },
  deleteExaCSDB: function labels_deleteExaCSDB(returnKey?: boolean): string {
    return shouldReturnKey("labels.deleteExaCSDB", returnKey, {});
  },
  deleteExaInfra: function labels_deleteExaInfra(returnKey?: boolean): string {
    return shouldReturnKey("labels.deleteExaInfra", returnKey, {});
  },
  deleteHeatWave: function labels_deleteHeatWave(returnKey?: boolean): string {
    return shouldReturnKey("labels.deleteHeatWave", returnKey, {});
  },
  deletionPlan: function labels_deletionPlan(returnKey?: boolean): string {
    return shouldReturnKey("labels.deletionPlan", returnKey, {});
  },
  deleteMcVcn: function labels_deleteMcVcn(returnKey?: boolean): string {
    return shouldReturnKey("labels.deleteMcVcn", returnKey, {});
  },
  deleteMysql: function labels_deleteMysql(returnKey?: boolean): string {
    return shouldReturnKey("labels.deleteMysql", returnKey, {});
  },
  deleteVMCluster: function labels_deleteVMCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.deleteVMCluster", returnKey, {});
  },
  deleteVMDb: function labels_deleteVMDb(returnKey?: boolean): string {
    return shouldReturnKey("labels.deleteVMDb", returnKey, {});
  },
  deploymentId: function labels_deploymentId(returnKey?: boolean): string {
    return shouldReturnKey("labels.deploymentId", returnKey, {});
  },
  deploymentName: function labels_deploymentName(returnKey?: boolean): string {
    return shouldReturnKey("labels.deploymentName", returnKey, {});
  },
  description: function labels_description(returnKey?: boolean): string {
    return shouldReturnKey("labels.description", returnKey, {});
  },
  detachVirtualNetwork: function labels_detachVirtualNetwork(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.detachVirtualNetwork", returnKey, {});
  },
  docked: function labels_docked(returnKey?: boolean): string {
    return shouldReturnKey("labels.docked", returnKey, {});
  },
  duration: function labels_duration(returnKey?: boolean): string {
    return shouldReturnKey("labels.duration", returnKey, {});
  },
  editTags: function labels_editTags(returnKey?: boolean): string {
    return shouldReturnKey("labels.editTags", returnKey, {});
  },
  email: function labels_email(returnKey?: boolean): string {
    return shouldReturnKey("labels.email", returnKey, {});
  },
  enableAutomaticBackups: function labels_enableAutomaticBackups(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.enableAutomaticBackups", returnKey, {});
  },
  enableAutoScaling: function labels_enableAutoScaling(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.enableAutoScaling", returnKey, {});
  },
  ended: function labels_ended(returnKey?: boolean): string {
    return shouldReturnKey("labels.ended", returnKey, {});
  },
  enterTimestamp: function labels_enterTimestamp(
    timezoneName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.enterTimestamp", returnKey, {
      timezoneName
    });
  },
  errorDetails: function labels_errorDetails(returnKey?: boolean): string {
    return shouldReturnKey("labels.errorDetails", returnKey, {});
  },
  errorMessage: function labels_errorMessage(returnKey?: boolean): string {
    return shouldReturnKey("labels.errorMessage", returnKey, {});
  },
  errors: function labels_errors(returnKey?: boolean): string {
    return shouldReturnKey("labels.errors", returnKey, {});
  },
  exaCS: function labels_exaCS(returnKey?: boolean): string {
    return shouldReturnKey("labels.exaCS", returnKey, {});
  },
  exadataDatabase: function labels_exadataDatabase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.exadataDatabase", returnKey, {});
  },
  exadataDatabaseName: function labels_exadataDatabaseName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.exadataDatabaseName", returnKey, {});
  },
  exadataInfra: function labels_exadataInfra(returnKey?: boolean): string {
    return shouldReturnKey("labels.exadataInfra", returnKey, {});
  },
  exadataInfraName: function labels_exadataInfraName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.exadataInfraName", returnKey, {});
  },
  exadataSystem: function labels_exadataSystem(returnKey?: boolean): string {
    return shouldReturnKey("labels.exadataSystem", returnKey, {});
  },
  exadataSystemModel: function labels_exadataSystemModel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.exadataSystemModel", returnKey, {});
  },
  exadataSystemName: function labels_exadataSystemName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.exadataSystemName", returnKey, {});
  },
  favorite: function labels_favorite(returnKey?: boolean): string {
    return shouldReturnKey("labels.favorite", returnKey, {});
  },
  feedbackPlaceholder: function labels_feedbackPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.feedbackPlaceholder", returnKey, {});
  },
  feedbackSatisfaction: function labels_feedbackSatisfaction(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.feedbackSatisfaction", returnKey, {});
  },
  file: function labels_file(returnKey?: boolean): string {
    return shouldReturnKey("labels.file", returnKey, {});
  },
  filePrivateInfo: function labels_filePrivateInfo(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.filePrivateInfo", returnKey, {});
  },
  flyout: function labels_flyout(returnKey?: boolean): string {
    return shouldReturnKey("labels.flyout", returnKey, {});
  },
  full: function labels_full(returnKey?: boolean): string {
    return shouldReturnKey("labels.full", returnKey, {});
  },
  giVersion: function labels_giVersion(returnKey?: boolean): string {
    return shouldReturnKey("labels.giVersion", returnKey, {});
  },
  heatwave: function labels_heatwave(returnKey?: boolean): string {
    return shouldReturnKey("labels.heatwave", returnKey, {});
  },
  heatwaveCluster: function labels_heatwaveCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.heatwaveCluster", returnKey, {});
  },
  heatwaveClusterStatus: function labels_heatwaveClusterStatus(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.heatwaveClusterStatus", returnKey, {});
  },
  hostIp: function labels_hostIp(returnKey?: boolean): string {
    return shouldReturnKey("labels.hostIp", returnKey, {});
  },
  hostName: function labels_hostName(returnKey?: boolean): string {
    return shouldReturnKey("labels.hostName", returnKey, {});
  },
  hostNameOnly: function labels_hostNameOnly(returnKey?: boolean): string {
    return shouldReturnKey("labels.hostNameOnly", returnKey, {});
  },
  id: function labels_id(returnKey?: boolean): string {
    return shouldReturnKey("labels.id", returnKey, {});
  },
  infra: function labels_infra(returnKey?: boolean): string {
    return shouldReturnKey("labels.infra", returnKey, {});
  },
  infras: function labels_infras(returnKey?: boolean): string {
    return shouldReturnKey("labels.infras", returnKey, {});
  },
  incremental: function labels_incremental(returnKey?: boolean): string {
    return shouldReturnKey("labels.incremental", returnKey, {});
  },
  internalFQDN: function labels_internalFQDN(returnKey?: boolean): string {
    return shouldReturnKey("labels.internalFQDN", returnKey, {});
  },
  ipAddress: function labels_ipAddress(returnKey?: boolean): string {
    return shouldReturnKey("labels.ipAddress", returnKey, {});
  },
  ipsAndOrCidrs: function labels_ipsAndOrCidrs(returnKey?: boolean): string {
    return shouldReturnKey("labels.ipsAndOrCidrs", returnKey, {});
  },
  issueType: function labels_issueType(returnKey?: boolean): string {
    return shouldReturnKey("labels.issueType", returnKey, {});
  },
  language: function labels_language(returnKey?: boolean): string {
    return shouldReturnKey("labels.language", returnKey, {});
  },
  lastAccessed: function labels_lastAccessed(returnKey?: boolean): string {
    return shouldReturnKey("labels.lastAccessed", returnKey, {});
  },
  lastViewed: function labels_lastViewed(returnKey?: boolean): string {
    return shouldReturnKey("labels.lastViewed", returnKey, {});
  },
  license: function labels_license(returnKey?: boolean): string {
    return shouldReturnKey("labels.license", returnKey, {});
  },
  licenseIncluded: function labels_licenseIncluded(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.licenseIncluded", returnKey, {});
  },
  licenseType: function labels_licenseType(returnKey?: boolean): string {
    return shouldReturnKey("labels.licenseType", returnKey, {});
  },
  listenerPort: function labels_listenerPort(returnKey?: boolean): string {
    return shouldReturnKey("labels.listenerPort", returnKey, {});
  },
  location: function labels_location(returnKey?: boolean): string {
    return shouldReturnKey("labels.location", returnKey, {});
  },
  locations: function labels_locations(returnKey?: boolean): string {
    return shouldReturnKey("labels.locations", returnKey, {});
  },
  management: function labels_management(returnKey?: boolean): string {
    return shouldReturnKey("labels.management", returnKey, {});
  },
  memoryInGB: function labels_memoryInGB(returnKey?: boolean): string {
    return shouldReturnKey("labels.memoryInGB", returnKey, {});
  },
  menuBehavior: function labels_menuBehavior(returnKey?: boolean): string {
    return shouldReturnKey("labels.menuBehavior", returnKey, {});
  },
  messages: function labels_messages(returnKey?: boolean): string {
    return shouldReturnKey("labels.messages", returnKey, {});
  },
  mutualAuthentication: function labels_mutualAuthentication(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mutualAuthentication", returnKey, {});
  },
  mySQLBackup: function labels_mySQLBackup(returnKey?: boolean): string {
    return shouldReturnKey("labels.mySQLBackup", returnKey, {});
  },
  mySQLDatabaseDescription: function labels_mySQLDatabaseDescription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLDatabaseDescription", returnKey, {});
  },
  mySQLDatabaseSystem: function labels_mySQLDatabaseSystem(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLDatabaseSystem", returnKey, {});
  },
  mySQLDatabaseSystemName: function labels_mySQLDatabaseSystemName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLDatabaseSystemName", returnKey, {});
  },
  mySQLDeleteProtected: function labels_mySQLDeleteProtected(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLDeleteProtected", returnKey, {});
  },
  mySQLEnableCrashRecovery: function labels_mySQLEnableCrashRecovery(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLEnableCrashRecovery", returnKey, {});
  },
  mySQLEnableDeleteProtected: function labels_mySQLEnableDeleteProtected(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLEnableDeleteProtected", returnKey, {});
  },
  mySQLEnableHeatwaveCluster: function labels_mySQLEnableHeatwaveCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLEnableHeatwaveCluster", returnKey, {});
  },
  mySQLEnableRequireFinalBackup: function labels_mySQLEnableRequireFinalBackup(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "labels.mySQLEnableRequireFinalBackup",
      returnKey,
      {}
    );
  },
  mySQLEnableRetainAutomaticBackups: function labels_mySQLEnableRetainAutomaticBackups(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "labels.mySQLEnableRetainAutomaticBackups",
      returnKey,
      {}
    );
  },
  mySQLHeatwave: function labels_mySQLHeatwave(returnKey?: boolean): string {
    return shouldReturnKey("labels.mySQLHeatwave", returnKey, {});
  },
  mySQLHeatwaveShape: function labels_mySQLHeatwaveShape(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLHeatwaveShape", returnKey, {});
  },
  mySQLPort: function labels_mySQLPort(returnKey?: boolean): string {
    return shouldReturnKey("labels.mySQLPort", returnKey, {});
  },
  mySQLPortX: function labels_mySQLPortX(returnKey?: boolean): string {
    return shouldReturnKey("labels.mySQLPortX", returnKey, {});
  },
  mySQLRequireFinalBackup: function labels_mySQLRequireFinalBackup(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLRequireFinalBackup", returnKey, {});
  },
  mySQLRestoreFromBackup: function labels_mySQLRestoreFromBackup(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLRestoreFromBackup", returnKey, {});
  },
  mySQLRetainAutomaticBackups: function labels_mySQLRetainAutomaticBackups(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLRetainAutomaticBackups", returnKey, {});
  },
  mySQLVersion: function labels_mySQLVersion(returnKey?: boolean): string {
    return shouldReturnKey("labels.mySQLVersion", returnKey, {});
  },
  mySQLWindowStartTime: function labels_mySQLWindowStartTime(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.mySQLWindowStartTime", returnKey, {});
  },
  name: function labels_name(returnKey?: boolean): string {
    return shouldReturnKey("labels.name", returnKey, {});
  },
  nationalCharacterSet: function labels_nationalCharacterSet(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.nationalCharacterSet", returnKey, {});
  },
  networking: function labels_networking(returnKey?: boolean): string {
    return shouldReturnKey("labels.networking", returnKey, {});
  },
  networkLink: function labels_networkLink(returnKey?: boolean): string {
    return shouldReturnKey("labels.networkLink", returnKey, {});
  },
  networkPeering: function labels_networkPeering(returnKey?: boolean): string {
    return shouldReturnKey("labels.networkPeering", returnKey, {});
  },
  networkVirtualAppliance: function labels_networkVirtualAppliance(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.networkVirtualAppliance", returnKey, {});
  },
  newAdminPassword: function labels_newAdminPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.newAdminPassword", returnKey, {});
  },
  newAdminConfirmPassword: function labels_newAdminConfirmPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.newAdminConfirmPassword", returnKey, {});
  },
  newQuotaLimit: function labels_newQuotaLimit(returnKey?: boolean): string {
    return shouldReturnKey("labels.newQuotaLimit", returnKey, {});
  },
  newTdeWalletPassword: function labels_newTdeWalletPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.newTdeWalletPassword", returnKey, {});
  },
  newTdeWalletConfirmPassword: function labels_newTdeWalletConfirmPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.newTdeWalletConfirmPassword", returnKey, {});
  },
  nodeCount: function labels_nodeCount(returnKey?: boolean): string {
    return shouldReturnKey("labels.nodeCount", returnKey, {});
  },
  notifications: function labels_notifications(returnKey?: boolean): string {
    return shouldReturnKey("labels.notifications", returnKey, {});
  },
  ociCidr: function labels_ociCidr(returnKey?: boolean): string {
    return shouldReturnKey("labels.ociCidr", returnKey, {});
  },
  ociCidrBlocks: function labels_ociCidrBlocks(returnKey?: boolean): string {
    return shouldReturnKey("labels.ociCidrBlocks", returnKey, {});
  },
  ociCidrs: function labels_ociCidrs(returnKey?: boolean): string {
    return shouldReturnKey("labels.ociCidrs", returnKey, {});
  },
  ocid: function labels_ocid(returnKey?: boolean): string {
    return shouldReturnKey("labels.ocid", returnKey, {});
  },
  ociResource: function labels_ociResource(returnKey?: boolean): string {
    return shouldReturnKey("labels.ociResource", returnKey, {});
  },
  ociTenant: function labels_ociTenant(returnKey?: boolean): string {
    return shouldReturnKey("labels.ociTenant", returnKey, {});
  },
  autoScaling: function labels_autoScaling(returnKey?: boolean): string {
    return shouldReturnKey("labels.autoScaling", returnKey, {});
  },
  ocpuCount: function labels_ocpuCount(returnKey?: boolean): string {
    return shouldReturnKey("labels.ocpuCount", returnKey, {});
  },
  ecpuCount: function labels_ecpuCount(returnKey?: boolean): string {
    return shouldReturnKey("labels.ecpuCount", returnKey, {});
  },
  updateToECPUBillingModel: function labels_updateToECPUBillingModel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.updateToECPUBillingModel", returnKey, {});
  },
  ocpuCountPerVM: function labels_ocpuCountPerVM(returnKey?: boolean): string {
    return shouldReturnKey("labels.ocpuCountPerVM", returnKey, {});
  },
  oldTdeWalletPassword: function labels_oldTdeWalletPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.oldTdeWalletPassword", returnKey, {});
  },
  openMode: function labels_openMode(returnKey?: boolean): string {
    return shouldReturnKey("labels.openMode", returnKey, {});
  },
  operationDetails: function labels_operationDetails(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.operationDetails", returnKey, {});
  },
  oracleDatabaseEdition: function labels_oracleDatabaseEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.oracleDatabaseEdition", returnKey, {});
  },
  oracleGridInfrastructureVersion: function labels_oracleGridInfrastructureVersion(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "labels.oracleGridInfrastructureVersion",
      returnKey,
      {}
    );
  },
  oracleSIDPrefix: function labels_oracleSIDPrefix(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.oracleSIDPrefix", returnKey, {});
  },
  overview: function labels_overview(returnKey?: boolean): string {
    return shouldReturnKey("labels.overview", returnKey, {});
  },
  password: function labels_password(returnKey?: boolean): string {
    return shouldReturnKey("labels.password", returnKey, {});
  },
  passwordConfirm: function labels_passwordConfirm(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.passwordConfirm", returnKey, {});
  },
  pdbAdminPassword: function labels_pdbAdminPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.pdbAdminPassword", returnKey, {});
  },
  pdbAdminPasswordConfirm: function labels_pdbAdminPasswordConfirm(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.pdbAdminPasswordConfirm", returnKey, {});
  },
  pdbName: function labels_pdbName(returnKey?: boolean): string {
    return shouldReturnKey("labels.pdbName", returnKey, {});
  },
  personaDirectory: function labels_personaDirectory(
    name: string,
    id: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.personaDirectory", returnKey, { name, id });
  },
  personaDomain: function labels_personaDomain(
    domain: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.personaDomain", returnKey, { domain });
  },
  personaEmail: function labels_personaEmail(
    email: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.personaEmail", returnKey, { email });
  },
  personaName: function labels_personaName(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.personaName", returnKey, { name });
  },
  pitrPolicy: function labels_pitrPolicy(returnKey?: boolean): string {
    return shouldReturnKey("labels.pitrPolicy", returnKey, {});
  },
  pluggableDatabase: function labels_pluggableDatabase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.pluggableDatabase", returnKey, {});
  },
  pluggableDatabaseName: function labels_pluggableDatabaseName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.pluggableDatabaseName", returnKey, {});
  },
  pluggableDatabases: function labels_pluggableDatabases(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.pluggableDatabases", returnKey, {});
  },
  pointInTimeRestore: function labels_pointInTimeRestore(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.pointInTimeRestore", returnKey, {});
  },
  popularProducts: function labels_popularProducts(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.popularProducts", returnKey, {});
  },
  privateEndpointIp: function labels_privateEndpointIp(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.privateEndpointIp", returnKey, {});
  },
  privateEndpointUrl: function labels_privateEndpointUrl(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.privateEndpointUrl", returnKey, {});
  },
  problemStartDate: function labels_problemStartDate(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.problemStartDate", returnKey, {});
  },
  problemType: function labels_problemType(returnKey?: boolean): string {
    return shouldReturnKey("labels.problemType", returnKey, {});
  },
  provideIssueDetails: function labels_provideIssueDetails(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.provideIssueDetails", returnKey, {});
  },
  providerQuotaType: function labels_providerQuotaType(
    provider: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.providerQuotaType", returnKey, { provider });
  },
  quotaType: function labels_quotaType(returnKey?: boolean): string {
    return shouldReturnKey("labels.quotaType", returnKey, {});
  },
  quotaUsageAndLimitAndRequested: function labels_quotaUsageAndLimitAndRequested(
    usage: string,
    oldLimit: string,
    newLimit: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.quotaUsageAndLimitAndRequested", returnKey, {
      usage,
      oldLimit,
      newLimit
    });
  },
  recent: function labels_recent(returnKey?: boolean): string {
    return shouldReturnKey("labels.recent", returnKey, {});
  },
  recentlyCreated: function labels_recentlyCreated(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.recentlyCreated", returnKey, {});
  },
  region: function labels_region(returnKey?: boolean): string {
    return shouldReturnKey("labels.region", returnKey, {});
  },
  registerExistingOciVcn: function labels_registerExistingOciVcn(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.registerExistingOciVcn", returnKey, {});
  },
  reopenTicket: function labels_reopenTicket(returnKey?: boolean): string {
    return shouldReturnKey("labels.reopenTicket", returnKey, {});
  },
  requestDetails: function labels_requestDetails(returnKey?: boolean): string {
    return shouldReturnKey("labels.requestDetails", returnKey, {});
  },
  requestedOCPUCount: function labels_requestedOCPUCount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.requestedOCPUCount", returnKey, {});
  },
  requestSummary: function labels_requestSummary(returnKey?: boolean): string {
    return shouldReturnKey("labels.requestSummary", returnKey, {});
  },
  requiretMTLSAuthentication: function labels_requiretMTLSAuthentication(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.requiretMTLSAuthentication", returnKey, {});
  },
  resource: function labels_resource(returnKey?: boolean): string {
    return shouldReturnKey("labels.resource", returnKey, {});
  },
  resourceGroup: function labels_resourceGroup(returnKey?: boolean): string {
    return shouldReturnKey("labels.resourceGroup", returnKey, {});
  },
  resourceId: function labels_resourceId(returnKey?: boolean): string {
    return shouldReturnKey("labels.resourceId", returnKey, {});
  },
  resources: function labels_resources(returnKey?: boolean): string {
    return shouldReturnKey("labels.resources", returnKey, {});
  },
  resourceToBeDeleted: function labels_resourceToBeDeleted(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.resourceToBeDeleted", returnKey, {});
  },
  resourceType: function labels_resourceType(returnKey?: boolean): string {
    return shouldReturnKey("labels.resourceType", returnKey, {});
  },
  restartAdbs: function labels_restartAdbs(returnKey?: boolean): string {
    return shouldReturnKey("labels.restartAdbs", returnKey, {});
  },
  restartHeatWaveCluster: function labels_restartHeatWaveCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.restartHeatWaveCluster", returnKey, {});
  },
  restartMysql: function labels_restartMysql(returnKey?: boolean): string {
    return shouldReturnKey("labels.restartMysql", returnKey, {});
  },
  restore: function labels_restore(returnKey?: boolean): string {
    return shouldReturnKey("labels.restore", returnKey, {});
  },
  retentionDays: function labels_retentionDays(returnKey?: boolean): string {
    return shouldReturnKey("labels.retentionDays", returnKey, {});
  },
  retentionPeriod: function labels_retentionPeriod(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.retentionPeriod", returnKey, {});
  },
  retentionPeriodDays: function labels_retentionPeriodDays(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.retentionPeriodDays", returnKey, {});
  },
  routerIpAddress: function labels_routerIpAddress(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.routerIpAddress", returnKey, {});
  },
  rotateWallet: function labels_rotateWallet(returnKey?: boolean): string {
    return shouldReturnKey("labels.rotateWallet", returnKey, {});
  },
  routerIpAddresses: function labels_routerIpAddresses(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.routerIpAddresses", returnKey, {});
  },
  routeVNET: function labels_routeVNET(returnKey?: boolean): string {
    return shouldReturnKey("labels.routeVNET", returnKey, {});
  },
  scanDnsName: function labels_scanDnsName(returnKey?: boolean): string {
    return shouldReturnKey("labels.scanDnsName", returnKey, {});
  },
  scanIps: function labels_scanIps(returnKey?: boolean): string {
    return shouldReturnKey("labels.scanIps", returnKey, {});
  },
  scanListenerPort: function labels_scanListenerPort(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.scanListenerPort", returnKey, {});
  },
  scanListenerPortSsl: function labels_scanListenerPortSsl(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.scanListenerPortSsl", returnKey, {});
  },
  scheduling: function labels_scheduling(returnKey?: boolean): string {
    return shouldReturnKey("labels.scheduling", returnKey, {});
  },
  security: function labels_security(returnKey?: boolean): string {
    return shouldReturnKey("labels.security", returnKey, {});
  },
  selectResources: function labels_selectResources(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.selectResources", returnKey, {});
  },
  serviceType: function labels_serviceType(returnKey?: boolean): string {
    return shouldReturnKey("labels.serviceType", returnKey, {});
  },
  settings: function labels_settings(returnKey?: boolean): string {
    return shouldReturnKey("labels.settings", returnKey, {});
  },
  serverless: function labels_serverless(returnKey?: boolean): string {
    return shouldReturnKey("labels.serverless", returnKey, {});
  },
  severity: function labels_severity(returnKey?: boolean): string {
    return shouldReturnKey("labels.severity", returnKey, {});
  },
  shape: function labels_shape(returnKey?: boolean): string {
    return shouldReturnKey("labels.shape", returnKey, {});
  },
  shapeSelection: function labels_shapeSelection(returnKey?: boolean): string {
    return shouldReturnKey("labels.shapeSelection", returnKey, {});
  },
  shared: function labels_shared(returnKey?: boolean): string {
    return shouldReturnKey("labels.shared", returnKey, {});
  },
  sidPrefix: function labels_sidPrefix(returnKey?: boolean): string {
    return shouldReturnKey("labels.sidPrefix", returnKey, {});
  },
  signingOut: function labels_signingOut(returnKey?: boolean): string {
    return shouldReturnKey("labels.signingOut", returnKey, {});
  },
  signingOutSetting: function labels_signingOutSetting(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.signingOutSetting", returnKey, {});
  },
  signOutInactive: function labels_signOutInactive(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.signOutInactive", returnKey, {});
  },
  softwareEdition: function labels_softwareEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.softwareEdition", returnKey, {});
  },
  sshPublicKey: function labels_sshPublicKey(returnKey?: boolean): string {
    return shouldReturnKey("labels.sshPublicKey", returnKey, {});
  },
  sshPublicKeySource: function labels_sshPublicKeySource(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.sshPublicKeySource", returnKey, {});
  },
  startAdbs: function labels_startAdbs(returnKey?: boolean): string {
    return shouldReturnKey("labels.startAdbs", returnKey, {});
  },
  started: function labels_started(returnKey?: boolean): string {
    return shouldReturnKey("labels.started", returnKey, {});
  },
  startExaCSDB: function labels_startExaCSDB(returnKey?: boolean): string {
    return shouldReturnKey("labels.startExaCSDB", returnKey, {});
  },
  startHeatWaveCluster: function labels_startHeatWaveCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.startHeatWaveCluster", returnKey, {});
  },
  startMysql: function labels_startMysql(returnKey?: boolean): string {
    return shouldReturnKey("labels.startMysql", returnKey, {});
  },
  startTime: function labels_startTime(returnKey?: boolean): string {
    return shouldReturnKey("labels.startTime", returnKey, {});
  },
  startVmDB: function labels_startVmDB(returnKey?: boolean): string {
    return shouldReturnKey("labels.startVmDB", returnKey, {});
  },
  status: function labels_status(returnKey?: boolean): string {
    return shouldReturnKey("labels.status", returnKey, {});
  },
  stopAdbs: function labels_stopAdbs(returnKey?: boolean): string {
    return shouldReturnKey("labels.stopAdbs", returnKey, {});
  },
  stopExaCSDB: function labels_stopExaCSDB(returnKey?: boolean): string {
    return shouldReturnKey("labels.stopExaCSDB", returnKey, {});
  },
  stopHeatWaveCluster: function labels_stopHeatWaveCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.stopHeatWaveCluster", returnKey, {});
  },
  stopMysql: function labels_stopMysql(returnKey?: boolean): string {
    return shouldReturnKey("labels.stopMysql", returnKey, {});
  },
  stopVmDB: function labels_stopVmDB(returnKey?: boolean): string {
    return shouldReturnKey("labels.stopVmDB", returnKey, {});
  },
  storage: function labels_storage(returnKey?: boolean): string {
    return shouldReturnKey("labels.storage", returnKey, {});
  },
  storageInTB: function labels_storageInTB(returnKey?: boolean): string {
    return shouldReturnKey("labels.storageInTB", returnKey, {});
  },
  storageLocalBackup: function labels_storageLocalBackup(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.storageLocalBackup", returnKey, {});
  },
  StorageManagementSoftware: function labels_StorageManagementSoftware(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.StorageManagementSoftware", returnKey, {});
  },
  storageServers: function labels_storageServers(returnKey?: boolean): string {
    return shouldReturnKey("labels.storageServers", returnKey, {});
  },
  storageSparseSnapshots: function labels_storageSparseSnapshots(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.storageSparseSnapshots", returnKey, {});
  },
  storageType: function labels_storageType(returnKey?: boolean): string {
    return shouldReturnKey("labels.storageType", returnKey, {});
  },
  storageValueInGBPlaceholder: function labels_storageValueInGBPlaceholder(
    storage: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.storageValueInGBPlaceholder", returnKey, {
      storage
    });
  },
  storageVolumePerformance: function labels_storageVolumePerformance(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.storageVolumePerformance", returnKey, {});
  },
  subnet: function labels_subnet(returnKey?: boolean): string {
    return shouldReturnKey("labels.subnet", returnKey, {});
  },
  subnetOcids: function labels_subnetOcids(returnKey?: boolean): string {
    return shouldReturnKey("labels.subnetOcids", returnKey, {});
  },
  subnets: function labels_subnets(returnKey?: boolean): string {
    return shouldReturnKey("labels.subnets", returnKey, {});
  },
  subscription: function labels_subscription(returnKey?: boolean): string {
    return shouldReturnKey("labels.subscription", returnKey, {});
  },
  subscriptionId: function labels_subscriptionId(returnKey?: boolean): string {
    return shouldReturnKey("labels.subscriptionId", returnKey, {});
  },
  subscriptionLocation: function labels_subscriptionLocation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.subscriptionLocation", returnKey, {});
  },
  summary: function labels_summary(returnKey?: boolean): string {
    return shouldReturnKey("labels.summary", returnKey, {});
  },
  support: function labels_support(returnKey?: boolean): string {
    return shouldReturnKey("labels.support", returnKey, {});
  },
  supportRequestId: function labels_supportRequestId(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.supportRequestId", returnKey, {});
  },
  systemModelShape: function labels_systemModelShape(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.systemModelShape", returnKey, {});
  },
  systemVersion: function labels_systemVersion(returnKey?: boolean): string {
    return shouldReturnKey("labels.systemVersion", returnKey, {});
  },
  tableName: function labels_tableName(returnKey?: boolean): string {
    return shouldReturnKey("labels.tableName", returnKey, {});
  },
  tags: function labels_tags(returnKey?: boolean): string {
    return shouldReturnKey("labels.tags", returnKey, {});
  },
  tdePassword: function labels_tdePassword(returnKey?: boolean): string {
    return shouldReturnKey("labels.tdePassword", returnKey, {});
  },
  tdePasswordConfirm: function labels_tdePasswordConfirm(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.tdePasswordConfirm", returnKey, {});
  },
  tdeWalletPassword: function labels_tdeWalletPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.tdeWalletPassword", returnKey, {});
  },
  title: function labels_title(returnKey?: boolean): string {
    return shouldReturnKey("labels.title", returnKey, {});
  },
  tnsName: function labels_tnsName(returnKey?: boolean): string {
    return shouldReturnKey("labels.tnsName", returnKey, {});
  },
  toolsAccessEndpoint: function labels_toolsAccessEndpoint(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.toolsAccessEndpoint", returnKey, {});
  },
  totalMemorySize: function labels_totalMemorySize(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.totalMemorySize", returnKey, {});
  },
  totalNodeCount: function labels_totalNodeCount(returnKey?: boolean): string {
    return shouldReturnKey("labels.totalNodeCount", returnKey, {});
  },
  totalStorageInGB: function labels_totalStorageInGB(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.totalStorageInGB", returnKey, {});
  },
  type: function labels_type(returnKey?: boolean): string {
    return shouldReturnKey("labels.type", returnKey, {});
  },
  unlockMyPDBAdminAccount: function labels_unlockMyPDBAdminAccount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.unlockMyPDBAdminAccount", returnKey, {});
  },
  updated: function labels_updated(returnKey?: boolean): string {
    return shouldReturnKey("labels.updated", returnKey, {});
  },
  upgradeNow: function labels_upgradeNow(returnKey?: boolean): string {
    return shouldReturnKey("labels.upgradeNow", returnKey, {});
  },
  uploadKey: function labels_uploadKey(returnKey?: boolean): string {
    return shouldReturnKey("labels.uploadKey", returnKey, {});
  },
  useDifferentTDEPassword: function labels_useDifferentTDEPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.useDifferentTDEPassword", returnKey, {});
  },
  username: function labels_username(returnKey?: boolean): string {
    return shouldReturnKey("labels.username", returnKey, {});
  },
  vcnOcid: function labels_vcnOcid(returnKey?: boolean): string {
    return shouldReturnKey("labels.vcnOcid", returnKey, {});
  },
  viewAll: function labels_viewAll(returnKey?: boolean): string {
    return shouldReturnKey("labels.viewAll", returnKey, {});
  },
  vips: function labels_vips(returnKey?: boolean): string {
    return shouldReturnKey("labels.vips", returnKey, {});
  },
  virtualCloudNetwork: function labels_virtualCloudNetwork(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.virtualCloudNetwork", returnKey, {});
  },
  virtualNetwork: function labels_virtualNetwork(returnKey?: boolean): string {
    return shouldReturnKey("labels.virtualNetwork", returnKey, {});
  },
  virtualNetworkAddresses: function labels_virtualNetworkAddresses(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.virtualNetworkAddresses", returnKey, {});
  },
  virtualNetworksAttachments: function labels_virtualNetworksAttachments(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.virtualNetworksAttachments", returnKey, {});
  },
  vmCluster: function labels_vmCluster(returnKey?: boolean): string {
    return shouldReturnKey("labels.vmCluster", returnKey, {});
  },
  vmClusterConfiguration: function labels_vmClusterConfiguration(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("labels.vmClusterConfiguration", returnKey, {});
  },
  vmClusterName: function labels_vmClusterName(returnKey?: boolean): string {
    return shouldReturnKey("labels.vmClusterName", returnKey, {});
  },
  vmClusters: function labels_vmClusters(returnKey?: boolean): string {
    return shouldReturnKey("labels.vmClusters", returnKey, {});
  },
  vmDatabase: function labels_vmDatabase(returnKey?: boolean): string {
    return shouldReturnKey("labels.vmDatabase", returnKey, {});
  },
  vmDatabaseName: function labels_vmDatabaseName(returnKey?: boolean): string {
    return shouldReturnKey("labels.vmDatabaseName", returnKey, {});
  },
  vmShape: function labels_vmShape(returnKey?: boolean): string {
    return shouldReturnKey("labels.vmShape", returnKey, {});
  },
  workloadType: function labels_workloadType(returnKey?: boolean): string {
    return shouldReturnKey("labels.workloadType", returnKey, {});
  }
};

export const listAdbs = {
  emptyList: {
    title: function listAdbs_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listAdbs.emptyList.title", returnKey, {});
    }
  },
  filters: {
    listView: function listAdbs_filters_listView(returnKey?: boolean): string {
      return shouldReturnKey("listAdbs.filters.listView", returnKey, {});
    }
  }
};

export const listApex = {
  emptyList: {
    title: function listApex_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listApex.emptyList.title", returnKey, {});
    }
  },
  titles: {
    long: function listApex_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listApex.titles.long", returnKey, {});
    },
    short: function listApex_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listApex.titles.short", returnKey, {});
    }
  }
};

export const listCloudLink = {
  emptyList: {
    title: function listCloudLink_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listCloudLink.emptyList.title", returnKey, {});
    }
  },
  filters: {
    listView: function listCloudLink_filters_listView(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listCloudLink.filters.listView", returnKey, {});
    }
  },
  messages: {
    lastOperationMessage: function listCloudLink_messages_lastOperationMessage(
      message: string,
      dateTime: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "listCloudLink.messages.lastOperationMessage",
        returnKey,
        { message, dateTime }
      );
    },
    numActiveLocations: function listCloudLink_messages_numActiveLocations(
      count: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "listCloudLink.messages.numActiveLocations",
        returnKey,
        { count }
      );
    },
    numFailedLocations: function listCloudLink_messages_numFailedLocations(
      count: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "listCloudLink.messages.numFailedLocations",
        returnKey,
        { count }
      );
    },
    numPendingLocations: function listCloudLink_messages_numPendingLocations(
      count: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "listCloudLink.messages.numPendingLocations",
        returnKey,
        { count }
      );
    }
  },
  titles: {
    locations: function listCloudLink_titles_locations(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listCloudLink.titles.locations", returnKey, {});
    },
    long: function listCloudLink_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listCloudLink.titles.long", returnKey, {});
    },
    short: function listCloudLink_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listCloudLink.titles.short", returnKey, {});
    }
  }
};

export const listExaDb = {
  emptyList: {
    title: function listExaDb_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listExaDb.emptyList.title", returnKey, {});
    }
  },
  filters: {
    listView: function listExaDb_filters_listView(returnKey?: boolean): string {
      return shouldReturnKey("listExaDb.filters.listView", returnKey, {});
    }
  },
  titles: {
    long: function listExaDb_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listExaDb.titles.long", returnKey, {});
    },
    short: function listExaDb_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listExaDb.titles.short", returnKey, {});
    }
  }
};

export const listExaInfra = {
  emptyList: {
    title: function listExaInfra_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listExaInfra.emptyList.title", returnKey, {});
    }
  },
  filters: {
    listView: function listExaInfra_filters_listView(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listExaInfra.filters.listView", returnKey, {});
    }
  }
};

export const listFavorite = {
  titles: {
    long: function listFavorite_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listFavorite.titles.long", returnKey, {});
    },
    short: function listFavorite_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listFavorite.titles.short", returnKey, {});
    }
  },
  emptyList: {
    title: function listFavorite_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listFavorite.emptyList.title", returnKey, {});
    }
  }
};

export const listMcvcn = {
  emptyList: {
    title: function listMcvcn_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listMcvcn.emptyList.title", returnKey, {});
    }
  },
  titles: {
    long: function listMcvcn_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listMcvcn.titles.long", returnKey, {});
    },
    short: function listMcvcn_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listMcvcn.titles.short", returnKey, {});
    }
  }
};

export const listMysql = {
  emptyList: {
    title: function listMysql_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listMysql.emptyList.title", returnKey, {});
    }
  },
  filters: {
    listView: function listMysql_filters_listView(returnKey?: boolean): string {
      return shouldReturnKey("listMysql.filters.listView", returnKey, {});
    }
  },
  titles: {
    long: function listMysql_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listMysql.titles.long", returnKey, {});
    },
    short: function listMysql_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listMysql.titles.short", returnKey, {});
    }
  }
};

export const listNetLink = {
  filters: {
    listView: function listNetLink_filters_listView(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listNetLink.filters.listView", returnKey, {});
    }
  }
};

export const listRecent = {
  titles: {
    long: function listRecent_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listRecent.titles.long", returnKey, {});
    },
    short: function listRecent_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listRecent.titles.short", returnKey, {});
    }
  },
  emptyList: {
    title: function listRecent_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listRecent.emptyList.title", returnKey, {});
    }
  }
};

export const listSupport = {
  emptyList: {
    title: function listSupport_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listSupport.emptyList.title", returnKey, {});
    }
  },
  filters: {
    allHistory: function listSupport_filters_allHistory(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listSupport.filters.allHistory", returnKey, {});
    },
    past7days: function listSupport_filters_past7days(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listSupport.filters.past7days", returnKey, {});
    },
    past14days: function listSupport_filters_past14days(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listSupport.filters.past14days", returnKey, {});
    },
    past30days: function listSupport_filters_past30days(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listSupport.filters.past30days", returnKey, {});
    },
    past60days: function listSupport_filters_past60days(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listSupport.filters.past60days", returnKey, {});
    },
    past90days: function listSupport_filters_past90days(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listSupport.filters.past90days", returnKey, {});
    }
  },
  messages: {
    createDisabled: function listSupport_messages_createDisabled(
      role: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listSupport.messages.createDisabled", returnKey, {
        role
      });
    }
  },
  status: {
    open: function listSupport_status_open(returnKey?: boolean): string {
      return shouldReturnKey("listSupport.status.open", returnKey, {});
    }
  },
  titles: {
    long: function listSupport_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listSupport.titles.long", returnKey, {});
    },
    short: function listSupport_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listSupport.titles.short", returnKey, {});
    }
  }
};

export const listVmCluster = {
  emptyList: {
    title: function listVmCluster_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listVmCluster.emptyList.title", returnKey, {});
    }
  },
  filters: {
    listView: function listVmCluster_filters_listView(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listVmCluster.filters.listView", returnKey, {});
    }
  }
};

export const listVmDb = {
  emptyList: {
    title: function listVmDb_emptyList_title(returnKey?: boolean): string {
      return shouldReturnKey("listVmDb.emptyList.title", returnKey, {});
    }
  },
  filters: {
    listView: function listVmDb_filters_listView(returnKey?: boolean): string {
      return shouldReturnKey("listVmDb.filters.listView", returnKey, {});
    }
  },
  titles: {
    long: function listVmDb_titles_long(returnKey?: boolean): string {
      return shouldReturnKey("listVmDb.titles.long", returnKey, {});
    },
    short: function listVmDb_titles_short(returnKey?: boolean): string {
      return shouldReturnKey("listVmDb.titles.short", returnKey, {});
    }
  }
};

export const listVmDbSystem = {
  emptyList: {
    title: function listVmDbSystem_emptyList_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listVmDbSystem.emptyList.title", returnKey, {});
    }
  },
  filters: {
    listView: function listVmDbSystem_filters_listView(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("listVmDbSystem.filters.listView", returnKey, {});
    }
  }
};

export const initErrors = {
  errorMessages: {
    duplicateSubscriptions: function initErrors_errorMessages_duplicateSubscriptions(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "initErrors.errorMessages.duplicateSubscriptions",
        returnKey,
        {}
      );
    },
    noLocation: function initErrors_errorMessages_noLocation(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "initErrors.errorMessages.noLocation",
        returnKey,
        {}
      );
    }
  },
  genericError: {
    title: function initErrors_genericError_title(returnKey?: boolean): string {
      return shouldReturnKey("initErrors.genericError.title", returnKey, {});
    },
    message: function initErrors_genericError_message(
      errorMessage: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("initErrors.genericError.message", returnKey, {
        errorMessage
      });
    }
  },
  linkingInProgress: {
    title: function initErrors_linkingInProgress_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "initErrors.linkingInProgress.title",
        returnKey,
        {}
      );
    },
    message: function initErrors_linkingInProgress_message(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "initErrors.linkingInProgress.message",
        returnKey,
        {}
      );
    }
  },
  linkingUnknown: {
    title: function initErrors_linkingUnknown_title(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("initErrors.linkingUnknown.title", returnKey, {});
    },
    message: function initErrors_linkingUnknown_message(
      apiError: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("initErrors.linkingUnknown.message", returnKey, {
        apiError
      });
    }
  }
};

export const notifications = {
  apiErrorMsg: function notifications_apiErrorMsg(
    errMsg: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("notifications.apiErrorMsg", returnKey, { errMsg });
  },
  deployedServices: {
    apex: function notifications_deployedServices_apex(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "notifications.deployedServices.apex",
        returnKey,
        {}
      );
    },
    autonomousSharedDatabase: function notifications_deployedServices_autonomousSharedDatabase(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "notifications.deployedServices.autonomousSharedDatabase",
        returnKey,
        {}
      );
    },
    exadataDatabase: function notifications_deployedServices_exadataDatabase(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "notifications.deployedServices.exadataDatabase",
        returnKey,
        {}
      );
    },
    exadataInfra: function notifications_deployedServices_exadataInfra(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "notifications.deployedServices.exadataInfra",
        returnKey,
        {}
      );
    },
    exadataSystem: function notifications_deployedServices_exadataSystem(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "notifications.deployedServices.exadataSystem",
        returnKey,
        {}
      );
    },
    exadataVmCluster: function notifications_deployedServices_exadataVmCluster(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "notifications.deployedServices.exadataVmCluster",
        returnKey,
        {}
      );
    },
    mysqlDatabase: function notifications_deployedServices_mysqlDatabase(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "notifications.deployedServices.mysqlDatabase",
        returnKey,
        {}
      );
    },
    vmDatabase: function notifications_deployedServices_vmDatabase(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "notifications.deployedServices.vmDatabase",
        returnKey,
        {}
      );
    }
  },
  failure: {
    messages: {
      addCapacityVMCluster: function notifications_failure_messages_addCapacityVMCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.addCapacityVMCluster",
          returnKey,
          { name }
        );
      },
      addRegion: function notifications_failure_messages_addRegion(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.addRegion",
          returnKey,
          { name }
        );
      },
      addSshKey: function notifications_failure_messages_addSshKey(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.addSshKey",
          returnKey,
          { name }
        );
      },
      applyTags: function notifications_failure_messages_applyTags(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.applyTags",
          returnKey,
          { name }
        );
      },
      attachVirtualNetwork: function notifications_failure_messages_attachVirtualNetwork(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.attachVirtualNetwork",
          returnKey,
          { name }
        );
      },
      clonePluggableDb: function notifications_failure_messages_clonePluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.clonePluggableDb",
          returnKey,
          { name }
        );
      },
      closeSupportRequest: function notifications_failure_messages_closeSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.closeSupportRequest",
          returnKey,
          { name }
        );
      },
      createBackup: function notifications_failure_messages_createBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.createBackup",
          returnKey,
          { name }
        );
      },
      createHeatWave: function notifications_failure_messages_createHeatWave(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.createHeatWave",
          returnKey,
          { name }
        );
      },
      createMcvcn: function notifications_failure_messages_createMcvcn(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.createMcvcn",
          returnKey,
          { name }
        );
      },
      createPluggableDb: function notifications_failure_messages_createPluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.createPluggableDb",
          returnKey,
          { name }
        );
      },
      createResourceDeployment: function notifications_failure_messages_createResourceDeployment(
        deploymentName: string,
        resourceType: string,
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.createResourceDeployment",
          returnKey,
          { deploymentName, resourceType, name }
        );
      },
      createSupportRequest: function notifications_failure_messages_createSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.createSupportRequest",
          returnKey,
          {}
        );
      },
      deleteAdbs: function notifications_failure_messages_deleteAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteAdbs",
          returnKey,
          { name }
        );
      },
      deleteBackup: function notifications_failure_messages_deleteBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteBackup",
          returnKey,
          { name }
        );
      },
      deleteExaDb: function notifications_failure_messages_deleteExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteExaDb",
          returnKey,
          { name }
        );
      },
      deleteExaInfra: function notifications_failure_messages_deleteExaInfra(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteExaInfra",
          returnKey,
          { name }
        );
      },
      deleteHeatWave: function notifications_failure_messages_deleteHeatWave(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteHeatWave",
          returnKey,
          { name }
        );
      },
      deleteMcVcn: function notifications_failure_messages_deleteMcVcn(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteMcVcn",
          returnKey,
          { name }
        );
      },
      deleteMysql: function notifications_failure_messages_deleteMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteMysql",
          returnKey,
          { name }
        );
      },
      deletePluggableDb: function notifications_failure_messages_deletePluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deletePluggableDb",
          returnKey,
          { name }
        );
      },
      deleteVMCluster: function notifications_failure_messages_deleteVMCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteVMCluster",
          returnKey,
          { name }
        );
      },
      deleteVMDb: function notifications_failure_messages_deleteVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteVMDb",
          returnKey,
          { name }
        );
      },
      deleteVMDbSystem: function notifications_failure_messages_deleteVMDbSystem(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deleteVMDbSystem",
          returnKey,
          { name }
        );
      },
      deployResource: function notifications_failure_messages_deployResource(
        resourceType: string,
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.deployResource",
          returnKey,
          { resourceType, name }
        );
      },
      detachVirtualNetwork: function notifications_failure_messages_detachVirtualNetwork(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.detachVirtualNetwork",
          returnKey,
          { name }
        );
      },
      downloadWallet: function notifications_failure_messages_downloadWallet(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.downloadWallet",
          returnKey,
          { name }
        );
      },
      linkSubscription: function notifications_failure_messages_linkSubscription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.linkSubscription",
          returnKey,
          { name }
        );
      },
      loadAdbs: function notifications_failure_messages_loadAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadAdbs",
          returnKey,
          {}
        );
      },
      loadBackups: function notifications_failure_messages_loadBackups(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadBackups",
          returnKey,
          {}
        );
      },
      loadBilling: function notifications_failure_messages_loadBilling(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadBilling",
          returnKey,
          {}
        );
      },
      loadCloudLinks: function notifications_failure_messages_loadCloudLinks(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadCloudLinks",
          returnKey,
          {}
        );
      },
      loadContainerDatabase: function notifications_failure_messages_loadContainerDatabase(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadContainerDatabase",
          returnKey,
          {}
        );
      },
      loadDatabaseHomes: function notifications_failure_messages_loadDatabaseHomes(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDatabaseHomes",
          returnKey,
          {}
        );
      },
      loadDatabaseNodes: function notifications_failure_messages_loadDatabaseNodes(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDatabaseNodes",
          returnKey,
          {}
        );
      },
      loadDatabases: function notifications_failure_messages_loadDatabases(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDatabases",
          returnKey,
          {}
        );
      },
      loadDbCharacterSet: function notifications_failure_messages_loadDbCharacterSet(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDbCharacterSet",
          returnKey,
          {}
        );
      },
      loadDbNationalCharacterSet: function notifications_failure_messages_loadDbNationalCharacterSet(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDbNationalCharacterSet",
          returnKey,
          {}
        );
      },
      loadDbSystemInitialStorageSizes: function notifications_failure_messages_loadDbSystemInitialStorageSizes(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDbSystemInitialStorageSizes",
          returnKey,
          {}
        );
      },
      loadDBSystemShape: function notifications_failure_messages_loadDBSystemShape(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDBSystemShape",
          returnKey,
          {}
        );
      },
      loadDbSystemStorageScaleSizes: function notifications_failure_messages_loadDbSystemStorageScaleSizes(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDbSystemStorageScaleSizes",
          returnKey,
          {}
        );
      },
      loadDbVersion: function notifications_failure_messages_loadDbVersion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDbVersion",
          returnKey,
          {}
        );
      },
      loadDeployment: function notifications_failure_messages_loadDeployment(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadDeployment",
          returnKey,
          {}
        );
      },
      loadExaDbCdbs: function notifications_failure_messages_loadExaDbCdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadExaDbCdbs",
          returnKey,
          {}
        );
      },
      loadExadbHome: function notifications_failure_messages_loadExadbHome(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadExadbHome",
          returnKey,
          {}
        );
      },
      loadExadbInfra: function notifications_failure_messages_loadExadbInfra(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadExadbInfra",
          returnKey,
          {}
        );
      },
      loadExadbInfraList: function notifications_failure_messages_loadExadbInfraList(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadExadbInfraList",
          returnKey,
          {}
        );
      },
      loadExadbNode: function notifications_failure_messages_loadExadbNode(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadExadbNode",
          returnKey,
          {}
        );
      },
      loadExaDbPdbs: function notifications_failure_messages_loadExaDbPdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadExaDbPdbs",
          returnKey,
          {}
        );
      },
      loadHeatwaveCluster: function notifications_failure_messages_loadHeatwaveCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadHeatwaveCluster",
          returnKey,
          {}
        );
      },
      loadInfra: function notifications_failure_messages_loadInfra(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadInfra",
          returnKey,
          {}
        );
      },
      loadMcvcn: function notifications_failure_messages_loadMcvcn(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadMcvcn",
          returnKey,
          {}
        );
      },
      loadMcvcns: function notifications_failure_messages_loadMcvcns(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadMcvcns",
          returnKey,
          {}
        );
      },
      loadMysql: function notifications_failure_messages_loadMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadMysql",
          returnKey,
          {}
        );
      },
      loadMysqlBackupDetail: function notifications_failure_messages_loadMysqlBackupDetail(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadMysqlBackupDetail",
          returnKey,
          { name }
        );
      },
      loadMysqlDatabase: function notifications_failure_messages_loadMysqlDatabase(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadMysqlDatabase",
          returnKey,
          {}
        );
      },
      loadMySQLHeatwaveShape: function notifications_failure_messages_loadMySQLHeatwaveShape(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadMySQLHeatwaveShape",
          returnKey,
          {}
        );
      },
      loadMySQLSystemModelShape: function notifications_failure_messages_loadMySQLSystemModelShape(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadMySQLSystemModelShape",
          returnKey,
          {}
        );
      },
      loadMySQLVersion: function notifications_failure_messages_loadMySQLVersion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadMySQLVersion",
          returnKey,
          {}
        );
      },
      loadNetLink: function notifications_failure_messages_loadNetLink(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadNetLink",
          returnKey,
          {}
        );
      },
      loadNetworkLinkWithName: function notifications_failure_messages_loadNetworkLinkWithName(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadNetworkLinkWithName",
          returnKey,
          { name }
        );
      },
      loadOracleGridInfraVersion: function notifications_failure_messages_loadOracleGridInfraVersion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadOracleGridInfraVersion",
          returnKey,
          {}
        );
      },
      loadPluggableDatabase: function notifications_failure_messages_loadPluggableDatabase(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadPluggableDatabase",
          returnKey,
          {}
        );
      },
      loadQuotas: function notifications_failure_messages_loadQuotas(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadQuotas",
          returnKey,
          {}
        );
      },
      loadQuotasLimitAvailability: function notifications_failure_messages_loadQuotasLimitAvailability(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadQuotasLimitAvailability",
          returnKey,
          {}
        );
      },
      loadQuotasLimitValue: function notifications_failure_messages_loadQuotasLimitValue(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadQuotasLimitValue",
          returnKey,
          {}
        );
      },
      loadResourceGroup: function notifications_failure_messages_loadResourceGroup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadResourceGroup",
          returnKey,
          {}
        );
      },
      loadSchema: function notifications_failure_messages_loadSchema(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadSchema",
          returnKey,
          {}
        );
      },
      loadSubnets: function notifications_failure_messages_loadSubnets(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadSubnets",
          returnKey,
          {}
        );
      },
      loadSubscription: function notifications_failure_messages_loadSubscription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadSubscription",
          returnKey,
          {}
        );
      },
      loadSupport: function notifications_failure_messages_loadSupport(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadSupport",
          returnKey,
          {}
        );
      },
      loadSupportList: function notifications_failure_messages_loadSupportList(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadSupportList",
          returnKey,
          {}
        );
      },
      loadVMClusterConfig: function notifications_failure_messages_loadVMClusterConfig(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVMClusterConfig",
          returnKey,
          {}
        );
      },
      loadVmClusterCapacity: function notifications_failure_messages_loadVmClusterCapacity(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVmClusterCapacity",
          returnKey,
          {}
        );
      },
      loadVMCluster: function notifications_failure_messages_loadVMCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVMCluster",
          returnKey,
          {}
        );
      },
      loadVMClusters: function notifications_failure_messages_loadVMClusters(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVMClusters",
          returnKey,
          {}
        );
      },
      loadVMDbCdbs: function notifications_failure_messages_loadVMDbCdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVMDbCdbs",
          returnKey,
          {}
        );
      },
      loadVMDbHome: function notifications_failure_messages_loadVMDbHome(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVMDbHome",
          returnKey,
          {}
        );
      },
      loadVMDbNode: function notifications_failure_messages_loadVMDbNode(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVMDbNode",
          returnKey,
          {}
        );
      },
      loadVMDbPdbs: function notifications_failure_messages_loadVMDbPdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVMDbPdbs",
          returnKey,
          {}
        );
      },
      loadVmDbSystem: function notifications_failure_messages_loadVmDbSystem(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVmDbSystem",
          returnKey,
          {}
        );
      },
      loadVmDbSystems: function notifications_failure_messages_loadVmDbSystems(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVmDbSystems",
          returnKey,
          {}
        );
      },
      loadVNET: function notifications_failure_messages_loadVNET(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.loadVNET",
          returnKey,
          {}
        );
      },
      reopenSupportRequest: function notifications_failure_messages_reopenSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.reopenSupportRequest",
          returnKey,
          { name }
        );
      },
      restartAdbs: function notifications_failure_messages_restartAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.restartAdbs",
          returnKey,
          { name }
        );
      },
      restartHeatWaveCluster: function notifications_failure_messages_restartHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.restartHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      restartMysql: function notifications_failure_messages_restartMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.restartMysql",
          returnKey,
          { name }
        );
      },
      restore: function notifications_failure_messages_restore(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.restore",
          returnKey,
          { name }
        );
      },
      restoreAdbs: function notifications_failure_messages_restoreAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.restoreAdbs",
          returnKey,
          { name }
        );
      },
      restoreFromBackup: function notifications_failure_messages_restoreFromBackup(
        name: string,
        backupName: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.restoreFromBackup",
          returnKey,
          { name, backupName }
        );
      },
      rotateWallet: function notifications_failure_messages_rotateWallet(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.rotateWallet",
          returnKey,
          { name }
        );
      },
      scaleAdbs: function notifications_failure_messages_scaleAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.scaleAdbs",
          returnKey,
          { name }
        );
      },
      scaleExaInfra: function notifications_failure_messages_scaleExaInfra(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.scaleExaInfra",
          returnKey,
          { name }
        );
      },
      scaleVmCluster: function notifications_failure_messages_scaleVmCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.scaleVmCluster",
          returnKey,
          { name }
        );
      },
      scaleVmDbSystem: function notifications_failure_messages_scaleVmDbSystem(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.scaleVmDbSystem",
          returnKey,
          { name }
        );
      },
      searchSupportRequest: function notifications_failure_messages_searchSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.searchSupportRequest",
          returnKey,
          {}
        );
      },
      startAdbs: function notifications_failure_messages_startAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.startAdbs",
          returnKey,
          { name }
        );
      },
      startExaDb: function notifications_failure_messages_startExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.startExaDb",
          returnKey,
          { name }
        );
      },
      startHeatWaveCluster: function notifications_failure_messages_startHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.startHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      startMysql: function notifications_failure_messages_startMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.startMysql",
          returnKey,
          { name }
        );
      },
      startVMDb: function notifications_failure_messages_startVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.startVMDb",
          returnKey,
          { name }
        );
      },
      stopAdbs: function notifications_failure_messages_stopAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.stopAdbs",
          returnKey,
          { name }
        );
      },
      stopExaDb: function notifications_failure_messages_stopExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.stopExaDb",
          returnKey,
          { name }
        );
      },
      stopHeatWaveCluster: function notifications_failure_messages_stopHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.stopHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      stopMysql: function notifications_failure_messages_stopMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.stopMysql",
          returnKey,
          { name }
        );
      },
      stopVMDb: function notifications_failure_messages_stopVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.stopVMDb",
          returnKey,
          { name }
        );
      },
      submitFeedback: function notifications_failure_messages_submitFeedback(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.submitFeedback",
          returnKey,
          {}
        );
      },
      updateAdbsDbName: function notifications_failure_messages_updateAdbsDbName(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateAdbsDbName",
          returnKey,
          { name }
        );
      },
      updateAdbsLicense: function notifications_failure_messages_updateAdbsLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateAdbsLicense",
          returnKey,
          { name }
        );
      },
      updateAdbsNetworking: function notifications_failure_messages_updateAdbsNetworking(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateAdbsNetworking",
          returnKey,
          { name }
        );
      },
      updateAdbsNetworkMutualAuth: function notifications_failure_messages_updateAdbsNetworkMutualAuth(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateAdbsNetworkMutualAuth",
          returnKey,
          { name }
        );
      },
      updateAdbsPassword: function notifications_failure_messages_updateAdbsPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateAdbsPassword",
          returnKey,
          { name }
        );
      },
      updateExadataVmClusterLicense: function notifications_failure_messages_updateExadataVmClusterLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateExadataVmClusterLicense",
          returnKey,
          { name }
        );
      },
      updateHeatWaveNodeCount: function notifications_failure_messages_updateHeatWaveNodeCount(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateHeatWaveNodeCount",
          returnKey,
          { name }
        );
      },
      updateMysqlBackup: function notifications_failure_messages_updateMysqlBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlBackup",
          returnKey,
          { name }
        );
      },
      updateMysqlBackupDescription: function notifications_failure_messages_updateMysqlBackupDescription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlBackupDescription",
          returnKey,
          { name }
        );
      },
      updateMysqlBackupRetentionDays: function notifications_failure_messages_updateMysqlBackupRetentionDays(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlBackupRetentionDays",
          returnKey,
          { name }
        );
      },
      updateMysqlCrashRecovery: function notifications_failure_messages_updateMysqlCrashRecovery(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlCrashRecovery",
          returnKey,
          { name }
        );
      },
      updateMysqlDataStorageSize: function notifications_failure_messages_updateMysqlDataStorageSize(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlDataStorageSize",
          returnKey,
          { name }
        );
      },
      updateMysqlDeletionPlan: function notifications_failure_messages_updateMysqlDeletionPlan(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlDeletionPlan",
          returnKey,
          { name }
        );
      },
      updateMysqlDescription: function notifications_failure_messages_updateMysqlDescription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlDescription",
          returnKey,
          { name }
        );
      },
      updateMysqlPassword: function notifications_failure_messages_updateMysqlPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlPassword",
          returnKey,
          { name }
        );
      },
      updateMysqlShape: function notifications_failure_messages_updateMysqlShape(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlShape",
          returnKey,
          { name }
        );
      },
      updateMysqlVersion: function notifications_failure_messages_updateMysqlVersion(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateMysqlVersion",
          returnKey,
          { name }
        );
      },
      updateVmDbSystemLicense: function notifications_failure_messages_updateVmDbSystemLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateVmDbSystemLicense",
          returnKey,
          { name }
        );
      },
      updateVmDbSystemStorage: function notifications_failure_messages_updateVmDbSystemStorage(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateVmDbSystemStorage",
          returnKey,
          { name }
        );
      },
      updateExaCdbBackup: function notifications_failure_messages_updateExaCdbBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateExaCdbBackup",
          returnKey,
          { name }
        );
      },
      updateSupportRequest: function notifications_failure_messages_updateSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateSupportRequest",
          returnKey,
          { name }
        );
      },
      updateVmCdbBackup: function notifications_failure_messages_updateVmCdbBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateVmCdbBackup",
          returnKey,
          { name }
        );
      },
      updateVmCdbPassword: function notifications_failure_messages_updateVmCdbPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateVmCdbPassword",
          returnKey,
          { name }
        );
      },
      updateVmTdePassword: function notifications_failure_messages_updateVmTdePassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.updateVmTdePassword",
          returnKey,
          { name }
        );
      },
      upgradeToATP: function notifications_failure_messages_upgradeToATP(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.upgradeToATP",
          returnKey,
          { name }
        );
      },
      uploadSupportRequestFile: function notifications_failure_messages_uploadSupportRequestFile(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.uploadSupportRequestFile",
          returnKey,
          { name }
        );
      },
      validationCheck: function notifications_failure_messages_validationCheck(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.messages.validationCheck",
          returnKey,
          {}
        );
      }
    },
    titles: {
      addCapacityVMCluster: function notifications_failure_titles_addCapacityVMCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.addCapacityVMCluster",
          returnKey,
          {}
        );
      },
      addRegion: function notifications_failure_titles_addRegion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.addRegion",
          returnKey,
          {}
        );
      },
      addSshKey: function notifications_failure_titles_addSshKey(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.addSshKey",
          returnKey,
          {}
        );
      },
      applyTags: function notifications_failure_titles_applyTags(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.applyTags",
          returnKey,
          {}
        );
      },
      attach: function notifications_failure_titles_attach(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.attach",
          returnKey,
          {}
        );
      },
      clone: function notifications_failure_titles_clone(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.clone",
          returnKey,
          {}
        );
      },
      closeSupportRequest: function notifications_failure_titles_closeSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.closeSupportRequest",
          returnKey,
          {}
        );
      },
      create: function notifications_failure_titles_create(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.create",
          returnKey,
          {}
        );
      },
      createResourceDeployment: function notifications_failure_titles_createResourceDeployment(
        resourceType: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.createResourceDeployment",
          returnKey,
          { resourceType }
        );
      },
      delete: function notifications_failure_titles_delete(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.delete",
          returnKey,
          {}
        );
      },
      deployResource: function notifications_failure_titles_deployResource(
        resourceType: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.deployResource",
          returnKey,
          { resourceType }
        );
      },
      detach: function notifications_failure_titles_detach(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.detach",
          returnKey,
          {}
        );
      },
      downloadWallet: function notifications_failure_titles_downloadWallet(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.downloadWallet",
          returnKey,
          {}
        );
      },
      linkSubscription: function notifications_failure_titles_linkSubscription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.linkSubscription",
          returnKey,
          {}
        );
      },
      load: function notifications_failure_titles_load(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.load",
          returnKey,
          {}
        );
      },
      reopenSupportRequest: function notifications_failure_titles_reopenSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.reopenSupportRequest",
          returnKey,
          {}
        );
      },
      restart: function notifications_failure_titles_restart(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.restart",
          returnKey,
          {}
        );
      },
      restore: function notifications_failure_titles_restore(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.restore",
          returnKey,
          {}
        );
      },
      restoreAdbs: function notifications_failure_titles_restoreAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.restoreAdbs",
          returnKey,
          {}
        );
      },
      rotate: function notifications_failure_titles_rotate(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.rotate",
          returnKey,
          {}
        );
      },
      scale: function notifications_failure_titles_scale(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.scale",
          returnKey,
          {}
        );
      },
      scaleVmDbSystem: function notifications_failure_titles_scaleVmDbSystem(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.scaleVmDbSystem",
          returnKey,
          {}
        );
      },
      searchSupportRequest: function notifications_failure_titles_searchSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.searchSupportRequest",
          returnKey,
          {}
        );
      },
      start: function notifications_failure_titles_start(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.start",
          returnKey,
          {}
        );
      },
      stop: function notifications_failure_titles_stop(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.stop",
          returnKey,
          {}
        );
      },
      submitFeedback: function notifications_failure_titles_submitFeedback(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.submitFeedback",
          returnKey,
          {}
        );
      },
      updateAdbsDbName: function notifications_failure_titles_updateAdbsDbName(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateAdbsDbName",
          returnKey,
          {}
        );
      },
      updateAdbsLicense: function notifications_failure_titles_updateAdbsLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateAdbsLicense",
          returnKey,
          {}
        );
      },
      updateAdbsNetworking: function notifications_failure_titles_updateAdbsNetworking(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateAdbsNetworking",
          returnKey,
          {}
        );
      },
      updateAdbsNetworkMutualAuth: function notifications_failure_titles_updateAdbsNetworkMutualAuth(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateAdbsNetworkMutualAuth",
          returnKey,
          {}
        );
      },
      updateAdbsPassword: function notifications_failure_titles_updateAdbsPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateAdbsPassword",
          returnKey,
          {}
        );
      },
      updateExadataVmClusterLicense: function notifications_failure_titles_updateExadataVmClusterLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateExadataVmClusterLicense",
          returnKey,
          {}
        );
      },
      updateHeatWaveNodeCount: function notifications_failure_titles_updateHeatWaveNodeCount(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateHeatWaveNodeCount",
          returnKey,
          {}
        );
      },
      updateMysqlBackup: function notifications_failure_titles_updateMysqlBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlBackup",
          returnKey,
          {}
        );
      },
      updateMysqlBackupDescription: function notifications_failure_titles_updateMysqlBackupDescription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlBackupDescription",
          returnKey,
          {}
        );
      },
      updateMysqlBackupRetentionDays: function notifications_failure_titles_updateMysqlBackupRetentionDays(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlBackupRetentionDays",
          returnKey,
          {}
        );
      },
      updateMysqlCrashRecovery: function notifications_failure_titles_updateMysqlCrashRecovery(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlCrashRecovery",
          returnKey,
          {}
        );
      },
      updateMysqlDataStorageSize: function notifications_failure_titles_updateMysqlDataStorageSize(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlDataStorageSize",
          returnKey,
          {}
        );
      },
      updateMysqlDeletionPlan: function notifications_failure_titles_updateMysqlDeletionPlan(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlDeletionPlan",
          returnKey,
          {}
        );
      },
      updateMysqlDescription: function notifications_failure_titles_updateMysqlDescription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlDescription",
          returnKey,
          {}
        );
      },
      updateMysqlPassword: function notifications_failure_titles_updateMysqlPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlPassword",
          returnKey,
          {}
        );
      },
      updateMysqlShape: function notifications_failure_titles_updateMysqlShape(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlShape",
          returnKey,
          {}
        );
      },
      updateMysqlVersion: function notifications_failure_titles_updateMysqlVersion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateMysqlVersion",
          returnKey,
          {}
        );
      },
      updateVmDbSystemLicense: function notifications_failure_titles_updateVmDbSystemLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateVmDbSystemLicense",
          returnKey,
          {}
        );
      },
      updateVmDbSystemStorage: function notifications_failure_titles_updateVmDbSystemStorage(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateVmDbSystemStorage",
          returnKey,
          {}
        );
      },
      updateExaCdbBackup: function notifications_failure_titles_updateExaCdbBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateExaCdbBackup",
          returnKey,
          {}
        );
      },
      updateSupportRequest: function notifications_failure_titles_updateSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateSupportRequest",
          returnKey,
          {}
        );
      },
      updateVmCdbBackup: function notifications_failure_titles_updateVmCdbBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateVmCdbBackup",
          returnKey,
          {}
        );
      },
      updateVmCdbPassword: function notifications_failure_titles_updateVmCdbPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateVmCdbPassword",
          returnKey,
          {}
        );
      },
      updateVmTdePassword: function notifications_failure_titles_updateVmTdePassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.updateVmTdePassword",
          returnKey,
          {}
        );
      },
      upgradeToATP: function notifications_failure_titles_upgradeToATP(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.upgradeToATP",
          returnKey,
          {}
        );
      },
      uploadSupportRequestFile: function notifications_failure_titles_uploadSupportRequestFile(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.uploadSupportRequestFile",
          returnKey,
          {}
        );
      },
      validationCheck: function notifications_failure_titles_validationCheck(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.failure.titles.validationCheck",
          returnKey,
          {}
        );
      }
    }
  },
  inProgress: {
    messages: {
      addCapacityVMCluster: function notifications_inProgress_messages_addCapacityVMCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.addCapacityVMCluster",
          returnKey,
          { name }
        );
      },
      addRegion: function notifications_inProgress_messages_addRegion(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.addRegion",
          returnKey,
          { name }
        );
      },
      addSshKey: function notifications_inProgress_messages_addSshKey(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.addSshKey",
          returnKey,
          { name }
        );
      },
      applyTags: function notifications_inProgress_messages_applyTags(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.applyTags",
          returnKey,
          { name }
        );
      },
      attachVirtualNetwork: function notifications_inProgress_messages_attachVirtualNetwork(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.attachVirtualNetwork",
          returnKey,
          { name }
        );
      },
      clonePluggableDb: function notifications_inProgress_messages_clonePluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.clonePluggableDb",
          returnKey,
          { name }
        );
      },
      closeSupportRequest: function notifications_inProgress_messages_closeSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.closeSupportRequest",
          returnKey,
          { name }
        );
      },
      createBackup: function notifications_inProgress_messages_createBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.createBackup",
          returnKey,
          { name }
        );
      },
      createHeatWave: function notifications_inProgress_messages_createHeatWave(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.createHeatWave",
          returnKey,
          { name }
        );
      },
      createMcvcn: function notifications_inProgress_messages_createMcvcn(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.createMcvcn",
          returnKey,
          { name }
        );
      },
      createPluggableDb: function notifications_inProgress_messages_createPluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.createPluggableDb",
          returnKey,
          { name }
        );
      },
      deleteAdbs: function notifications_inProgress_messages_deleteAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteAdbs",
          returnKey,
          { name }
        );
      },
      deleteBackup: function notifications_inProgress_messages_deleteBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteBackup",
          returnKey,
          { name }
        );
      },
      deleteExaDb: function notifications_inProgress_messages_deleteExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteExaDb",
          returnKey,
          { name }
        );
      },
      deleteExaInfra: function notifications_inProgress_messages_deleteExaInfra(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteExaInfra",
          returnKey,
          { name }
        );
      },
      deleteHeatWave: function notifications_inProgress_messages_deleteHeatWave(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteHeatWave",
          returnKey,
          { name }
        );
      },
      deleteMcVcn: function notifications_inProgress_messages_deleteMcVcn(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteMcVcn",
          returnKey,
          { name }
        );
      },
      deleteMysql: function notifications_inProgress_messages_deleteMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteMysql",
          returnKey,
          { name }
        );
      },
      deletePluggableDb: function notifications_inProgress_messages_deletePluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deletePluggableDb",
          returnKey,
          { name }
        );
      },
      deleteVMCluster: function notifications_inProgress_messages_deleteVMCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteVMCluster",
          returnKey,
          { name }
        );
      },
      deleteVMDb: function notifications_inProgress_messages_deleteVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteVMDb",
          returnKey,
          { name }
        );
      },
      deleteVMDbSystem: function notifications_inProgress_messages_deleteVMDbSystem(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deleteVMDbSystem",
          returnKey,
          { name }
        );
      },
      deployResource: function notifications_inProgress_messages_deployResource(
        deploymentName: string,
        resourceType: string,
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.deployResource",
          returnKey,
          { deploymentName, resourceType, name }
        );
      },
      detachVirtualNetwork: function notifications_inProgress_messages_detachVirtualNetwork(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.detachVirtualNetwork",
          returnKey,
          { name }
        );
      },
      linkSubscription: function notifications_inProgress_messages_linkSubscription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.linkSubscription",
          returnKey,
          { name }
        );
      },
      reopenSupportRequest: function notifications_inProgress_messages_reopenSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.reopenSupportRequest",
          returnKey,
          { name }
        );
      },
      restartHeatWaveCluster: function notifications_inProgress_messages_restartHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.restartHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      restartAdbs: function notifications_inProgress_messages_restartAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.restartAdbs",
          returnKey,
          { name }
        );
      },
      restartMysql: function notifications_inProgress_messages_restartMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.restartMysql",
          returnKey,
          { name }
        );
      },
      restore: function notifications_inProgress_messages_restore(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.restore",
          returnKey,
          { name }
        );
      },
      restoreAdbs: function notifications_inProgress_messages_restoreAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.restoreAdbs",
          returnKey,
          { name }
        );
      },
      restoreFromBackup: function notifications_inProgress_messages_restoreFromBackup(
        name: string,
        backupName: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.restoreFromBackup",
          returnKey,
          { name, backupName }
        );
      },
      rotateWallet: function notifications_inProgress_messages_rotateWallet(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.rotateWallet",
          returnKey,
          { name }
        );
      },
      scaleAdbs: function notifications_inProgress_messages_scaleAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.scaleAdbs",
          returnKey,
          { name }
        );
      },
      scaleExaInfra: function notifications_inProgress_messages_scaleExaInfra(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.scaleExaInfra",
          returnKey,
          { name }
        );
      },
      scaleVmCluster: function notifications_inProgress_messages_scaleVmCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.scaleVmCluster",
          returnKey,
          { name }
        );
      },
      scaleVmDbSystem: function notifications_inProgress_messages_scaleVmDbSystem(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.scaleVmDbSystem",
          returnKey,
          { name }
        );
      },
      startAdbs: function notifications_inProgress_messages_startAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.startAdbs",
          returnKey,
          { name }
        );
      },
      startExaDb: function notifications_inProgress_messages_startExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.startExaDb",
          returnKey,
          { name }
        );
      },
      startHeatWaveCluster: function notifications_inProgress_messages_startHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.startHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      startMysql: function notifications_inProgress_messages_startMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.startMysql",
          returnKey,
          { name }
        );
      },
      startVMDb: function notifications_inProgress_messages_startVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.startVMDb",
          returnKey,
          { name }
        );
      },
      stopAdbs: function notifications_inProgress_messages_stopAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.stopAdbs",
          returnKey,
          { name }
        );
      },
      stopExaDb: function notifications_inProgress_messages_stopExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.stopExaDb",
          returnKey,
          { name }
        );
      },
      stopHeatWaveCluster: function notifications_inProgress_messages_stopHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.stopHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      stopMysql: function notifications_inProgress_messages_stopMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.stopMysql",
          returnKey,
          { name }
        );
      },
      stopVMDb: function notifications_inProgress_messages_stopVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.stopVMDb",
          returnKey,
          { name }
        );
      },
      updateAdbsDbName: function notifications_inProgress_messages_updateAdbsDbName(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateAdbsDbName",
          returnKey,
          { name }
        );
      },
      updateAdbsLicense: function notifications_inProgress_messages_updateAdbsLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateAdbsLicense",
          returnKey,
          { name }
        );
      },
      updateAdbsNetworking: function notifications_inProgress_messages_updateAdbsNetworking(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateAdbsNetworking",
          returnKey,
          { name }
        );
      },
      updateAdbsNetworkMutualAuth: function notifications_inProgress_messages_updateAdbsNetworkMutualAuth(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateAdbsNetworkMutualAuth",
          returnKey,
          { name }
        );
      },
      updateAdbsPassword: function notifications_inProgress_messages_updateAdbsPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateAdbsPassword",
          returnKey,
          { name }
        );
      },
      updateExaCdbBackup: function notifications_inProgress_messages_updateExaCdbBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateExaCdbBackup",
          returnKey,
          { name }
        );
      },
      updateExadataVmClusterLicense: function notifications_inProgress_messages_updateExadataVmClusterLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateExadataVmClusterLicense",
          returnKey,
          { name }
        );
      },
      updateHeatWaveNodeCount: function notifications_inProgress_messages_updateHeatWaveNodeCount(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateHeatWaveNodeCount",
          returnKey,
          { name }
        );
      },
      updateMysqlBackup: function notifications_inProgress_messages_updateMysqlBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlBackup",
          returnKey,
          { name }
        );
      },
      updateMysqlBackupDescription: function notifications_inProgress_messages_updateMysqlBackupDescription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlBackupDescription",
          returnKey,
          { name }
        );
      },
      updateMysqlBackupRetentionDays: function notifications_inProgress_messages_updateMysqlBackupRetentionDays(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlBackupRetentionDays",
          returnKey,
          { name }
        );
      },
      updateMysqlCrashRecovery: function notifications_inProgress_messages_updateMysqlCrashRecovery(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlCrashRecovery",
          returnKey,
          { name }
        );
      },
      updateMysqlDataStorageSize: function notifications_inProgress_messages_updateMysqlDataStorageSize(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlDataStorageSize",
          returnKey,
          { name }
        );
      },
      updateMysqlDeletionPlan: function notifications_inProgress_messages_updateMysqlDeletionPlan(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlDeletionPlan",
          returnKey,
          { name }
        );
      },
      updateMysqlDescription: function notifications_inProgress_messages_updateMysqlDescription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlDescription",
          returnKey,
          { name }
        );
      },
      updateMysqlPassword: function notifications_inProgress_messages_updateMysqlPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlPassword",
          returnKey,
          { name }
        );
      },
      updateMysqlShape: function notifications_inProgress_messages_updateMysqlShape(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlShape",
          returnKey,
          { name }
        );
      },
      updateMysqlVersion: function notifications_inProgress_messages_updateMysqlVersion(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateMysqlVersion",
          returnKey,
          { name }
        );
      },
      updateSupportRequest: function notifications_inProgress_messages_updateSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateSupportRequest",
          returnKey,
          { name }
        );
      },
      updateVmCdbBackup: function notifications_inProgress_messages_updateVmCdbBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateVmCdbBackup",
          returnKey,
          { name }
        );
      },
      updateVmCdbPassword: function notifications_inProgress_messages_updateVmCdbPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateVmCdbPassword",
          returnKey,
          { name }
        );
      },
      updateVmDbSystemLicense: function notifications_inProgress_messages_updateVmDbSystemLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateVmDbSystemLicense",
          returnKey,
          { name }
        );
      },
      updateVmDbSystemStorage: function notifications_inProgress_messages_updateVmDbSystemStorage(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateVmDbSystemStorage",
          returnKey,
          { name }
        );
      },
      updateVmTdePassword: function notifications_inProgress_messages_updateVmTdePassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.updateVmTdePassword",
          returnKey,
          { name }
        );
      },
      upgradeToATP: function notifications_inProgress_messages_upgradeToATP(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.upgradeToATP",
          returnKey,
          { name }
        );
      },
      uploadSupportRequestFile: function notifications_inProgress_messages_uploadSupportRequestFile(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.messages.uploadSupportRequestFile",
          returnKey,
          { name }
        );
      }
    },
    titles: {
      addCapacityVMCluster: function notifications_inProgress_titles_addCapacityVMCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.addCapacityVMCluster",
          returnKey,
          {}
        );
      },
      addRegion: function notifications_inProgress_titles_addRegion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.addRegion",
          returnKey,
          {}
        );
      },
      addSshKey: function notifications_inProgress_titles_addSshKey(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.addSshKey",
          returnKey,
          {}
        );
      },
      applyTags: function notifications_inProgress_titles_applyTags(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.applyTags",
          returnKey,
          {}
        );
      },
      attachVirtualNetwork: function notifications_inProgress_titles_attachVirtualNetwork(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.attachVirtualNetwork",
          returnKey,
          {}
        );
      },
      clonePluggableDb: function notifications_inProgress_titles_clonePluggableDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.clonePluggableDb",
          returnKey,
          {}
        );
      },
      closeSupportRequest: function notifications_inProgress_titles_closeSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.closeSupportRequest",
          returnKey,
          {}
        );
      },
      createBackup: function notifications_inProgress_titles_createBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.createBackup",
          returnKey,
          {}
        );
      },
      createHeatWave: function notifications_inProgress_titles_createHeatWave(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.createHeatWave",
          returnKey,
          {}
        );
      },
      createMcvcn: function notifications_inProgress_titles_createMcvcn(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.createMcvcn",
          returnKey,
          {}
        );
      },
      createPluggableDb: function notifications_inProgress_titles_createPluggableDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.createPluggableDb",
          returnKey,
          {}
        );
      },
      deleteAdbs: function notifications_inProgress_titles_deleteAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteAdbs",
          returnKey,
          {}
        );
      },
      deleteBackup: function notifications_inProgress_titles_deleteBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteBackup",
          returnKey,
          {}
        );
      },
      deleteExaDb: function notifications_inProgress_titles_deleteExaDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteExaDb",
          returnKey,
          {}
        );
      },
      deleteExaInfra: function notifications_inProgress_titles_deleteExaInfra(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteExaInfra",
          returnKey,
          {}
        );
      },
      deleteHeatWave: function notifications_inProgress_titles_deleteHeatWave(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteHeatWave",
          returnKey,
          {}
        );
      },
      deleteMcVcn: function notifications_inProgress_titles_deleteMcVcn(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteMcVcn",
          returnKey,
          {}
        );
      },
      deleteMysql: function notifications_inProgress_titles_deleteMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteMysql",
          returnKey,
          {}
        );
      },
      deletePluggableDb: function notifications_inProgress_titles_deletePluggableDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deletePluggableDb",
          returnKey,
          {}
        );
      },
      deleteVMCluster: function notifications_inProgress_titles_deleteVMCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteVMCluster",
          returnKey,
          {}
        );
      },
      deleteVMDb: function notifications_inProgress_titles_deleteVMDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteVMDb",
          returnKey,
          {}
        );
      },
      deleteVMDbSystem: function notifications_inProgress_titles_deleteVMDbSystem(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deleteVMDbSystem",
          returnKey,
          {}
        );
      },
      deployResource: function notifications_inProgress_titles_deployResource(
        resourceType: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.deployResource",
          returnKey,
          { resourceType }
        );
      },
      detachVirtualNetwork: function notifications_inProgress_titles_detachVirtualNetwork(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.detachVirtualNetwork",
          returnKey,
          {}
        );
      },
      linkSubscription: function notifications_inProgress_titles_linkSubscription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.linkSubscription",
          returnKey,
          {}
        );
      },
      reopenSupportRequest: function notifications_inProgress_titles_reopenSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.reopenSupportRequest",
          returnKey,
          {}
        );
      },
      restartAdbs: function notifications_inProgress_titles_restartAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.restartAdbs",
          returnKey,
          {}
        );
      },
      restartHeatWaveCluster: function notifications_inProgress_titles_restartHeatWaveCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.restartHeatWaveCluster",
          returnKey,
          {}
        );
      },
      restartMysql: function notifications_inProgress_titles_restartMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.restartMysql",
          returnKey,
          {}
        );
      },
      restore: function notifications_inProgress_titles_restore(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.restore",
          returnKey,
          {}
        );
      },
      restoreAdbs: function notifications_inProgress_titles_restoreAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.restoreAdbs",
          returnKey,
          {}
        );
      },
      rotateWallet: function notifications_inProgress_titles_rotateWallet(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.rotateWallet",
          returnKey,
          {}
        );
      },
      scaleAdbs: function notifications_inProgress_titles_scaleAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.scaleAdbs",
          returnKey,
          {}
        );
      },
      scaleExaInfra: function notifications_inProgress_titles_scaleExaInfra(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.scaleExaInfra",
          returnKey,
          {}
        );
      },
      scaleVmCluster: function notifications_inProgress_titles_scaleVmCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.scaleVmCluster",
          returnKey,
          {}
        );
      },
      scaleVmDbSystem: function notifications_inProgress_titles_scaleVmDbSystem(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.scaleVmDbSystem",
          returnKey,
          {}
        );
      },
      startAdbs: function notifications_inProgress_titles_startAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.startAdbs",
          returnKey,
          {}
        );
      },
      startExaDb: function notifications_inProgress_titles_startExaDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.startExaDb",
          returnKey,
          {}
        );
      },
      startHeatWaveCluster: function notifications_inProgress_titles_startHeatWaveCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.startHeatWaveCluster",
          returnKey,
          {}
        );
      },
      startMysql: function notifications_inProgress_titles_startMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.startMysql",
          returnKey,
          {}
        );
      },
      startVMDb: function notifications_inProgress_titles_startVMDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.startVMDb",
          returnKey,
          {}
        );
      },
      stopAdbs: function notifications_inProgress_titles_stopAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.stopAdbs",
          returnKey,
          {}
        );
      },
      stopExaDb: function notifications_inProgress_titles_stopExaDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.stopExaDb",
          returnKey,
          {}
        );
      },
      stopHeatWaveCluster: function notifications_inProgress_titles_stopHeatWaveCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.stopHeatWaveCluster",
          returnKey,
          {}
        );
      },
      stopMysql: function notifications_inProgress_titles_stopMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.stopMysql",
          returnKey,
          {}
        );
      },
      stopVMDb: function notifications_inProgress_titles_stopVMDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.stopVMDb",
          returnKey,
          {}
        );
      },
      updateAdbsDbName: function notifications_inProgress_titles_updateAdbsDbName(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateAdbsDbName",
          returnKey,
          {}
        );
      },
      updateAdbsLicense: function notifications_inProgress_titles_updateAdbsLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateAdbsLicense",
          returnKey,
          {}
        );
      },
      updateAdbsNetworking: function notifications_inProgress_titles_updateAdbsNetworking(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateAdbsNetworking",
          returnKey,
          {}
        );
      },
      updateAdbsNetworkMutualAuth: function notifications_inProgress_titles_updateAdbsNetworkMutualAuth(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateAdbsNetworkMutualAuth",
          returnKey,
          {}
        );
      },
      updateAdbsPassword: function notifications_inProgress_titles_updateAdbsPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateAdbsPassword",
          returnKey,
          {}
        );
      },
      updateExaCdbBackup: function notifications_inProgress_titles_updateExaCdbBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateExaCdbBackup",
          returnKey,
          {}
        );
      },
      updateExadataVmClusterLicense: function notifications_inProgress_titles_updateExadataVmClusterLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateExadataVmClusterLicense",
          returnKey,
          {}
        );
      },
      updateHeatWaveNodeCount: function notifications_inProgress_titles_updateHeatWaveNodeCount(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateHeatWaveNodeCount",
          returnKey,
          {}
        );
      },
      updateMysqlBackup: function notifications_inProgress_titles_updateMysqlBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlBackup",
          returnKey,
          {}
        );
      },
      updateMysqlBackupDescription: function notifications_inProgress_titles_updateMysqlBackupDescription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlBackupDescription",
          returnKey,
          {}
        );
      },
      updateMysqlBackupRetentionDays: function notifications_inProgress_titles_updateMysqlBackupRetentionDays(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlBackupRetentionDays",
          returnKey,
          {}
        );
      },
      updateMysqlCrashRecovery: function notifications_inProgress_titles_updateMysqlCrashRecovery(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlCrashRecovery",
          returnKey,
          {}
        );
      },
      updateMysqlDataStorageSize: function notifications_inProgress_titles_updateMysqlDataStorageSize(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlDataStorageSize",
          returnKey,
          {}
        );
      },
      updateMysqlDeletionPlan: function notifications_inProgress_titles_updateMysqlDeletionPlan(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlDeletionPlan",
          returnKey,
          {}
        );
      },
      updateMysqlDescription: function notifications_inProgress_titles_updateMysqlDescription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlDescription",
          returnKey,
          {}
        );
      },
      updateMysqlPassword: function notifications_inProgress_titles_updateMysqlPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlPassword",
          returnKey,
          {}
        );
      },
      updateMysqlShape: function notifications_inProgress_titles_updateMysqlShape(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlShape",
          returnKey,
          {}
        );
      },
      updateMysqlVersion: function notifications_inProgress_titles_updateMysqlVersion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateMysqlVersion",
          returnKey,
          {}
        );
      },
      updateSupportRequest: function notifications_inProgress_titles_updateSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateSupportRequest",
          returnKey,
          {}
        );
      },
      updateVmCdbBackup: function notifications_inProgress_titles_updateVmCdbBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateVmCdbBackup",
          returnKey,
          {}
        );
      },
      updateVmCdbPassword: function notifications_inProgress_titles_updateVmCdbPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateVmCdbPassword",
          returnKey,
          {}
        );
      },
      updateVmDbSystemLicense: function notifications_inProgress_titles_updateVmDbSystemLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateVmDbSystemLicense",
          returnKey,
          {}
        );
      },
      updateVmDbSystemStorage: function notifications_inProgress_titles_updateVmDbSystemStorage(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateVmDbSystemStorage",
          returnKey,
          {}
        );
      },
      updateVmTdePassword: function notifications_inProgress_titles_updateVmTdePassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.updateVmTdePassword",
          returnKey,
          {}
        );
      },
      uploadSupportRequestFile: function notifications_inProgress_titles_uploadSupportRequestFile(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.uploadSupportRequestFile",
          returnKey,
          {}
        );
      },
      upgradeToATP: function notifications_inProgress_titles_upgradeToATP(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.inProgress.titles.upgradeToATP",
          returnKey,
          {}
        );
      }
    }
  },
  success: {
    messages: {
      addCapacityVMCluster: function notifications_success_messages_addCapacityVMCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.addCapacityVMCluster",
          returnKey,
          { name }
        );
      },
      addRegion: function notifications_success_messages_addRegion(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.addRegion",
          returnKey,
          { name }
        );
      },
      addSshKey: function notifications_success_messages_addSshKey(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.addSshKey",
          returnKey,
          { name }
        );
      },
      applyTags: function notifications_success_messages_applyTags(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.applyTags",
          returnKey,
          { name }
        );
      },
      attachVirtualNetwork: function notifications_success_messages_attachVirtualNetwork(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.attachVirtualNetwork",
          returnKey,
          { name }
        );
      },
      clonePluggableDb: function notifications_success_messages_clonePluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.clonePluggableDb",
          returnKey,
          { name }
        );
      },
      closeSupportRequest: function notifications_success_messages_closeSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.closeSupportRequest",
          returnKey,
          { name }
        );
      },
      createBackup: function notifications_success_messages_createBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.createBackup",
          returnKey,
          { name }
        );
      },
      createHeatWave: function notifications_success_messages_createHeatWave(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.createHeatWave",
          returnKey,
          { name }
        );
      },
      createMcvcn: function notifications_success_messages_createMcvcn(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.createMcvcn",
          returnKey,
          { name }
        );
      },
      createPluggableDb: function notifications_success_messages_createPluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.createPluggableDb",
          returnKey,
          { name }
        );
      },
      createSupportRequest: function notifications_success_messages_createSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.createSupportRequest",
          returnKey,
          {}
        );
      },
      deleteAdbs: function notifications_success_messages_deleteAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteAdbs",
          returnKey,
          { name }
        );
      },
      deleteBackup: function notifications_success_messages_deleteBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteBackup",
          returnKey,
          { name }
        );
      },
      deleteExaDb: function notifications_success_messages_deleteExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteExaDb",
          returnKey,
          { name }
        );
      },
      deleteExaInfra: function notifications_success_messages_deleteExaInfra(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteExaInfra",
          returnKey,
          { name }
        );
      },
      deleteHeatWave: function notifications_success_messages_deleteHeatWave(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteHeatWave",
          returnKey,
          { name }
        );
      },
      deleteMcVcn: function notifications_success_messages_deleteMcVcn(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteMcVcn",
          returnKey,
          { name }
        );
      },
      deleteMysql: function notifications_success_messages_deleteMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteMysql",
          returnKey,
          { name }
        );
      },
      deletePluggableDb: function notifications_success_messages_deletePluggableDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deletePluggableDb",
          returnKey,
          { name }
        );
      },
      deleteVMCluster: function notifications_success_messages_deleteVMCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteVMCluster",
          returnKey,
          { name }
        );
      },
      deleteVMDb: function notifications_success_messages_deleteVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteVMDb",
          returnKey,
          { name }
        );
      },
      deleteVMDbSystem: function notifications_success_messages_deleteVMDbSystem(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deleteVMDbSystem",
          returnKey,
          { name }
        );
      },
      detachVirtualNetwork: function notifications_success_messages_detachVirtualNetwork(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.detachVirtualNetwork",
          returnKey,
          { name }
        );
      },
      deployResource: function notifications_success_messages_deployResource(
        resourceType: string,
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.deployResource",
          returnKey,
          { resourceType, name }
        );
      },
      downloadWallet: function notifications_success_messages_downloadWallet(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.downloadWallet",
          returnKey,
          { name }
        );
      },
      linkSubscription: function notifications_success_messages_linkSubscription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.linkSubscription",
          returnKey,
          { name }
        );
      },
      reopenSupportRequest: function notifications_success_messages_reopenSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.reopenSupportRequest",
          returnKey,
          { name }
        );
      },
      restartAdbs: function notifications_success_messages_restartAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.restartAdbs",
          returnKey,
          { name }
        );
      },
      restartHeatWaveCluster: function notifications_success_messages_restartHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.restartHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      restartMysql: function notifications_success_messages_restartMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.restartMysql",
          returnKey,
          { name }
        );
      },
      restore: function notifications_success_messages_restore(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.restore",
          returnKey,
          { name }
        );
      },
      restoreAdbs: function notifications_success_messages_restoreAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.restoreAdbs",
          returnKey,
          { name }
        );
      },
      restoreFromBackup: function notifications_success_messages_restoreFromBackup(
        name: string,
        backupName: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.restoreFromBackup",
          returnKey,
          { name, backupName }
        );
      },
      rotateWallet: function notifications_success_messages_rotateWallet(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.rotateWallet",
          returnKey,
          { name }
        );
      },
      scaleAdbs: function notifications_success_messages_scaleAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.scaleAdbs",
          returnKey,
          { name }
        );
      },
      scaleExaInfra: function notifications_success_messages_scaleExaInfra(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.scaleExaInfra",
          returnKey,
          { name }
        );
      },
      scaleVmCluster: function notifications_success_messages_scaleVmCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.scaleVmCluster",
          returnKey,
          { name }
        );
      },
      scaleVmDbSystem: function notifications_success_messages_scaleVmDbSystem(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.scaleVmDbSystem",
          returnKey,
          { name }
        );
      },
      startAdbs: function notifications_success_messages_startAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.startAdbs",
          returnKey,
          { name }
        );
      },
      startExaDb: function notifications_success_messages_startExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.startExaDb",
          returnKey,
          { name }
        );
      },
      startHeatWaveCluster: function notifications_success_messages_startHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.startHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      startMysql: function notifications_success_messages_startMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.startMysql",
          returnKey,
          { name }
        );
      },
      startVMDb: function notifications_success_messages_startVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.startVMDb",
          returnKey,
          { name }
        );
      },
      stopAdbs: function notifications_success_messages_stopAdbs(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.stopAdbs",
          returnKey,
          { name }
        );
      },
      stopExaDb: function notifications_success_messages_stopExaDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.stopExaDb",
          returnKey,
          { name }
        );
      },
      stopHeatWaveCluster: function notifications_success_messages_stopHeatWaveCluster(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.stopHeatWaveCluster",
          returnKey,
          { name }
        );
      },
      stopMysql: function notifications_success_messages_stopMysql(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.stopMysql",
          returnKey,
          { name }
        );
      },
      stopVMDb: function notifications_success_messages_stopVMDb(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.stopVMDb",
          returnKey,
          { name }
        );
      },
      submitFeedback: function notifications_success_messages_submitFeedback(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.submitFeedback",
          returnKey,
          {}
        );
      },
      updateAdbsDbName: function notifications_success_messages_updateAdbsDbName(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateAdbsDbName",
          returnKey,
          { name }
        );
      },
      updateAdbsLicense: function notifications_success_messages_updateAdbsLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateAdbsLicense",
          returnKey,
          { name }
        );
      },
      updateAdbsNetworking: function notifications_success_messages_updateAdbsNetworking(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateAdbsNetworking",
          returnKey,
          { name }
        );
      },
      updateAdbsNetworkMutualAuth: function notifications_success_messages_updateAdbsNetworkMutualAuth(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateAdbsNetworkMutualAuth",
          returnKey,
          { name }
        );
      },
      updateAdbsPassword: function notifications_success_messages_updateAdbsPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateAdbsPassword",
          returnKey,
          { name }
        );
      },
      updateExaCdbBackup: function notifications_success_messages_updateExaCdbBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateExaCdbBackup",
          returnKey,
          { name }
        );
      },
      updateExadataVmClusterLicense: function notifications_success_messages_updateExadataVmClusterLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateExadataVmClusterLicense",
          returnKey,
          { name }
        );
      },
      updateHeatWaveNodeCount: function notifications_success_messages_updateHeatWaveNodeCount(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateHeatWaveNodeCount",
          returnKey,
          { name }
        );
      },
      updateMysqlBackup: function notifications_success_messages_updateMysqlBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlBackup",
          returnKey,
          { name }
        );
      },
      updateMysqlBackupDescription: function notifications_success_messages_updateMysqlBackupDescription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlBackupDescription",
          returnKey,
          { name }
        );
      },
      updateMysqlBackupRetentionDays: function notifications_success_messages_updateMysqlBackupRetentionDays(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlBackupRetentionDays",
          returnKey,
          { name }
        );
      },
      updateMysqlCrashRecovery: function notifications_success_messages_updateMysqlCrashRecovery(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlCrashRecovery",
          returnKey,
          { name }
        );
      },
      updateMysqlDataStorageSize: function notifications_success_messages_updateMysqlDataStorageSize(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlDataStorageSize",
          returnKey,
          { name }
        );
      },
      updateMysqlDeletionPlan: function notifications_success_messages_updateMysqlDeletionPlan(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlDeletionPlan",
          returnKey,
          { name }
        );
      },
      updateMysqlDescription: function notifications_success_messages_updateMysqlDescription(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlDescription",
          returnKey,
          { name }
        );
      },
      updateMysqlPassword: function notifications_success_messages_updateMysqlPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlPassword",
          returnKey,
          { name }
        );
      },
      updateMysqlShape: function notifications_success_messages_updateMysqlShape(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlShape",
          returnKey,
          { name }
        );
      },
      updateMysqlVersion: function notifications_success_messages_updateMysqlVersion(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateMysqlVersion",
          returnKey,
          { name }
        );
      },
      updateSupportRequest: function notifications_success_messages_updateSupportRequest(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateSupportRequest",
          returnKey,
          { name }
        );
      },
      updateVmCdbBackup: function notifications_success_messages_updateVmCdbBackup(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateVmCdbBackup",
          returnKey,
          { name }
        );
      },
      updateVmCdbPassword: function notifications_success_messages_updateVmCdbPassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateVmCdbPassword",
          returnKey,
          { name }
        );
      },
      updateVmDbSystemLicense: function notifications_success_messages_updateVmDbSystemLicense(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateVmDbSystemLicense",
          returnKey,
          { name }
        );
      },
      updateVmDbSystemStorage: function notifications_success_messages_updateVmDbSystemStorage(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateVmDbSystemStorage",
          returnKey,
          { name }
        );
      },
      updateVmTdePassword: function notifications_success_messages_updateVmTdePassword(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.updateVmTdePassword",
          returnKey,
          { name }
        );
      },
      upgradeToATP: function notifications_success_messages_upgradeToATP(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.upgradeToATP",
          returnKey,
          { name }
        );
      },
      uploadSupportRequestFile: function notifications_success_messages_uploadSupportRequestFile(
        name: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.messages.uploadSupportRequestFile",
          returnKey,
          { name }
        );
      }
    },
    titles: {
      addCapacityVMCluster: function notifications_success_titles_addCapacityVMCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.addCapacityVMCluster",
          returnKey,
          {}
        );
      },
      addRegion: function notifications_success_titles_addRegion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.addRegion",
          returnKey,
          {}
        );
      },
      addSshKey: function notifications_success_titles_addSshKey(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.addSshKey",
          returnKey,
          {}
        );
      },
      applyTags: function notifications_success_titles_applyTags(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.applyTags",
          returnKey,
          {}
        );
      },
      attachVirtualNetwork: function notifications_success_titles_attachVirtualNetwork(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.attachVirtualNetwork",
          returnKey,
          {}
        );
      },
      clonePluggableDb: function notifications_success_titles_clonePluggableDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.clonePluggableDb",
          returnKey,
          {}
        );
      },
      closeSupportRequest: function notifications_success_titles_closeSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.closeSupportRequest",
          returnKey,
          {}
        );
      },
      createBackup: function notifications_success_titles_createBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.createBackup",
          returnKey,
          {}
        );
      },
      createHeatWave: function notifications_success_titles_createHeatWave(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.createHeatWave",
          returnKey,
          {}
        );
      },
      createMcvcn: function notifications_success_titles_createMcvcn(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.createMcvcn",
          returnKey,
          {}
        );
      },
      createPluggableDb: function notifications_success_titles_createPluggableDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.createPluggableDb",
          returnKey,
          {}
        );
      },
      createSupportRequest: function notifications_success_titles_createSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.createSupportRequest",
          returnKey,
          {}
        );
      },
      deleteAdbs: function notifications_success_titles_deleteAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteAdbs",
          returnKey,
          {}
        );
      },
      deleteBackup: function notifications_success_titles_deleteBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteBackup",
          returnKey,
          {}
        );
      },
      deleteExaDb: function notifications_success_titles_deleteExaDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteExaDb",
          returnKey,
          {}
        );
      },
      deleteExaInfra: function notifications_success_titles_deleteExaInfra(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteExaInfra",
          returnKey,
          {}
        );
      },
      deleteHeatWave: function notifications_success_titles_deleteHeatWave(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteHeatWave",
          returnKey,
          {}
        );
      },
      deleteMcVcn: function notifications_success_titles_deleteMcVcn(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteMcVcn",
          returnKey,
          {}
        );
      },
      deleteMysql: function notifications_success_titles_deleteMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteMysql",
          returnKey,
          {}
        );
      },
      deletePluggableDb: function notifications_success_titles_deletePluggableDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deletePluggableDb",
          returnKey,
          {}
        );
      },
      deleteVMCluster: function notifications_success_titles_deleteVMCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteVMCluster",
          returnKey,
          {}
        );
      },
      deleteVMDb: function notifications_success_titles_deleteVMDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteVMDb",
          returnKey,
          {}
        );
      },
      deleteVMDbSystem: function notifications_success_titles_deleteVMDbSystem(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deleteVMDbSystem",
          returnKey,
          {}
        );
      },
      detachVirtualNetwork: function notifications_success_titles_detachVirtualNetwork(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.detachVirtualNetwork",
          returnKey,
          {}
        );
      },
      deployResource: function notifications_success_titles_deployResource(
        resourceType: string,
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.deployResource",
          returnKey,
          { resourceType }
        );
      },
      downloadWallet: function notifications_success_titles_downloadWallet(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.downloadWallet",
          returnKey,
          {}
        );
      },
      linkSubscription: function notifications_success_titles_linkSubscription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.linkSubscription",
          returnKey,
          {}
        );
      },
      reopenSupportRequest: function notifications_success_titles_reopenSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.reopenSupportRequest",
          returnKey,
          {}
        );
      },
      restartAdbs: function notifications_success_titles_restartAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.restartAdbs",
          returnKey,
          {}
        );
      },
      restartHeatWaveCluster: function notifications_success_titles_restartHeatWaveCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.restartHeatWaveCluster",
          returnKey,
          {}
        );
      },
      restartMysql: function notifications_success_titles_restartMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.restartMysql",
          returnKey,
          {}
        );
      },
      restore: function notifications_success_titles_restore(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.restore",
          returnKey,
          {}
        );
      },
      restoreAdbs: function notifications_success_titles_restoreAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.restoreAdbs",
          returnKey,
          {}
        );
      },
      rotateWallet: function notifications_success_titles_rotateWallet(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.rotateWallet",
          returnKey,
          {}
        );
      },
      scaleAdbs: function notifications_success_titles_scaleAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.scaleAdbs",
          returnKey,
          {}
        );
      },
      scaleExaInfra: function notifications_success_titles_scaleExaInfra(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.scaleExaInfra",
          returnKey,
          {}
        );
      },
      scaleVmCluster: function notifications_success_titles_scaleVmCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.scaleVmCluster",
          returnKey,
          {}
        );
      },
      scaleVmDbSystem: function notifications_success_titles_scaleVmDbSystem(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.scaleVmDbSystem",
          returnKey,
          {}
        );
      },
      startAdbs: function notifications_success_titles_startAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.startAdbs",
          returnKey,
          {}
        );
      },
      startExaDb: function notifications_success_titles_startExaDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.startExaDb",
          returnKey,
          {}
        );
      },
      startHeatWaveCluster: function notifications_success_titles_startHeatWaveCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.startHeatWaveCluster",
          returnKey,
          {}
        );
      },
      startMysql: function notifications_success_titles_startMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.startMysql",
          returnKey,
          {}
        );
      },
      startVMDb: function notifications_success_titles_startVMDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.startVMDb",
          returnKey,
          {}
        );
      },
      stopAdbs: function notifications_success_titles_stopAdbs(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.stopAdbs",
          returnKey,
          {}
        );
      },
      stopExaDb: function notifications_success_titles_stopExaDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.stopExaDb",
          returnKey,
          {}
        );
      },
      stopHeatWaveCluster: function notifications_success_titles_stopHeatWaveCluster(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.stopHeatWaveCluster",
          returnKey,
          {}
        );
      },
      stopMysql: function notifications_success_titles_stopMysql(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.stopMysql",
          returnKey,
          {}
        );
      },
      stopVMDb: function notifications_success_titles_stopVMDb(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.stopVMDb",
          returnKey,
          {}
        );
      },
      submitFeedback: function notifications_success_titles_submitFeedback(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.submitFeedback",
          returnKey,
          {}
        );
      },
      updateAdbsDbName: function notifications_success_titles_updateAdbsDbName(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateAdbsDbName",
          returnKey,
          {}
        );
      },
      updateAdbsLicense: function notifications_success_titles_updateAdbsLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateAdbsLicense",
          returnKey,
          {}
        );
      },
      updateAdbsNetworking: function notifications_success_titles_updateAdbsNetworking(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateAdbsNetworking",
          returnKey,
          {}
        );
      },
      updateAdbsNetworkMutualAuth: function notifications_success_titles_updateAdbsNetworkMutualAuth(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateAdbsNetworkMutualAuth",
          returnKey,
          {}
        );
      },
      updateAdbsPassword: function notifications_success_titles_updateAdbsPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateAdbsPassword",
          returnKey,
          {}
        );
      },
      updateExaCdbBackup: function notifications_success_titles_updateExaCdbBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateExaCdbBackup",
          returnKey,
          {}
        );
      },
      updateExadataVmClusterLicense: function notifications_success_titles_updateExadataVmClusterLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateExadataVmClusterLicense",
          returnKey,
          {}
        );
      },
      updateHeatWaveNodeCount: function notifications_success_titles_updateHeatWaveNodeCount(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateHeatWaveNodeCount",
          returnKey,
          {}
        );
      },
      updateMysqlBackup: function notifications_success_titles_updateMysqlBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlBackup",
          returnKey,
          {}
        );
      },
      updateMysqlBackupDescription: function notifications_success_titles_updateMysqlBackupDescription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlBackupDescription",
          returnKey,
          {}
        );
      },
      updateMysqlBackupRetentionDays: function notifications_success_titles_updateMysqlBackupRetentionDays(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlBackupRetentionDays",
          returnKey,
          {}
        );
      },
      updateMysqlCrashRecovery: function notifications_success_titles_updateMysqlCrashRecovery(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlCrashRecovery",
          returnKey,
          {}
        );
      },
      updateMysqlDataStorageSize: function notifications_success_titles_updateMysqlDataStorageSize(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlDataStorageSize",
          returnKey,
          {}
        );
      },
      updateMysqlDeletionPlan: function notifications_success_titles_updateMysqlDeletionPlan(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlDeletionPlan",
          returnKey,
          {}
        );
      },
      updateMysqlDescription: function notifications_success_titles_updateMysqlDescription(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlDescription",
          returnKey,
          {}
        );
      },
      updateMysqlPassword: function notifications_success_titles_updateMysqlPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlPassword",
          returnKey,
          {}
        );
      },
      updateMysqlShape: function notifications_success_titles_updateMysqlShape(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlShape",
          returnKey,
          {}
        );
      },
      updateMysqlVersion: function notifications_success_titles_updateMysqlVersion(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateMysqlVersion",
          returnKey,
          {}
        );
      },
      updateSupportRequest: function notifications_success_titles_updateSupportRequest(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateSupportRequest",
          returnKey,
          {}
        );
      },
      updateVmCdbBackup: function notifications_success_titles_updateVmCdbBackup(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateVmCdbBackup",
          returnKey,
          {}
        );
      },
      updateVmCdbPassword: function notifications_success_titles_updateVmCdbPassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateVmCdbPassword",
          returnKey,
          {}
        );
      },
      updateVmDbSystemLicense: function notifications_success_titles_updateVmDbSystemLicense(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateVmDbSystemLicense",
          returnKey,
          {}
        );
      },
      updateVmDbSystemStorage: function notifications_success_titles_updateVmDbSystemStorage(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateVmDbSystemStorage",
          returnKey,
          {}
        );
      },
      updateVmTdePassword: function notifications_success_titles_updateVmTdePassword(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.updateVmTdePassword",
          returnKey,
          {}
        );
      },
      upgradeToATP: function notifications_success_titles_upgradeToATP(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.upgradeToATP",
          returnKey,
          {}
        );
      },
      uploadSupportRequestFile: function notifications_success_titles_uploadSupportRequestFile(
        returnKey?: boolean
      ): string {
        return shouldReturnKey(
          "notifications.success.titles.uploadSupportRequestFile",
          returnKey,
          {}
        );
      }
    }
  },
  watermarkMessage: function notifications_watermarkMessage(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("notifications.watermarkMessage", returnKey, {});
  },
  when: {
    dayAgo: function notifications_when_dayAgo(returnKey?: boolean): string {
      return shouldReturnKey("notifications.when.dayAgo", returnKey, {});
    },
    daysAgo: function notifications_when_daysAgo(
      day: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.daysAgo", returnKey, { day });
    },
    hourAgo: function notifications_when_hourAgo(returnKey?: boolean): string {
      return shouldReturnKey("notifications.when.hourAgo", returnKey, {});
    },
    hoursAgo: function notifications_when_hoursAgo(
      hour: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.hoursAgo", returnKey, {
        hour
      });
    },
    minuteAgo: function notifications_when_minuteAgo(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.minuteAgo", returnKey, {});
    },
    minutesAgo: function notifications_when_minutesAgo(
      minute: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.minutesAgo", returnKey, {
        minute
      });
    },
    monthAgo: function notifications_when_monthAgo(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.monthAgo", returnKey, {});
    },
    monthsAgo: function notifications_when_monthsAgo(
      month: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.monthsAgo", returnKey, {
        month
      });
    },
    secondsAgo: function notifications_when_secondsAgo(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.secondsAgo", returnKey, {});
    },
    weekAgo: function notifications_when_weekAgo(returnKey?: boolean): string {
      return shouldReturnKey("notifications.when.weekAgo", returnKey, {});
    },
    weeksAgo: function notifications_when_weeksAgo(
      week: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.weeksAgo", returnKey, {
        week
      });
    },
    yearsAgo: function notifications_when_yearsAgo(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("notifications.when.yearsAgo", returnKey, {});
    }
  }
};

export const openModes = {
  migrate: function openModes_migrate(returnKey?: boolean): string {
    return shouldReturnKey("openModes.migrate", returnKey, {});
  },
  mounted: function openModes_mounted(returnKey?: boolean): string {
    return shouldReturnKey("openModes.mounted", returnKey, {});
  },
  readOnly: function openModes_readOnly(returnKey?: boolean): string {
    return shouldReturnKey("openModes.readOnly", returnKey, {});
  },
  readWrite: function openModes_readWrite(returnKey?: boolean): string {
    return shouldReturnKey("openModes.readWrite", returnKey, {});
  }
};

export const portalBrand = {
  long: function portalBrand_long(returnKey?: boolean): string {
    return shouldReturnKey("portalBrand.long", returnKey, {});
  },
  short: function portalBrand_short(returnKey?: boolean): string {
    return shouldReturnKey("portalBrand.short", returnKey, {});
  }
};

export const promoAlertModal = {
  exadataDatabaseNotAvailable: function promoAlertModal_exadataDatabaseNotAvailable(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "promoAlertModal.exadataDatabaseNotAvailable",
      returnKey,
      {}
    );
  },
  exadataDatabaseCannotCreated: function promoAlertModal_exadataDatabaseCannotCreated(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "promoAlertModal.exadataDatabaseCannotCreated",
      returnKey,
      {}
    );
  }
};

export const resourceErrors = {
  invalidProvider: function resourceErrors_invalidProvider(
    provider: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("resourceErrors.invalidProvider", returnKey, {
      provider
    });
  },
  invalidResourceType: function resourceErrors_invalidResourceType(
    resourceType: string,
    provider: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("resourceErrors.invalidResourceType", returnKey, {
      resourceType,
      provider
    });
  },
  resourceNotFound: function resourceErrors_resourceNotFound(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("resourceErrors.resourceNotFound", returnKey, {});
  }
};

export const resourceTypes = {
  adbs: function resourceTypes_adbs(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.adbs", returnKey, {});
  },
  deployment: function resourceTypes_deployment(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.deployment", returnKey, {});
  },
  exaDb: function resourceTypes_exaDb(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.exaDb", returnKey, {});
  },
  exaInfra: function resourceTypes_exaInfra(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.exaInfra", returnKey, {});
  },
  exaVmCluster: function resourceTypes_exaVmCluster(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("resourceTypes.exaVmCluster", returnKey, {});
  },
  mcvcn: function resourceTypes_mcvcn(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.mcvcn", returnKey, {});
  },
  mysql: function resourceTypes_mysql(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.mysql", returnKey, {});
  },
  mysqlBackup: function resourceTypes_mysqlBackup(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.mysqlBackup", returnKey, {});
  },
  vmDb: function resourceTypes_vmDb(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.vmDb", returnKey, {});
  },
  vmDbSystem: function resourceTypes_vmDbSystem(returnKey?: boolean): string {
    return shouldReturnKey("resourceTypes.vmDbSystem", returnKey, {});
  }
};

export const satisfaction = {
  extremelyNotSatisfied: function satisfaction_extremelyNotSatisfied(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("satisfaction.extremelyNotSatisfied", returnKey, {});
  },
  extremelySatisfied: function satisfaction_extremelySatisfied(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("satisfaction.extremelySatisfied", returnKey, {});
  },
  neutral: function satisfaction_neutral(returnKey?: boolean): string {
    return shouldReturnKey("satisfaction.neutral", returnKey, {});
  },
  notSatisfied: function satisfaction_notSatisfied(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("satisfaction.notSatisfied", returnKey, {});
  },
  satisfied: function satisfaction_satisfied(returnKey?: boolean): string {
    return shouldReturnKey("satisfaction.satisfied", returnKey, {});
  }
};

export const serviceProviders = {
  adbShared: function serviceProviders_adbShared(returnKey?: boolean): string {
    return shouldReturnKey("serviceProviders.adbShared", returnKey, {});
  },
  cloudlink: function serviceProviders_cloudlink(returnKey?: boolean): string {
    return shouldReturnKey("serviceProviders.cloudlink", returnKey, {});
  },
  exadataDedicated: function serviceProviders_exadataDedicated(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("serviceProviders.exadataDedicated", returnKey, {});
  },
  mySqlHeatwave: function serviceProviders_mySqlHeatwave(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("serviceProviders.mySqlHeatwave", returnKey, {});
  },
  network: function serviceProviders_network(returnKey?: boolean): string {
    return shouldReturnKey("serviceProviders.network", returnKey, {});
  },
  vmDatabase: function serviceProviders_vmDatabase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("serviceProviders.vmDatabase", returnKey, {});
  }
};

export const softwareEditionOptions = {
  enterpriseEdition: function softwareEditionOptions_enterpriseEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "softwareEditionOptions.enterpriseEdition",
      returnKey,
      {}
    );
  },
  enterpriseEditionExtremePerformance: function softwareEditionOptions_enterpriseEditionExtremePerformance(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "softwareEditionOptions.enterpriseEditionExtremePerformance",
      returnKey,
      {}
    );
  },
  enterpriseEditionHighPerformance: function softwareEditionOptions_enterpriseEditionHighPerformance(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "softwareEditionOptions.enterpriseEditionHighPerformance",
      returnKey,
      {}
    );
  },
  standardEdition: function softwareEditionOptions_standardEdition(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "softwareEditionOptions.standardEdition",
      returnKey,
      {}
    );
  }
};

export const sshKeySources = {
  generateNewKeyPair: function sshKeySources_generateNewKeyPair(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("sshKeySources.generateNewKeyPair", returnKey, {});
  },
  uploadExistingPublicKey: function sshKeySources_uploadExistingPublicKey(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "sshKeySources.uploadExistingPublicKey",
      returnKey,
      {}
    );
  }
};

export const storageManagements = {
  asm: function storageManagements_asm(returnKey?: boolean): string {
    return shouldReturnKey("storageManagements.asm", returnKey, {});
  },
  lvm: function storageManagements_lvm(returnKey?: boolean): string {
    return shouldReturnKey("storageManagements.lvm", returnKey, {});
  }
};

export const storagePerformanceModeOptions = {
  balanced: function storagePerformanceModeOptions_balanced(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "storagePerformanceModeOptions.balanced",
      returnKey,
      {}
    );
  },
  higherPerformance: function storagePerformanceModeOptions_higherPerformance(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "storagePerformanceModeOptions.higherPerformance",
      returnKey,
      {}
    );
  }
};

export const subscriptionStatus = {
  failed: function subscriptionStatus_failed(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionStatus.failed", returnKey, {});
  },
  inProgress: function subscriptionStatus_inProgress(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("subscriptionStatus.inProgress", returnKey, {});
  },
  linked: function subscriptionStatus_linked(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionStatus.linked", returnKey, {});
  },
  linking: function subscriptionStatus_linking(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionStatus.linking", returnKey, {});
  },
  succeeded: function subscriptionStatus_succeeded(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("subscriptionStatus.succeeded", returnKey, {});
  },
  unlinked: function subscriptionStatus_unlinked(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionStatus.unlinked", returnKey, {});
  },
  updating: function subscriptionStatus_updating(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionStatus.updating", returnKey, {});
  }
};

export const supportIssueTypes = {
  billing: function supportIssueTypes_billing(returnKey?: boolean): string {
    return shouldReturnKey("supportIssueTypes.billing", returnKey, {});
  },
  quotas: function supportIssueTypes_quotas(returnKey?: boolean): string {
    return shouldReturnKey("supportIssueTypes.quotas", returnKey, {});
  },
  quotasShort: function supportIssueTypes_quotasShort(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("supportIssueTypes.quotasShort", returnKey, {});
  },
  technical: function supportIssueTypes_technical(returnKey?: boolean): string {
    return shouldReturnKey("supportIssueTypes.technical", returnKey, {});
  }
};

export const supportIssueStateTypes = {
  closed: function supportIssueStateTypes_closed(returnKey?: boolean): string {
    return shouldReturnKey("supportIssueStateTypes.closed", returnKey, {});
  },
  closeRequested: function supportIssueStateTypes_closeRequested(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportIssueStateTypes.closeRequested",
      returnKey,
      {}
    );
  },
  pendingCustomer: function supportIssueStateTypes_pendingCustomer(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportIssueStateTypes.pendingCustomer",
      returnKey,
      {}
    );
  },
  pendingOracle: function supportIssueStateTypes_pendingOracle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportIssueStateTypes.pendingOracle",
      returnKey,
      {}
    );
  }
};

export const supportItemTypes = {
  activityAttachmentTitle: function supportItemTypes_activityAttachmentTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.activityAttachmentTitle",
      returnKey,
      {}
    );
  },
  activityCloseTitle: function supportItemTypes_activityCloseTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.activityCloseTitle",
      returnKey,
      {}
    );
  },
  activityNotesTitle: function supportItemTypes_activityNotesTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.activityNotesTitle",
      returnKey,
      {}
    );
  },
  activityProblemDescriptionTitle: function supportItemTypes_activityProblemDescriptionTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.activityProblemDescriptionTitle",
      returnKey,
      {}
    );
  },
  activityReopenTitle: function supportItemTypes_activityReopenTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.activityReopenTitle",
      returnKey,
      {}
    );
  },
  activityTitle: function supportItemTypes_activityTitle(
    status: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("supportItemTypes.activityTitle", returnKey, {
      status
    });
  },
  activityUpdateTitle: function supportItemTypes_activityUpdateTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.activityUpdateTitle",
      returnKey,
      {}
    );
  },
  limitApprovedTitle: function supportItemTypes_limitApprovedTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.limitApprovedTitle",
      returnKey,
      {}
    );
  },
  limitNotApprovedTitle: function supportItemTypes_limitNotApprovedTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.limitNotApprovedTitle",
      returnKey,
      {}
    );
  },
  limitPatiallyApprovedTitle: function supportItemTypes_limitPatiallyApprovedTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "supportItemTypes.limitPatiallyApprovedTitle",
      returnKey,
      {}
    );
  },
  limitPendingTitle: function supportItemTypes_limitPendingTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("supportItemTypes.limitPendingTitle", returnKey, {});
  },
  limitTitle: function supportItemTypes_limitTitle(
    status: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("supportItemTypes.limitTitle", returnKey, {
      status
    });
  },
  technicalTitle: function supportItemTypes_technicalTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("supportItemTypes.technicalTitle", returnKey, {});
  }
};

export const supportSeverity = {
  high: function supportSeverity_high(returnKey?: boolean): string {
    return shouldReturnKey("supportSeverity.high", returnKey, {});
  },
  highest: function supportSeverity_highest(returnKey?: boolean): string {
    return shouldReturnKey("supportSeverity.highest", returnKey, {});
  },
  low: function supportSeverity_low(returnKey?: boolean): string {
    return shouldReturnKey("supportSeverity.low", returnKey, {});
  },
  medium: function supportSeverity_medium(returnKey?: boolean): string {
    return shouldReturnKey("supportSeverity.medium", returnKey, {});
  }
};

export const tags = {
  description: function tags_description(returnKey?: boolean): string {
    return shouldReturnKey("tags.description", returnKey, {});
  },
  info: {
    learnMore: function tags_info_learnMore(returnKey?: boolean): string {
      return shouldReturnKey("tags.info.learnMore", returnKey, {});
    }
  },
  note: function tags_note(returnKey?: boolean): string {
    return shouldReturnKey("tags.note", returnKey, {});
  },
  title: function tags_title(returnKey?: boolean): string {
    return shouldReturnKey("tags.title", returnKey, {});
  }
};

export const topHeader = {
  cloudShell: function topHeader_cloudShell(returnKey?: boolean): string {
    return shouldReturnKey("topHeader.cloudShell", returnKey, {});
  },
  directoriesSubscriptions: function topHeader_directoriesSubscriptions(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topHeader.directoriesSubscriptions", returnKey, {});
  },
  feedback: function topHeader_feedback(returnKey?: boolean): string {
    return shouldReturnKey("topHeader.feedback", returnKey, {});
  },
  help: function topHeader_help(returnKey?: boolean): string {
    return shouldReturnKey("topHeader.help", returnKey, {});
  },
  hidePortalMenu: function topHeader_hidePortalMenu(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topHeader.hidePortalMenu", returnKey, {});
  },
  notifications: function topHeader_notifications(returnKey?: boolean): string {
    return shouldReturnKey("topHeader.notifications", returnKey, {});
  },
  settings: function topHeader_settings(returnKey?: boolean): string {
    return shouldReturnKey("topHeader.settings", returnKey, {});
  },
  showPortalMenu: function topHeader_showPortalMenu(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topHeader.showPortalMenu", returnKey, {});
  },
  signOut: function topHeader_signOut(returnKey?: boolean): string {
    return shouldReturnKey("topHeader.signOut", returnKey, {});
  }
};

export const topServices = {
  apex: function topServices_apex(returnKey?: boolean): string {
    return shouldReturnKey("topServices.apex", returnKey, {});
  },
  autonomousDatabases: function topServices_autonomousDatabases(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topServices.autonomousDatabases", returnKey, {});
  },
  autonomousSharedDatabase: function topServices_autonomousSharedDatabase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "topServices.autonomousSharedDatabase",
      returnKey,
      {}
    );
  },
  billing: function topServices_billing(returnKey?: boolean): string {
    return shouldReturnKey("topServices.billing", returnKey, {});
  },
  deployments: function topServices_deployments(returnKey?: boolean): string {
    return shouldReturnKey("topServices.deployments", returnKey, {});
  },
  exadata: function topServices_exadata(returnKey?: boolean): string {
    return shouldReturnKey("topServices.exadata", returnKey, {});
  },
  exadataDatabases: function topServices_exadataDatabases(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topServices.exadataDatabases", returnKey, {});
  },
  exadataInfras: function topServices_exadataInfras(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topServices.exadataInfras", returnKey, {});
  },
  exadataSystems: function topServices_exadataSystems(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topServices.exadataSystems", returnKey, {});
  },
  exadataVmClusters: function topServices_exadataVmClusters(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topServices.exadataVmClusters", returnKey, {});
  },
  mysqlDatabase: function topServices_mysqlDatabase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topServices.mysqlDatabase", returnKey, {});
  },
  mcvcn: function topServices_mcvcn(returnKey?: boolean): string {
    return shouldReturnKey("topServices.mcvcn", returnKey, {});
  },
  subscriptionManagement: function topServices_subscriptionManagement(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("topServices.subscriptionManagement", returnKey, {});
  },
  support: function topServices_support(returnKey?: boolean): string {
    return shouldReturnKey("topServices.support", returnKey, {});
  },
  vmDatabase: function topServices_vmDatabase(returnKey?: boolean): string {
    return shouldReturnKey("topServices.vmDatabase", returnKey, {});
  },
  vmDatabases: function topServices_vmDatabases(returnKey?: boolean): string {
    return shouldReturnKey("topServices.vmDatabases", returnKey, {});
  }
};

export const trialAccountInfo = {
  promo: function trialAccountInfo_promo(returnKey?: boolean): string {
    return shouldReturnKey("trialAccountInfo.promo", returnKey, {});
  },
  promoConsumed: function trialAccountInfo_promoConsumed(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("trialAccountInfo.promoConsumed", returnKey, {});
  },
  promoExpired: function trialAccountInfo_promoExpired(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("trialAccountInfo.promoExpired", returnKey, {});
  }
};

export const updateSupportRequest = {
  title: function updateSupportRequest_title(returnKey?: boolean): string {
    return shouldReturnKey("updateSupportRequest.title", returnKey, {});
  }
};

export const uploadSupportRequestFile = {
  title: function uploadSupportRequestFile_title(returnKey?: boolean): string {
    return shouldReturnKey("uploadSupportRequestFile.title", returnKey, {});
  }
};

export const validation = {
  addressOverlap: function validation_addressOverlap(
    address1: string,
    address2: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.addressOverlap", returnKey, {
      address1,
      address2
    });
  },
  addressWithinRange: function validation_addressWithinRange(
    cidr: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.addressWithinRange", returnKey, {
      cidr
    });
  },
  addressWithinRanges: function validation_addressWithinRanges(
    cidrs: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.addressWithinRanges", returnKey, {
      cidrs
    });
  },
  dbNameValidation: function validation_dbNameValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.dbNameValidation", returnKey, {});
  },
  dbPasswordValidation: function validation_dbPasswordValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.dbPasswordValidation", returnKey, {});
  },
  deleteExaInfraCb: function validation_deleteExaInfraCb(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.deleteExaInfraCb", returnKey, {});
  },
  inputRange: function validation_inputRange(
    minValue: string,
    maxValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.inputRange", returnKey, {
      minValue,
      maxValue
    });
  },
  integerMax: function validation_integerMax(
    maxValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.integerMax", returnKey, { maxValue });
  },
  integerStepMin: function validation_integerStepMin(
    minValue: string,
    stepValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.integerStepMin", returnKey, {
      minValue,
      stepValue
    });
  },
  integerZeroOrMinRange: function validation_integerZeroOrMinRange(
    minValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.integerZeroOrMinRange", returnKey, {
      minValue
    });
  },
  integerZeroOrRange: function validation_integerZeroOrRange(
    minValue: string,
    maxValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.integerZeroOrRange", returnKey, {
      minValue,
      maxValue
    });
  },
  invalidCidrPrefixRange: function validation_invalidCidrPrefixRange(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.invalidCidrPrefixRange", returnKey, {});
  },
  invalidCustomDuration: function validation_invalidCustomDuration(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.invalidCustomDuration", returnKey, {});
  },
  mdsAdminUserNameCharactersNotAllowedValidation: function validation_mdsAdminUserNameCharactersNotAllowedValidation(
    characters: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validation.mdsAdminUserNameCharactersNotAllowedValidation",
      returnKey,
      { characters }
    );
  },
  mdsAdminUserNameLengthValidation: function validation_mdsAdminUserNameLengthValidation(
    minValue: string,
    maxValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validation.mdsAdminUserNameLengthValidation",
      returnKey,
      { minValue, maxValue }
    );
  },
  mdsAdminUserNameReservedNameValidation: function validation_mdsAdminUserNameReservedNameValidation(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validation.mdsAdminUserNameReservedNameValidation",
      returnKey,
      { name }
    );
  },
  mdsDescriptionValidation: function validation_mdsDescriptionValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validation.mdsDescriptionValidation",
      returnKey,
      {}
    );
  },
  mdsHostnameValidation: function validation_mdsHostnameValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.mdsHostnameValidation", returnKey, {});
  },
  nameChars: function validation_nameChars(
    minValue: string,
    maxValue: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.nameChars", returnKey, {
      minValue,
      maxValue
    });
  },
  nameStartChar: function validation_nameStartChar(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.nameStartChar", returnKey, {});
  },
  nameStartAplhaNumeric: function validation_nameStartAplhaNumeric(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.nameStartAplhaNumeric", returnKey, {});
  },
  nameValidation: function validation_nameValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.nameValidation", returnKey, {});
  },
  networkLinkNameValidation: function validation_networkLinkNameValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validation.networkLinkNameValidation",
      returnKey,
      {}
    );
  },
  passwordConfirmation: function validation_passwordConfirmation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.passwordConfirmation", returnKey, {});
  },
  passwordValidation: function validation_passwordValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.passwordValidation", returnKey, {});
  },
  passwordValidationMysql: function validation_passwordValidationMysql(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.passwordValidationMysql", returnKey, {});
  },
  quotaLimitUnavailable: function validation_quotaLimitUnavailable(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.quotaLimitUnavailable", returnKey, {});
  },
  required: function validation_required(returnKey?: boolean): string {
    return shouldReturnKey("validation.required", returnKey, {});
  },
  roleMissingForCreate: function validation_roleMissingForCreate(
    role: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.roleMissingForCreate", returnKey, {
      role
    });
  },
  roleMissingForList: function validation_roleMissingForList(
    role: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.roleMissingForList", returnKey, {
      role
    });
  },
  sameDbNames: function validation_sameDbNames(returnKey?: boolean): string {
    return shouldReturnKey("validation.sameDbNames", returnKey, {});
  },
  subStringDbName: function validation_subStringDbName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.subStringDbName", returnKey, {});
  },
  tagKeyInvalidChars: function validation_tagKeyInvalidChars(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.tagKeyInvalidChars", returnKey, {});
  },
  tagKeyRequiredWithValue: function validation_tagKeyRequiredWithValue(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.tagKeyRequiredWithValue", returnKey, {});
  },
  tagKeyRequiredNoValue: function validation_tagKeyRequiredNoValue(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.tagKeyRequiredNoValue", returnKey, {});
  },
  tagKeyUnique: function validation_tagKeyUnique(
    tagName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.tagKeyUnique", returnKey, { tagName });
  },
  tagResourceTypeRequired: function validation_tagResourceTypeRequired(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.tagResourceTypeRequired", returnKey, {});
  },
  unableToValidate: function validation_unableToValidate(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.unableToValidate", returnKey, {});
  },
  unique: function validation_unique(returnKey?: boolean): string {
    return shouldReturnKey("validation.unique", returnKey, {});
  },
  valueAlphaNumeric: function validation_valueAlphaNumeric(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.valueAlphaNumeric", returnKey, {});
  },
  valueAlphaNumericHyphen: function validation_valueAlphaNumericHyphen(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.valueAlphaNumericHyphen", returnKey, {});
  },
  valueAlphaNumericUnderscore: function validation_valueAlphaNumericUnderscore(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validation.valueAlphaNumericUnderscore",
      returnKey,
      {}
    );
  },
  valueMaxLen: function validation_valueMaxLen(
    length: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.valueMaxLen", returnKey, { length });
  },
  versionValidationMysql: function validation_versionValidationMysql(
    value: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validation.versionValidationMysql", returnKey, {
      value
    });
  },
  walletPasswordValidation: function validation_walletPasswordValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validation.walletPasswordValidation",
      returnKey,
      {}
    );
  }
};

export const workloadTypes = {
  apex: function workloadTypes_apex(returnKey?: boolean): string {
    return shouldReturnKey("workloadTypes.apex", returnKey, {});
  },
  dataWarehouse: function workloadTypes_dataWarehouse(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("workloadTypes.dataWarehouse", returnKey, {});
  },
  json: function workloadTypes_json(returnKey?: boolean): string {
    return shouldReturnKey("workloadTypes.json", returnKey, {});
  },
  transactionProcessing: function workloadTypes_transactionProcessing(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "workloadTypes.transactionProcessing",
      returnKey,
      {}
    );
  }
};
