import { FormattedString, InfoBlockLayout, InfoBlockStatus, Select, SelectOption } from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { oracleDbEELearnMoreUrl, oracleDbSELearnMoreUrl } from "../../constants/docConstants";
import { DATABASE_EDITION_TYPE } from "../../helpers/resourceHelper";

export interface DatabaseEditionSelectProps {
  defaultValue?: string;
  fieldName: string;
  label?: string;
  disabled?: boolean;
  testId?: string;
  isOcpuModel?: boolean;
  onChange?: (id: string) => void;
}

function getDbEditionTypes(): SelectOption[] {
  return [
    {
      id: DATABASE_EDITION_TYPE.ENTERPRISE_EDITION,
      text: Messages.dbEditionOptions.enterpriseEdition(),
    },
    {
      id: DATABASE_EDITION_TYPE.STANDARD_EDITION,
      text: Messages.dbEditionOptions.standardEdition(),
    },
  ] as SelectOption[];
}

function dbEditionStatusInfo(oracleDbEeElement: JSX.Element, oracleDbSeElement: JSX.Element): JSX.Element {
  return (
    <>
      {oracleDbEeElement}
      <br />
      {oracleDbSeElement}
    </>
  );
}

export const DatabaseEditionSelect = (
  { defaultValue, fieldName, label = Messages.labels.oracleDatabaseEdition(), disabled, testId, onChange, isOcpuModel }
    : DatabaseEditionSelectProps,
): JSX.Element => {
  const dbEditions = getDbEditionTypes();
  const oracleDbEE = FormattedString({ inputText: Messages.hints.oracleDbEE(oracleDbEELearnMoreUrl) });
  const oracleDbSE = FormattedString({ inputText: Messages.hints.oracleDbSE(oracleDbSELearnMoreUrl) });
  const ecpuOracleDbEE = FormattedString({ inputText: Messages.hints.ecpuOracleDbEE(oracleDbEELearnMoreUrl) });
  const ecpuOracleDbSE = FormattedString({ inputText: Messages.hints.ecpuOracleDbSE(oracleDbSELearnMoreUrl) });

  return (
    <Select
      required
      testId={testId}
      fieldName={fieldName}
      label={label}
      options={dbEditions}
      defaultValue={defaultValue ? [defaultValue] : undefined}
      statusInfo={disabled ? undefined : {
        message: isOcpuModel ? dbEditionStatusInfo(oracleDbEE, oracleDbSE) : dbEditionStatusInfo(ecpuOracleDbEE, ecpuOracleDbSE),
        messageType: InfoBlockStatus.INFO,
        infoLayout: InfoBlockLayout.Compact,
      }}
      disabled={disabled}
      hideSearchBox
      onChange={onChange}
    />
  );
};
