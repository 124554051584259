import {
  CalloutComponent,
  CheckBox,
  FormValues,
  getValue,
  InputFormCallout,
  InputFormGroup,
  IntegerInput,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import apiClients from "../../apiClients";
import * as Messages from "../../codegen/Messages";
import { CalloutTestIds } from "../../constants/uiConstants";
import { parseId } from "../../helpers/idHelper";
import { useMutationCall } from "../../hooks/useMutationCall";
import {
  ADBS_UPDATE_POLL_DELAY,
  ADBS_UPDATE_POLL_INTERVAL,
  AsyncNotificationMethodKey,
  AsyncNotificationPolledResponseKey,
} from "../../models/AsyncNotificationProviders";
import { getOciRegion } from "../../utils";
import { OperationActionProps } from "../OperationTypes";
import {
  adbsMaxECPUCount,
  adbsMaxOCPUCount,
  adbsMaxStorage,
  adbsMinECPUCount,
  adbsMinOCPUCount,
  adbsMinStorage,
} from "../../helpers/resourceHelper";

export interface AdbsScaleValues {
  isAutoScalingEnabled: boolean | undefined,
  cpuCoreCount: number | undefined,
  isOcpuModel: boolean | undefined,
  dataStorageSizeInTBs: number | undefined,
}

export interface AdbsScaleProps extends OperationActionProps {
  databaseId: string | undefined;
  adbsScaleValues: AdbsScaleValues;
}

enum Fields {
  AutoScaling = "autoScaling",
  CoreCount = "coreCount",
  StorageSize = "storageSize",
}

export enum FieldTestIds {
  AutoScaling = "auto-scaling-field",
  CoreCount = "core-count-field",
  StorageSize = "storage-size-field"
}

const AdbsScale = (
  { targetId, location, databaseId, adbsScaleValues, onExecute, onCancel }: AdbsScaleProps,
): JSX.Element => {
  const { subscriptionId, resourceGroup, resourceName } = parseId(databaseId);
  const resGroup = decodeURIComponent(resourceGroup || "");
  const resName = decodeURIComponent(resourceName || "");
  const [ocpuCalloutRef, setOcpuCalloutRef] = React.useState<CalloutComponent>({} as CalloutComponent);

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (adbsScaleValues) {
      const initFieldValues: FormValues = {
        [Fields.AutoScaling]: adbsScaleValues.isAutoScalingEnabled,
        // eslint-disable-next-line eqeqeq
        [Fields.CoreCount]: adbsScaleValues.cpuCoreCount != undefined
          ? Number(adbsScaleValues.cpuCoreCount).toString()
          : undefined,
        // eslint-disable-next-line eqeqeq
        [Fields.StorageSize]: adbsScaleValues.dataStorageSizeInTBs != undefined
          ? Number(adbsScaleValues.dataStorageSizeInTBs).toString()
          : undefined,
      };
      setInitialValues(initFieldValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adbsScaleValues]);

  const { invokeCall: invokeAdbsUpdate } = useMutationCall(
    { method: apiClients.withRegion(getOciRegion(location)).adbsDatabaseApi.updateAdbsDatabase },
  );

  const onScaleAdbs = (formValues: FormValues): void => {
    const newCpuCoreCount = getValue<string>(formValues, Fields.CoreCount, InputFormGroup);
    const cpuCoreCount = newCpuCoreCount ? parseInt(newCpuCoreCount, 10) : 0;
    const newDataStorageSizeInTBs = getValue<string>(formValues, Fields.StorageSize, InputFormGroup);
    const dataStorageSizeInTBs = newDataStorageSizeInTBs ? parseInt(newDataStorageSizeInTBs, 10) : 0;
    const isAutoScalingEnabled = getValue<boolean>(formValues, Fields.AutoScaling, InputFormGroup);

    const invokeOptions = {
      onSuccess: onExecute,
      onFailure: ocpuCalloutRef.allowResubmit,
      notification: {
        inProgress: {
          title: Messages.notifications.inProgress.titles.scaleAdbs(),
          message: Messages.notifications.inProgress.messages.scaleAdbs(resName),
        },
        success: {
          title: Messages.notifications.success.titles.scaleAdbs(),
          message: Messages.notifications.success.messages.scaleAdbs(resName),
        },
        failure: {
          title: Messages.notifications.failure.titles.scale(),
          message: Messages.notifications.failure.messages.scaleAdbs(resName),
        },
        asyncPolling: {
          methodKey: AsyncNotificationMethodKey.ADBS_GET,
          methodArgs: {
            subscriptionId,
            resourceGroupName: resGroup,
            databaseName: resName,
          },
          location,
          responseMethodKey: AsyncNotificationPolledResponseKey.ADBS_UPDATED_CHECK,
          delay: ADBS_UPDATE_POLL_DELAY,
          interval: ADBS_UPDATE_POLL_INTERVAL,
        },
      },
    };

    invokeAdbsUpdate({
      databaseName: resName,
      resourceGroupName: resGroup,
      subscriptionId,
      updateAdbsDatabaseDetails: { cpuCoreCount, dataStorageSizeInTBs, isAutoScalingEnabled },
    }, invokeOptions);
  };

  return (
    <InputFormCallout
      testId={CalloutTestIds.AdbsScale}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onScaleAdbs}
      componentRef={setOcpuCalloutRef}
      targetId={targetId}
      onClose={onCancel}
      primaryButtonText={Messages.actions.scale()}
      title={Messages.createNewPanels.scaleCoreCount.title()}
      initialValues={initialValues}
    >
      <CheckBox
        testId={FieldTestIds.AutoScaling}
        fieldName={Fields.AutoScaling}
        defaultValue={adbsScaleValues.isAutoScalingEnabled}
        label={Messages.labels.autoScaling()}
        tooltip={Messages.hints.autoScaling()}
      />
      <IntegerInput
        min={adbsScaleValues.isOcpuModel ? adbsMinOCPUCount : adbsMinECPUCount}
        max={adbsScaleValues.isOcpuModel ? adbsMaxOCPUCount : adbsMaxECPUCount}
        fieldName={Fields.CoreCount}
        testId={FieldTestIds.CoreCount}
        defaultValue={adbsScaleValues.cpuCoreCount}
        required
        label={adbsScaleValues.isOcpuModel ? Messages.labels.ocpuCount() : Messages.labels.ecpuCount()}
      />
      <IntegerInput
        min={adbsMinStorage}
        max={adbsMaxStorage}
        defaultValue={adbsScaleValues.dataStorageSizeInTBs}
        required
        label={Messages.labels.storageInTB()}
        testId={FieldTestIds.StorageSize}
        fieldName={Fields.StorageSize}
      />
    </InputFormCallout>
  );
};

export const newAdbsScale = (
  props: AdbsScaleProps,
): JSX.Element => (<AdbsScale {...props} />);
