import { ActionBar, ActionType, LabelMetaItem, MetaItemSection, NoValue } from "o4a-react";
import * as React from "react";
import { Stack, Link } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { DetailsPanelId, PageId } from "../../constants/pluginConstants";
import { metaItemLabelsGap, MonochromeIconIds } from "../../constants/uiConstants";
import { AdbsDatabase } from "../../gen/clients/mchub-azure-api-client-adb";
import {
  AdbWorkloadType,
  getAdbsLocalizedLicenseType,
  getWorkloadTypeDisplayName,
  ResourceStatus,
} from "../../helpers/resourceHelper";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useFeatures } from "../../hooks/useFeatures";
import { useOperation } from "../../hooks/useOperation";
import { useSidePanel } from "../../hooks/useSidePanel";
import { AdbsEditLicenseProps, newAdbsEditLicense } from "../../operations/Adbs/AdbsEditLicense";
import { AdbsScaleProps, newAdbsScale } from "../../operations/Adbs/AdbsScale";
import { AdbsUpgradeProps, newAdbsUpgrade } from "../../operations/Adbs/AdbsUpgrade";
import { adbsOcpuToEcpuUpdateUrl } from "../../constants/docConstants";

export enum ActionIds {
  Scale = "scale",
  Upgrade = "upgrade",
  Refresh = "refresh",
  EditLicense = "edit-license",
}

export enum ActionBarTestIds {
  Scale = "scale",
  Upgrade = "upgrade",
  Refresh = "refresh",
}

export enum MetaItemActionTestIds {
  EditLicense = "edit-license",
}

export enum MetaItemSectionTestIds {
  WORKLOAD_TYPE = "mis-workload-type",
  LICENSE_TYPE = "mis-license-type",
  COMPUTE_COUNT = "mis-compute-count",
  AUTO_SCALING = "mis-auto-scaling",
  STORAGE = "mis-storage",
  CHARACTER_SET = "mis-character-set",
  NATIONAL_CHARACTER_SET = "mis-n-character-set",
}

export enum AdbsComputeModels {
 OCPU = "OCPU",
 ECPU = "ECPU"
}
export interface AdbsConfigurationDetailsProps {
  adbs: AdbsDatabase | undefined;
  isApex?: boolean;
  location: string;
  refresh: () => void;
  disableOperations?: boolean;
}

export const AdbsConfigurationDetails = ({
  adbs,
  isApex,
  location,
  refresh,
  disableOperations,
}: AdbsConfigurationDetailsProps): JSX.Element => {
  const { trackActionClick, trackActionDiscard } = useAnalytics();

  const { trigger: triggerScale } = useOperation<AdbsScaleProps>(newAdbsScale);
  const { trigger: triggerEditLicense } = useOperation<AdbsEditLicenseProps>(newAdbsEditLicense);
  const { trigger: triggerUpgrade } = useOperation<AdbsUpgradeProps>(newAdbsUpgrade);
  const { enableAdbsCharacterSet } = useFeatures();

  const { closePanels } = useSidePanel();

  const getAdbsConfigurationActions = (): ActionType[] => {
    const actions: ActionType[] = [];
    actions.push({
      key: ActionIds.Scale,
      testId: ActionBarTestIds.Scale,
      text: Messages.actions.scale(),
      icon: MonochromeIconIds.Scale,
      onClick: () => {
        trackActionClick(ActionIds.Scale, PageId.ADBS_DETAILS, DetailsPanelId.CONFIGURATION);
        triggerScale({
          databaseId: adbs?.id,
          adbsScaleValues: {
            isAutoScalingEnabled: adbs?.isAutoScalingEnabled,
            cpuCoreCount: adbs?.computeCount,
            isOcpuModel: adbs?.computeModel !== AdbsComputeModels.ECPU,
            dataStorageSizeInTBs: adbs?.dataStorageSizeInTBs,
          },
          targetId: ActionIds.Scale,
          location,
          onExecute: refresh,
          onCancel: () => trackActionDiscard(ActionIds.Scale, PageId.ADBS_DETAILS, DetailsPanelId.CONFIGURATION),
        });
      },
      disabled: adbs?.status !== ResourceStatus.Active || disableOperations,
    });
    if (adbs?.dbWorkload === AdbWorkloadType.APEX || adbs?.dbWorkload === AdbWorkloadType.JSON) {
      actions.push({
        key: ActionIds.Upgrade,
        testId: ActionBarTestIds.Upgrade,
        text: Messages.actions.convert(),
        icon: MonochromeIconIds.Up,
        onClick: () => {
          trackActionClick(ActionIds.Upgrade, PageId.ADBS_DETAILS, DetailsPanelId.CONFIGURATION);
          triggerUpgrade({
            databaseId: adbs?.id,
            location,
            onExecute: refresh,
            onCancel: () => trackActionDiscard(ActionIds.Upgrade, PageId.ADBS_DETAILS, DetailsPanelId.CONFIGURATION),
          });
        },
        disabled: adbs?.status !== ResourceStatus.Active || disableOperations,
      });
    }
    actions.push({
      key: ActionIds.Refresh,
      testId: ActionBarTestIds.Refresh,
      text: Messages.actions.refresh(),
      icon: MonochromeIconIds.Refresh,
      onClick: () => {
        trackActionClick(ActionIds.Refresh, PageId.ADBS_DETAILS, DetailsPanelId.CONFIGURATION);
        refresh();
      },
    });
    return actions;
  };

  return (
    <Stack style={{ height: "100%" }} tokens={{ childrenGap: 10 }}>
      <ActionBar actions={getAdbsConfigurationActions()} onActionClick={closePanels} />
      <MetaItemSection labelWidth={250}>
        <Stack key="adbsConfiguration-essentials-left" tokens={{ childrenGap: metaItemLabelsGap }}>
          {!isApex && (
            <LabelMetaItem testId={MetaItemSectionTestIds.WORKLOAD_TYPE} label={Messages.labels.workloadType()}>
              {adbs?.dbWorkload ? getWorkloadTypeDisplayName(adbs.dbWorkload as AdbWorkloadType) : <NoValue />}
            </LabelMetaItem>
          )}
          <LabelMetaItem testId={MetaItemSectionTestIds.COMPUTE_COUNT} label={adbs?.computeModel === AdbsComputeModels.OCPU ? Messages.labels.ocpuCount() : Messages.labels.ecpuCount()}>
            <>
              {adbs?.computeCount?.toString() || <NoValue />}
              {adbs?.computeModel !== AdbsComputeModels.ECPU
               && (
               <span style={{ marginLeft: "10px" }}>
                 <Link href={adbsOcpuToEcpuUpdateUrl} target="_blank">
                   { Messages.labels.updateToECPUBillingModel()}
                 </Link>
               </span>
               )}
            </>
          </LabelMetaItem>
          <LabelMetaItem testId={MetaItemSectionTestIds.AUTO_SCALING} label={Messages.labels.autoScaling()}>
            {adbs?.isAutoScalingEnabled ? Messages.common.enabled() : Messages.common.disabled()}
          </LabelMetaItem>
          <LabelMetaItem testId={MetaItemSectionTestIds.STORAGE} label={Messages.labels.storageInTB()}>
            {adbs?.dataStorageSizeInTBs?.toString() || <NoValue />}
          </LabelMetaItem>
          <LabelMetaItem
            label={Messages.labels.license()}
            testId={MetaItemSectionTestIds.LICENSE_TYPE}
            action={!isApex
              && adbs?.dbWorkload !== AdbWorkloadType.APEX
              && adbs?.dbWorkload !== AdbWorkloadType.JSON
              ? {
                id: ActionIds.EditLicense,
                testId: MetaItemActionTestIds.EditLicense,
                label: Messages.actions.edit(),
                onClick: () => {
                  trackActionClick(ActionIds.EditLicense, PageId.ADBS_DETAILS, DetailsPanelId.CONFIGURATION);
                  triggerEditLicense({
                    databaseId: adbs?.id,
                    licenseModel: adbs?.licenseModel,
                    computeCount: adbs?.computeCount,
                    isOcpuModel: adbs?.computeModel !== AdbsComputeModels.ECPU,
                    databaseEdition: adbs?.databaseEdition,
                    location,
                    onExecute: refresh,
                    onCancel: () => trackActionDiscard(
                      ActionIds.EditLicense,
                      PageId.ADBS_DETAILS,
                      DetailsPanelId.CONFIGURATION,
                    ),
                  });
                },
              } : undefined}
          >
            {getAdbsLocalizedLicenseType(adbs?.licenseModel || "", adbs?.databaseEdition || "") || <NoValue />}
          </LabelMetaItem>
        </Stack>
       
        <Stack key="adbsConfiguration-essentials-right" tokens={{ childrenGap: metaItemLabelsGap }}>
          {enableAdbsCharacterSet
          && (
          <>
            <LabelMetaItem testId={MetaItemSectionTestIds.CHARACTER_SET} label={Messages.labels.characterSet()}>
              {adbs?.characterSet || <NoValue />}
            </LabelMetaItem>
            <LabelMetaItem
              testId={MetaItemSectionTestIds.NATIONAL_CHARACTER_SET}
              label={Messages.labels.nationalCharacterSet()}
            >
              {adbs?.ncharacterSet || <NoValue />}
            </LabelMetaItem>

          </>
          )}
        </Stack>
        
      </MetaItemSection>
    </Stack>
  );
};
