/* eslint-disable max-len */
export const contactSupportCommunityHelpUrl = "https://docs.cloud.oracle.com/iaas/Content/multicloud/help.htm";
export const gcsSecurityPractices = "https://www.oracle.com/us/support/library/customer-support-security-practices-069170.pdf";
export const gettingStartedADB = "https://docs.cloud.oracle.com/iaas/odsaz/odsa-provisioning-autonomous-database.html";
export const gettingStartedExadata = "https://docs.cloud.oracle.com/iaas/odsaz/odsa-provisioning-exadata-database.html";
export const gettingStartedMySQL = "https://docs.cloud.oracle.com/iaas/odsaz/odsa-provisioning-mysql-heatwave.html";
export const gettingStartedProvisioning = "https://docs.cloud.oracle.com/iaas/odsaz/odsa-database-provisioning-and-management.html";
export const gettingStartedVMDB = "https://docs.cloud.oracle.com/iaas/odsaz/odsa-provisioning-base-database.html";
export const licenseTypeUrl = "https://www.oracle.com/cloud/bring-your-own-license/index.html";
export const networkOverviewUrl = "https://docs.cloud.oracle.com/iaas/Content/Network/Concepts/overview.htm#Reserved";
export const networkCidrSizingUrl = "https://docs.cloud.oracle.com/iaas/odsaz/odsa-database-troubleshoot-network-cidr-sizing.html";
export const odsaDocumentationUrl = "https://docs.cloud.oracle.com/iaas/Content/multicloud/intro.htm";
export const tagsLearnMoreUrl = "https://docs.cloud.oracle.com/iaas/Content/Tagging/Concepts/taggingoverview.htm";
export const vnetTroubleshooting = "https://docs.cloud.oracle.com/iaas/Content/multicloud/odsa_troubleshooting.htm#vnet";
export const adbsBasicTabLearnMoreUrl = "https://docs.cloud.oracle.com/iaas/odsaz/odsa-provisioning-shared-autonomous-database.html";

export const vmdbPluggableDatabaseUrl = "https://docs.cloud.oracle.com/iaas/dbcs/doc/pluggable-databases.html";
export const exaPluggableDatabaseUrl = "https://docs.oracle.com/en/engineered-systems/exadata-cloud-service/ecscm/manage-databases.html#GUID-4297A407-9E43-470F-AFD9-D2CC3D3C48F5";

export const oracleDbEELearnMoreUrl = "https://docs.cloud.oracle.com/iaas/autonomous-database-shared/doc/autonomous-update-license.html";
export const oracleDbSELearnMoreUrl = "https://docs.cloud.oracle.com/iaas/autonomous-database-shared/doc/autonomous-update-license.html";
export const adbsOcpuToEcpuUpdateUrl = "https://docs.oracle.com/en/cloud/paas/autonomous-database/serverless/adbsb/autonomous-update-billing-model.html";
export const adbsEcpuFAQ = "https://www.oracle.com/a/ocom/docs/autonomous-database-ecpu-faq.pdf";
