import {
  AnchoredPanelType,
  FormValues,
  InputFormSidePanel,
  InputFormSidePanelComponent,
  SubmitButtonMode,
} from "o4a-react";
import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { SidePanelTestIds } from "../../constants/uiConstants";
import {
  AdbsDatabaseBaseDatabaseEditionEnum,
  AdbsDatabaseBaseLicenseModelEnum,
} from "../../gen/clients/mchub-azure-api-client-adb";
import { adbsDbEditionSEOcpuMax, adbsDbEditionSEEcpuMax, DATABASE_EDITION_TYPE, LICENSE_TYPE } from "../../helpers/resourceHelper";
import { DatabaseEditionSelect } from "../DatabaseEdition/DatabaseEditionSelect";
import { LicenseTypeSelect } from "../LicenseTypeSelect/LicenseTypeSelect";

export enum Fields {
  LicenseType = "licenseType",
  DatabaseEdition = "databaseEdition",
}

export enum FieldTestIds {
  LicenseType = "license-type-field",
  DatabaseEdition = "database-edition-field",
}

export interface AdbsEditLicenseValues {
  licenseModel: AdbsDatabaseBaseLicenseModelEnum | undefined,
  databaseEdition: AdbsDatabaseBaseDatabaseEditionEnum | undefined;
  computeCount: number | undefined,
  isOcpuModel: boolean | undefined,
}

export interface AdbsEditLicensePanelProps {
  adbsEditLicenseValues: AdbsEditLicenseValues;
  componentRef?: (ref: InputFormSidePanelComponent) => void;
  onClose: (() => void) | undefined;
  onSubmit: (formValues: FormValues) => void;
}

export const AdbsEditLicensePanel = ({
  adbsEditLicenseValues,
  componentRef,
  onSubmit,
  onClose,
}: AdbsEditLicensePanelProps): JSX.Element => {
  const { licenseModel, databaseEdition, computeCount = 0, isOcpuModel } = adbsEditLicenseValues;

  const [licenseType, setLicenseType] = React.useState<string|undefined>(licenseModel);
  const [selectedDatabaseEdition, setSelectedDatabaseEdition] = React.useState<string>();

  const [initialValues, setInitialValues] = React.useState<FormValues>();

  React.useEffect(() => {
    if (adbsEditLicenseValues) {
      const initFieldValues: FormValues = { [Fields.LicenseType]: licenseModel ? [licenseModel] : undefined };

      if (databaseEdition) {
        initFieldValues[Fields.DatabaseEdition] = [databaseEdition];
      }

      setInitialValues(initFieldValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adbsEditLicenseValues]);

  return (
    <InputFormSidePanel
      testId={SidePanelTestIds.AdbsEditLicense}
      componentRef={componentRef}
      title={Messages.createNewPanels.licenseType.title()}
      type={AnchoredPanelType.MEDIUM}
      submitButtonMode={SubmitButtonMode.DISABLE_TILL_VALID}
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
    >
      <LicenseTypeSelect
        testId={FieldTestIds.LicenseType}
        fieldName={Fields.LicenseType}
        defaultValue={licenseModel}
        label={Messages.labels.licenseType()}
        onChange={(type: string) => {
          setLicenseType(type);
        }}
      />
      {licenseType === LICENSE_TYPE.BRING_YOUR_OWN_LICENSE
        && (
          <DatabaseEditionSelect
            testId={FieldTestIds.DatabaseEdition}
            fieldName={Fields.DatabaseEdition}
            disabled={computeCount > (isOcpuModel ? adbsDbEditionSEOcpuMax : adbsDbEditionSEEcpuMax)}
            isOcpuModel={isOcpuModel}
            defaultValue={selectedDatabaseEdition || databaseEdition
              || (computeCount > (isOcpuModel ? adbsDbEditionSEOcpuMax : adbsDbEditionSEEcpuMax) ? DATABASE_EDITION_TYPE.ENTERPRISE_EDITION : undefined)}
            onChange={(type: string) => {
              setSelectedDatabaseEdition(type);
            }}
          />
        )}
    </InputFormSidePanel>
  );
};
